import { useSelector } from 'react-redux';
import { addWeeks, format } from 'date-fns';
import c from 'classnames';

import { kitResultsViewable, parseDate } from 'helpers';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';

import { Card, Typography } from 'components';

import styles from './ProcessingYourSample.module.scss';

export const ProcessingYourSample = () => {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { latestKit, getDisplayName } = useTinyAccountForHome(tinyAccountId);

  const estimated = addWeeks(parseDate(latestKit?.date_delivered_to_lab), 4);

  //Only show this card after surveys and until results are ready
  if (!latestKit?.date_survey_completed || kitResultsViewable(latestKit))
    return <></>;
  return (
    <Card variant='wrapper' className={c(styles.card, 'orange-border-left')}>
      <div className='flex align-items-center flex-column gap-2 text-center'>
        <Typography variant='label'>Estimated results</Typography>
        <Typography variant='heading-l'>
          {format(estimated, 'MMMM d')}
        </Typography>
        <div className='pt-4'>
          <Typography variant='heading-m'>
            {`We’re processing ${getDisplayName(true)} sample`}
          </Typography>
        </div>
        <Typography variant='body-s' responsive serif>
          Your sample is being processed at our labs. The estimated processing
          time is 4 weeks from the moment we receive it.
        </Typography>
      </div>
    </Card>
  );
};
