import { Fragment, useMemo } from 'react';

import useFeature from 'services/features/useFeature';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';

import { ButtonColor, ButtonSize, LinkButton, Typography } from 'components';
import { ActionPlanLinkItem } from 'views/ActionPlan/ActionPlanGroup/ActionPlanLinkItem';
import { groupByOutcomeAndFilter } from 'views/ActionPlan/ActionPlanAccordionList/ActionPlanAccordionListV2';
import ActionPlanGroup from 'views/ActionPlan/ActionPlanGroupV2/ActionPlanGroupV2';
import checklistIllustration from 'assets/images/placeholders/long_checklist.svg';

import styles from './RelatedActions.module.scss';

type RelatedActionsProps = {
  title: string;
  description: string;
};
export const RelatedActions = ({ title, description }: RelatedActionsProps) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { isFeatureEnabled: showAPGroupedByOutcome } = useFeature(
    'ap_group_by_outcome',
  );
  const { data: actionItems } = useActionPlanData({ kitId: currentKitId });
  const actionItemsGroupedByOutcome = useMemo(
    () => groupByOutcomeAndFilter(actionItems ?? []) ?? {},
    [actionItems],
  );
  const surveyActions = actionItemsGroupedByOutcome['informationBased'];

  if (!currentKitId || !actionItems?.length) return null;
  return (
    <>
      {showAPGroupedByOutcome ? (
        surveyActions && Object.entries(surveyActions)?.length ? (
          <ActionPlanGroup
            title={'Resources'}
            icon={'clipboard'}
            color={'green'}
            description={
              'While we process your results, you can start improving your gut health right away'
            }
            actionsGroupedByOutcome={surveyActions}
            defaultOpen
            hideToggleIcon
          />
        ) : null
      ) : (
        <div className={styles.actionPlanGroup}>
          <div className={styles.groupTitle}>
            <div className='flex flex-column gap-1 p-3'>
              <Typography variant={'heading-m'} className={styles.purpleText}>
                {title}
              </Typography>
              <Typography variant={'body-s'} responsive>
                {description}
              </Typography>
            </div>
            <img src={checklistIllustration} alt='list with check marks' />
          </div>
          <div className={styles.groupContainer}>
            {actionItems.map(actionPlanDetails => (
              <Fragment key={actionPlanDetails.display_title}>
                <ActionPlanLinkItem
                  {...actionPlanDetails}
                  kitId={currentKitId}
                />
              </Fragment>
            ))}
          </div>
          <div className={styles.navigation}>
            <LinkButton
              href={`/action-plan/${currentKitId}`}
              size={ButtonSize.M}
              color={ButtonColor.PURPLE}
              label='Go to action plan'
              fullWidth
            />
          </div>
        </div>
      )}
    </>
  );
};
