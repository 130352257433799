import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import c from 'classnames';

import { EvaluationColor } from 'types/Insights';

import { Icon, Typography } from 'components';
import { getEvaluationIconFromColor } from '../EvaluationGroupsSummary/EvaluationGroupsSummary';
import type { Metrics } from './CategoryMetrics';

import styles from './CategoryMetricsFilter.module.scss';
import { Filters, FilterConfig } from 'components/Filters/Filters';

const MetricFilter = ({
  value,
  display,
}: {
  value: string;
  display: string;
}) => {
  return (
    <>
      <Icon
        name={getEvaluationIconFromColor(value)}
        size='s'
        className={c(styles.icon, styles[value])}
      />
      <Typography variant='label'>{display}</Typography>
    </>
  );
};
const evaluationFilters: FilterConfig[] = [
  {
    value: 'red',
    display: <MetricFilter value='red' display='Needs support' />,
  },
  {
    value: 'orange',
    display: <MetricFilter value='orange' display='Needs improvement' />,
  },
  {
    value: 'grey',
    display: <MetricFilter value='grey' display='Normal results' />,
  },
  {
    value: 'green',
    display: <MetricFilter value='green' display='Great results' />,
  },
];
function filterGroupedSubMetrics(
  data: Metrics,
  filter: string[] | null,
): Metrics {
  if (!filter) {
    return { ...data }; // Return a shallow copy of the original data
  }
  const filteredData: Metrics = {};
  for (const key in data) {
    const filteredItems = data[key]?.submetrics.filter(item =>
      filter.includes(item.evaluation_color),
    );
    if (!!filteredItems && filteredItems.length > 0) {
      filteredData[key] = {
        submetrics: filteredItems,
        priority: data[key].priority,
      };
    }
  }

  return filteredData;
}

type CategoryMetricsFilterProps = {
  setFiltered: Dispatch<SetStateAction<string[]>>; //setter from useState
};
export const CategoryMetricsFilter = ({
  setFiltered,
}: CategoryMetricsFilterProps) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  useEffect(() => {
    //filter data when the activeFilters are updated
    if (setFiltered) {
      if (!activeFilters) {
        setFiltered([]);
      } else {
        setFiltered(activeFilters);
      }
    }
  }, [activeFilters, setFiltered]);

  return (
    <Filters
      name='evaluations'
      filters={activeFilters}
      filterConfig={evaluationFilters}
      setFiltered={setActiveFilters}
    />
  );
};
