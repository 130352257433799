import { useSelector } from 'react-redux';

import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';
import { LINKS } from 'assets';

import { Typography } from 'components';
import { GenericSummaryCardWithImage } from '../GenericSummaryCardWithImage/GenericSummaryCardWithImage';
import resampleBg from 'assets/images/summary/resample.jpg';
import resamplePlusBg from 'assets/images/summary/resample_plus.jpg';
import logoPlus from 'assets/images/logos/logo-plus-white.svg';

import styles from './ResampleSummaryCard.module.scss';

export const ResampleSummaryCard = () => {
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isTinyPlus = useSelector(getIsTinyPlus);

  return (
    <GenericSummaryCardWithImage
      section={
        isAnyTinyPlus ? 'Tiny+ member-only pricing' : 'Get your next kit'
      }
      title={
        isAnyTinyPlus
          ? isTinyPlus
            ? 'Get another test for $149'
            : 'Get another test for $169'
          : 'Track changes over time and fine-tune'
      }
      link={`${
        isAnyTinyPlus
          ? isTinyPlus
            ? LINKS.checkoutWithKitTinyPlus
            : LINKS.checkoutWithKitTinyPlusProgramOrProactive
          : LINKS.checkoutWithKitNonTinyPlus
      }&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=resample-cta`}
      external
      cta='Order now'
      image={isAnyTinyPlus ? resamplePlusBg : resampleBg}
      overlay={
        <div className={styles.overlay}>
          {isAnyTinyPlus && <img src={logoPlus} alt='tiny plus logo' />}
          {!isAnyTinyPlus && (
            <Typography variant={'label'} className={styles.badge}>
              {`$20 off`}
            </Typography>
          )}
        </div>
      }
    />
  );
};
