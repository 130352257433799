import { BarChart } from 'components/CompareSampleCharts/BarChart/BarChart';
import { compositionCompareStackedHorizontalBarChartConfig } from 'components/CompareSampleCharts/BarChart/BarChartData';
import type Kit from 'types/Kit';
import Typography from '../Typography/Typography';
import {
  getMomGutSamples,
  getNewbornSamples,
  getFourthTrimesterSamples,
  getIntroSolidsSamples,
  getToddlerSamples,
} from './HowYouCompareSamples';

export const CompareSampleCharts = ({ kit }: { kit: Kit }) => {
  const kitGroup = (kit: Kit) => {
    if (!kit?.age_at_sampling) return;
    if (kit.age_at_sampling < 23) {
      return 'newborn';
    }
    if (kit.age_at_sampling < 30 * 4) {
      return 'infant';
    }
    if (kit.age_at_sampling < 395) {
      return 'baby';
    }
    if (kit.age_at_sampling >= 395 && kit.age_at_sampling < 1095) {
      return 'toddler';
    }
    if (kit.age_at_sampling >= 365 * 3) {
      return 'adult';
    }
    if (kit.week_of_pregnancy) {
      return 'pregnant';
    }
    return 'unknown';
  };

  const momGutData = getMomGutSamples();
  const newbornData = getNewbornSamples();
  const fourthTrimesterData = getFourthTrimesterSamples();
  const introSolidsData = getIntroSolidsSamples();
  const toddlerData = getToddlerSamples();

  return (
    <div className={'flex justify-center'}>
      {kitGroup(kit) === 'pregnant' && (
        <div>
          <Typography variant='heading-m'>Pregnant Participants</Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={momGutData.labels}
            data={momGutData.data}
          />
        </div>
      )}
      {kitGroup(kit) === 'adult' && (
        <div>
          <Typography variant='heading-m'>
            Adults and kids: over 3 years old
          </Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={momGutData.labels}
            data={momGutData.data}
          />
        </div>
      )}

      {kitGroup(kit) === 'newborn' && (
        <div>
          <Typography variant='heading-m'>
            Newborns: 0 to 22 days old
          </Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={newbornData.labels}
            data={newbornData.data}
          />
        </div>
      )}

      {kitGroup(kit) === 'infant' && (
        <div>
          <Typography variant='heading-m'>
            Babies: 23 days to 4 months old
          </Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={fourthTrimesterData.labels}
            data={fourthTrimesterData.data}
          />
        </div>
      )}

      {kitGroup(kit) === 'baby' && (
        <div>
          <Typography variant='heading-m'>
            Babies and toddlers: 5 to 13 months old
          </Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={introSolidsData.labels}
            data={introSolidsData.data}
          />
        </div>
      )}

      {kitGroup(kit) === 'toddler' && (
        <div>
          <Typography variant='heading-m'>
            Toddlers: 13 months to 3 years old
          </Typography>
          <BarChart
            id={'microbiomeVerticalBarChart'}
            config={compositionCompareStackedHorizontalBarChartConfig}
            labels={toddlerData.labels}
            data={toddlerData.data}
          />
        </div>
      )}
    </div>
  );
};
