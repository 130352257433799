import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueries } from '@tanstack/react-query';

import { LINKS } from 'assets/constants';
import { apiName } from 'assets/constants';
import { getColorFromGutVaginalEvaluationMeaning } from 'helpers';
import { getGutTypeResponse } from 'services/gutType/useGutType';
import { getBabyGutKitsForTinyAccountId } from 'store/kits';
import Kit from 'types/Kit';
import { GutTypeData } from 'types/GutType';

import { Spinfinity, TextLink } from 'components';
import { ScatterPlotChart } from './ScatterPlot/ScatterPlotChart';
import { scatterChartTopArchetypeConfig } from './ScatterPlot/ScatterPlotData';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

type Coordinates = { x: string | number; y: string | number };
type GutMarkerData = {
  label: string;
  data: Coordinates[];
  backgroundColor: string;
  pointStyle: string;
  radius: number;
};

const getKitLabel = (kits: Kit[], kit_id: string) => {
  const kit = kits.find(kit => kit.id === kit_id);
  return kit?.sample_label ? kit.sample_label : '';
};

export const BabyGutInfo = () => {
  const { currentTinyAccountId } = useCurrentKitAndTinyAccount();

  const babyGutKits = useSelector(
    getBabyGutKitsForTinyAccountId(currentTinyAccountId),
  );

  const babyGutQueries = useQueries({
    queries: babyGutKits.map((kit: Kit) => {
      return {
        queryKey: [apiName, 'gutType', kit.id],
        queryFn: () => getGutTypeResponse(kit.id),
      };
    }),
  });

  const isFetching = babyGutQueries.some(result => result.isLoading);

  const babyGutData: GutMarkerData[] = useMemo(() => {
    if (!isFetching) {
      const gutData: GutMarkerData[] = [];
      babyGutQueries.forEach(query => {
        const gutInfo = query.data as GutTypeData;
        if (gutInfo?.gut_vaginal_type) {
          const markerColor = getColorFromGutVaginalEvaluationMeaning(
            gutInfo.gut_vaginal_type.evaluation_meaning,
          );
          gutData.push({
            label: getKitLabel(babyGutKits, gutInfo.kit_id),
            data: [
              {
                x: (gutInfo.type_PC1 && gutInfo.type_PC1.toFixed(2)) ?? '',
                y: (gutInfo.type_PC2 && gutInfo.type_PC2.toFixed(2)) ?? '',
              },
            ],
            backgroundColor: markerColor === 'yellow' ? 'gold' : markerColor,
            pointStyle: 'triangle',
            radius: 14,
          });
        }
      });
      return gutData;
    } else {
      return [];
    }
  }, [babyGutKits, babyGutQueries, isFetching]);

  return (
    <div className='flex flex-column'>
      {isFetching ? (
        <Spinfinity />
      ) : (
        <>
          <p>
            {
              'Every dot on this plot is a baby sample. Tiny Health has identified 7 distinct groupings of gut types for babies. '
            }
            <TextLink
              label='Learn More'
              iconNameRight='chevronForward'
              href={LINKS.babyGutTypes}
              external
            />
          </p>
          <ScatterPlotChart
            id={'babyGutScatterPlotChart'}
            config={scatterChartTopArchetypeConfig()}
            data={babyGutData}
            labels={['test1']}
          />
        </>
      )}
    </div>
  );
};
