import { NavLink, useLocation } from 'react-router-dom';
import c from 'classnames';

import { ActionPlanItem } from 'types/ActionPlan';

import { Icon } from 'components';
import { DesiredOutcome } from '../DesiredOutcomes/DesiredOutcomes';

import styles from './ActionPlanLinkItem.module.scss';

export const ActionPlanLinkItem = ({
  display_title,
  desired_outcomes,
  kitId,
  id,
}: Partial<ActionPlanItem> & { kitId: string }) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.actionPlanItemWrapper}>
      <NavLink
        to={`/action-plan/${kitId}/action/${id}`}
        state={{ from: pathname }}
        className={styles.actionPlanItemContainer}
      >
        <div className={styles.actionPlanCardItemTextContainer}>
          <div className={c(styles.actionPlanItemTitle)}>{display_title}</div>
          {!!desired_outcomes?.length && !!desired_outcomes[0] && (
            <DesiredOutcome outcome={desired_outcomes[0]} />
          )}
        </div>
        <div className={styles.actionPlanItemChevron}>
          <Icon name='chevronForward' size='m' />
        </div>
      </NavLink>
    </div>
  );
};
