import { useSelector } from 'react-redux';

import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';
import { LINKS } from 'assets/constants';
import { Button } from 'components';

export const OrderKitButton = classes => {
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isTinyPlus = useSelector(getIsTinyPlus);
  return !isAnyTinyPlus ? (
    <div
      className={`flex flex-column justify-center align-items-center p-2 ${classes} dont-print`}
    >
      <Button
        href={`${LINKS.checkoutWithKitNonTinyPlus}&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=ap-cta`}
        className='m-0 button'
        external={true}
      >
        {`Order Kit`}
      </Button>
    </div>
  ) : (
    <div
      className={`flex flex-column justify-center align-items-center p-2 ${classes} dont-print`}
    >
      <Button
        href={`${
          isTinyPlus
            ? LINKS.checkoutWithKitTinyPlus
            : LINKS.checkoutWithKitTinyPlusProgramOrProactive
        }&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=ap-cta`}
        className='m-0 button'
        external={true}
      >
        {`Order Kit`}
      </Button>
    </div>
  );
};
