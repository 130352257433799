import { Spinfinity } from 'components';
import AuthLayout from 'components/Layout/AuthLayout';
import ResponsiveLayout from 'components/Layout/ResponsiveLayout';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAccountsAndKits from 'services/account/useAccountsAndKits';
import useFeature from 'services/features/useFeature';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getHomePageTinyAccountId, setHomePageTinyAccountId } from 'store/home';
import { getIsAuthenticated, getIsAutoLoggedOut } from 'store/user';
import { SplashScreen } from 'views/Auth';
import { GetStarted } from './GetStarted/GetStarted';
import { HomeContent } from './HomeContent/HomeContent';

const Home = () => {
  const dispatch = useDispatch();
  const { state: navigationState } = useLocation();
  const tinyAccountIdParam = useUrlQuery().get('account');
  useAccountsAndKits();
  const { isFeatureEnabled, isFetched } = useFeature('home_screen');
  const isAuthenticated = useSelector(getIsAuthenticated);
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const tinyAccounts = useSelector(getTinyAccountsOrderedByLatestKitResults);
  const isAutoLoggedOut = useSelector(getIsAutoLoggedOut);
  // set the home page tiny account
  useEffect(() => {
    if (!isFeatureEnabled || !isFetched || !tinyAccounts?.length) return;
    // if tinyAccountIdParam or navigation state is present, set that as the home page tiny account
    let newTinyAccountId: string | null = null;
    if (tinyAccountIdParam || navigationState?.tinyAccountId) {
      newTinyAccountId =
        navigationState?.tinyAccountId ?? (tinyAccountIdParam as string);
    } else if (!tinyAccountId) {
      // if no tiny account is set, set the tiny account with the most recent kit as the home page tiny account
      newTinyAccountId = tinyAccounts[0].id;
    }

    if (
      newTinyAccountId !== tinyAccountId &&
      tinyAccounts?.some(account => account.id === newTinyAccountId)
    ) {
      dispatch(setHomePageTinyAccountId(newTinyAccountId));
    }
  }, [
    dispatch,
    isAuthenticated,
    isFeatureEnabled,
    isFetched,
    navigationState,
    tinyAccountId,
    tinyAccountIdParam,
    tinyAccounts,
  ]);
  if (isAutoLoggedOut) {
    return (
      <Navigate
        to='/login'
        replace
        state={{
          ...navigationState,
          tinyAccountId: tinyAccountIdParam
            ? tinyAccountIdParam
            : tinyAccountId,
        }}
      />
    );
  }
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='' element={<SplashScreen />} />
        </Route>
      </Routes>
    );
  }
  let HomeScreenAction = null;
  if (!isFetched) {
    HomeScreenAction = <Spinfinity />;
  } else if (!isFeatureEnabled) {
    HomeScreenAction = (
      <Navigate to='/results' replace state={navigationState} />
    );
  } else if (!tinyAccounts?.length) {
    HomeScreenAction = <GetStarted />;
  } else if (tinyAccountId) {
    HomeScreenAction = <HomeContent />;
  }

  return (
    <Routes>
      <Route element={<ResponsiveLayout />}>
        <Route path='' element={HomeScreenAction} />
      </Route>
    </Routes>
  );
};

export default Home;
