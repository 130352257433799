import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { useUrlQuery } from 'hooks/useUrlQuery';
import TinyAccount from 'types/TinyAccount';

import { Icon, Markdown } from 'components';
import { IconName } from 'components/Icon/getSvgByName';

import styles from './QuestionAnswerList.module.scss';

export type QuestionAnswerItem = {
  question_id: string;
  title: string;
  answer: string;
};
type QuestionAnswerListProps = {
  list: QuestionAnswerItem[];
  icon?: IconName;
};

const replaceTypeformVariables = (text: string, tinyAccount: TinyAccount) => {
  let newText = text?.replaceAll(
    '{{hidden:first_name}}',
    tinyAccount.first_name,
  );
  newText = newText?.replaceAll('{{hidden:last_name}}', tinyAccount.last_name);
  return newText;
};

export const QuestionAnswerList = ({
  list,
  icon = 'check',
}: QuestionAnswerListProps) => {
  const { currentKit, currentTinyAccount } = useCurrentKitAndTinyAccount();
  const withIds = useUrlQuery().get('ids') !== null;

  if (!currentKit) return <></>;
  return (
    <div className={styles.container}>
      {list.map((qaItem, qaIdx) => (
        <div
          className={styles.questionAnswerItem}
          key={qaIdx + '_' + qaItem.question_id}
        >
          <Icon name={icon} size='s' />
          <div className={styles.questionAnswerPair}>
            <div className={styles.question}>
              <Markdown
                customOverride={{
                  p: ({ children }: { children: React.ReactNode }) => (
                    <div>{children}</div>
                  ),
                }}
              >
                {`${withIds ? `${qaItem.question_id} - ` : ''}${
                  !currentTinyAccount
                    ? qaItem.title
                    : replaceTypeformVariables(qaItem.title, currentTinyAccount)
                }`}
              </Markdown>
            </div>
            <div className={styles.answer}>{qaItem.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
