import { LINKS } from 'assets/constants';
import useVaginalType from 'services/gutType/useVaginalType';

import { OrderKitButton, Spinfinity } from 'components';
import { VaginalTypeCard } from './VaginalTypeCard';

import { useCurrentKitAndTinyAccount } from '../../../hooks/useCurrentKitAndTinyAccount';

export const VaginalType = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { data, isLoading } = useVaginalType();

  if (isLoading) return <Spinfinity />;
  if (!currentKitId) {
    return (
      <>
        <p>
          You have not taken any vaginal samples yet, or your results are not
          ready.
        </p>
        <OrderKitButton href={LINKS.vaginalKitSales}>
          Order a Tiny&nbsp;Vaginal&nbsp;Kit
        </OrderKitButton>
      </>
    );
  }
  if (!data?.length)
    return (
      <div>This kit doesn&apos;t have information about the vaginal type.</div>
    );
  return <VaginalTypeCard kitData={data ?? []} />;
};
