import c from 'classnames';

import { ConditionAssociation } from 'types/Conditions';

import { Icon } from 'components';

import styles from './Conditions.module.scss';

export const AssociatedSubheading = ({
  association,
}: {
  association: ConditionAssociation;
}) => (
  <span className={c(styles.subheader, styles.flexRow)}>
    {association === ConditionAssociation.NEGATIVE && (
      <span className={styles.goodDot}>
        <Icon name='downSmall' size='xs' />
      </span>
    )}
    {association === ConditionAssociation.POSITIVE && (
      <span className={styles.badDot}>
        <Icon name='upSmall' size='xs' />
      </span>
    )}
    <span>
      Associated with{' '}
      {association === ConditionAssociation.NEGATIVE
        ? 'decreased'
        : association === ConditionAssociation.POSITIVE
        ? 'increased'
        : 'typical'}{' '}
      risk
    </span>
  </span>
);
