import ReactSelect from 'react-select';
import c from 'classnames';

import styles from './Select.module.scss';

const THSelect = ({
  value,
  options,
  onChange,
  name,
  error = false,
  enableSearch = false,
  placeholder = 'Select one...',
  ...props
}: {
  value?: string | number;
  options: { value: string | number; label: string }[];
  onChange: (value: string | number) => void;
  name: string;

  error?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
}) => {
  return (
    <>
      <ReactSelect
        className={styles.select}
        classNamePrefix={c(styles.select, error && styles.error)}
        name={name}
        aria-labelledby={name}
        placeholder={placeholder}
        options={options}
        value={options.find(option => option.value === value)}
        onChange={option => option && onChange(option.value)}
        isSearchable={enableSearch}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          }),
        }}
        {...props}
      />
    </>
  );
};

export default THSelect;
