import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import c from 'classnames';

import useModal from 'hooks/useModal';
import { getHasModalOpened, setModalOpened } from 'store/modal';
import { getMainAccountId } from 'store/account';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  DesignButton,
  Typography,
} from 'components';
import onboarding0 from 'assets/images/profiles/onboarding_0.svg';
import onboarding1 from 'assets/images/profiles/onboarding_1.svg';
import onboarding2 from 'assets/images/profiles/onboarding_2.svg';
import onboarding3 from 'assets/images/profiles/onboarding_3.svg';
import onboarding4 from 'assets/images/profiles/onboarding_4.svg';

import styles from './AccountOnboardingModal.module.scss';

const stepsData = [
  {
    image: onboarding0,
    title: 'Welcome to Tiny Health',
    description:
      'We help you identify microbiome imbalances, get to the root cause, and empower you to take proactive steps toward better health.',
  },
  {
    image: onboarding1,
    title: 'Sample in under 5 minutes',
    description: `Take your sample and activate your kit using the code on the side of your collection tube. Answer a few questions, and you're on your way!`,
  },
  {
    image: onboarding2,
    title: 'Explore your in-depth results',
    description: `Our team will analyze your sample and interpret your results. In 3-4 weeks, you'll receive a detailed, easy-to-understand report.`,
  },
  {
    image: onboarding3,
    title: 'Take action with a personalized plan',
    description: `Your Action Plan includes science-based diet, supplement, and lifestyle advice. We'll help you prioritize your next steps.`,
  },
  {
    image: onboarding4,
    title: 'Review your results with your coach or practitioner',
    description: `Meet with your coach or practitioner to discuss your health, go over test results, and answer any questions you have.`,
  },
];

export const AccountOnboardingModal = ({ enabled = false }) => {
  const dispatch = useDispatch();
  const { Modal, isOpen, setIsOpen } = useModal();
  const mainAccountId = useSelector(getMainAccountId);
  const accountOnboardingModal = {
    name: 'accountOnboarding',
    props: { mainAccountId },
  };
  const [activeStep, setActiveStep] = useState(0);

  const hasOpenedAccountOnboardingModal = useSelector(
    getHasModalOpened(accountOnboardingModal),
  );
  const handleNext = async () => {
    if (activeStep < stepsData.length - 1) {
      setActiveStep(prevStep => prevStep + 1);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (enabled && !hasOpenedAccountOnboardingModal) {
      setIsOpen(true);
    }
  }, [enabled, hasOpenedAccountOnboardingModal]);
  useEffect(() => {
    if (isOpen && !hasOpenedAccountOnboardingModal) {
      dispatch(setModalOpened(accountOnboardingModal));
    }
  }, [isOpen, hasOpenedAccountOnboardingModal]);

  const ModalMemo = useMemo(() => Modal, [isOpen]);
  return (
    <ModalMemo showHeader={false}>
      <div className={styles.modalBody}>
        <div className={styles.swipeContainer}>
          {stepsData.map((step, index) => (
            <div
              key={index}
              className={c(
                styles.content,
                index === activeStep && styles.active,
              )}
            >
              <div className={styles.imageWrapper}>
                <img src={step.image} alt={`Illustration ${index + 1}`} />
              </div>
              <Typography
                variant={index === 0 ? 'heading-2xl' : 'heading-xl'}
                className={styles.padding}
              >
                {step.title}
              </Typography>
              <div className={c(styles.description, styles.padding)}>
                <Typography variant={index === 0 ? 'heading-m' : 'body-s'}>
                  {step.description}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.padding}>
          {stepsData?.length > 1 && (
            <div className={styles.dots}>
              {stepsData.map((step, index) => (
                <span
                  key={index}
                  className={c(index === activeStep && styles.active)}
                  onClick={() => setActiveStep(index)}
                />
              ))}
            </div>
          )}
          <DesignButton
            label='Next'
            variant={ButtonVariant.SOLID}
            size={ButtonSize.M}
            color={ButtonColor.PURPLE}
            onClick={handleNext}
            fullWidth
            className='mb-3'
          />
        </div>
      </div>
    </ModalMemo>
  );
};
