import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useProfileQuestions from 'services/general/profileQuestions';
import useSampleInfoQuestions from 'services/surveys/useSampleInfoQuestions';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import { getBirthMotherForTinyAccountId } from 'store/account';

import {
  ButtonColor,
  ButtonSize,
  Card,
  LinkButton,
  BirthMotherPicker,
} from 'components';
import { NextStep } from './NextStep';
import { SurveysInfoModal } from '../SurveysInfoModal/SurveysInfoModal';
import { TypeformSurveysStep } from './TypeformSurveysStep';

export const NextSteps = () => {
  const [isMissingBirthMother, setIsMissingBirthMother] = useState(false);
  const { pathname } = useLocation();
  const { currentKit, currentKitId, currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  const birthMother = useSelector(
    getBirthMotherForTinyAccountId(currentTinyAccountId),
  );
  const { needsProfile } = useProfileQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const { needsSampleInfo } = useSampleInfoQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const { needsSurveys } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  const isResample = !!currentKit?.is_resample;

  useEffect(() => {
    if (!currentKit || !currentTinyAccount) return;
    //birth mother check
    let missingBirthMother = false;
    if (!currentTinyAccount?.birth_mother_id && currentKit.age_at_sampling) {
      const threeYears = 1095;
      missingBirthMother = currentKit.age_at_sampling < threeYears;
    }
    setIsMissingBirthMother(missingBirthMother);
  }, [currentKit, currentTinyAccount]);

  if (!currentTinyAccount) return <></>;
  return (
    <div>
      <div className='flex'>
        <SurveysInfoModal />
      </div>

      <Card variant='wrapper' className='p-3'>
        <div className='flex flex-column gap-3 w-100'>
          <NextStep
            isActive={needsProfile}
            isCompleted={!needsProfile}
            linkCompleted={`/results/${currentKitId}/surveys/profile`}
            title={`${currentTinyAccount.first_name}'s profile`}
          >
            <LinkButton
              label={isResample ? 'Review & confirm profile' : 'Get started'}
              href={`/profile?account=${currentTinyAccountId}${
                isResample ? `&review=${currentKitId}` : ''
              }`}
              state={{ from: pathname }}
              size={ButtonSize.M}
              color={ButtonColor.PURPLE}
              fullWidth
            />
          </NextStep>
          <NextStep
            isActive={!needsProfile && needsSampleInfo}
            isCompleted={!needsProfile && !needsSampleInfo}
            linkCompleted={`/results/${currentKitId}/surveys/sample`}
            title={`About ${currentTinyAccount.first_name}`}
          >
            <LinkButton
              label='Get started'
              href={`/profile?kit=${currentKitId}`}
              state={{ from: pathname }}
              size={ButtonSize.M}
              color={ButtonColor.PURPLE}
              fullWidth
            />
          </NextStep>
          {isMissingBirthMother || !!birthMother ? (
            <NextStep
              isActive={!needsProfile && !needsSampleInfo}
              isCompleted={!!birthMother}
              title={`Who gave birth to ${currentTinyAccount.first_name}?`}
            >
              {isMissingBirthMother && <BirthMotherPicker />}
              {!!birthMother && <BirthMotherPicker birthMother={birthMother} />}
            </NextStep>
          ) : null}
          <NextStep
            isActive={
              !needsProfile && !needsSampleInfo && !isMissingBirthMother
            }
            isCompleted={
              !needsProfile &&
              !needsSampleInfo &&
              !isMissingBirthMother &&
              !needsSurveys
            }
            title={`Health history`}
          >
            {!needsProfile && !needsSampleInfo && !isMissingBirthMother ? (
              <TypeformSurveysStep />
            ) : (
              <></>
            )}
          </NextStep>
        </div>
      </Card>
    </div>
  );
};
