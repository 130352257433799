import { useEffect, useMemo, useState } from 'react';
import { format, addWeeks, isBefore, startOfDay } from 'date-fns';
import { motion } from 'framer-motion';
import c from 'classnames';

import { kitResultsViewable, parseDate } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import useProfileQuestions from 'services/general/profileQuestions';
import useSampleInfoQuestions from 'services/surveys/useSampleInfoQuestions';
import { IconName } from 'components/Icon/getSvgByName';
import Kit from 'types/Kit';

import {
  Card,
  ContainerSizes,
  Icon,
  PageContainer,
  Typography,
} from 'components';
import { NextSteps } from './NextSteps/NextSteps';
import { TopGuides } from './TopGuides';
import { RelatedActions } from './RelatedActions/RelatedActions';

import styles from './KitProcessWithProfile.module.scss';

type IconColor = 'red' | 'green' | 'grey' | 'orange';
type StepContent = {
  description: string;
  icon: IconName;
  iconColor: IconColor;
};
type Step = {
  title: string;
  getStepContent: () => StepContent;
};
const getKitProcessSteps = (kit?: Kit, needsProfileOrSurveys?: boolean) => [
  {
    title: 'Kit activated',
    getStepContent: () => {
      return {
        description: `Your kit has been activated!`,
        icon: 'checkBackground' as IconName,
        iconColor: 'green' as IconColor,
      };
    },
  },
  {
    title: 'Profile & health history',
    getStepContent: () => {
      if (!needsProfileOrSurveys)
        return {
          description: `Your surveys were successfully completed.`,
          icon: 'checkBackground' as IconName,
          iconColor: 'green' as IconColor,
        };
      //since we have pending surveys, depending if we already have results from lab:
      return kitResultsViewable(kit)
        ? {
            description: `We’re not able to deliver your report until all surveys are completed.`,
            icon: 'alertCircle' as IconName,
            iconColor: 'orange' as IconColor,
          }
        : {
            description: `We need a bit more info to interpret your sample and won’t be able to deliver your report without it.`,
            icon: 'checkBackground' as IconName,
            iconColor: 'grey' as IconColor,
          };
    },
  },
  {
    title: 'Lab analysis',
    getStepContent: () => {
      if (kit?.date_sequences_delivered)
        return {
          description: `Our lab has finished processing your sample and your results will be ready soon.`,
          icon: 'checkBackground' as IconName,
          iconColor: 'green' as IconColor,
        };
      const processingTime = '3-4 weeks';
      const desc = kit?.date_delivered_to_lab
        ? `Your sample was delivered to our lab on ${format(
            parseDate(kit.date_delivered_to_lab),
            'MMMM d',
          )}. We estimate you will have your results by ${format(
            addWeeks(parseDate(kit.date_delivered_to_lab), 4),
            'MMMM d',
          )}.`
        : kit?.date_in_transit_to_lab
        ? `Your sample is en route to our lab. Once received by the lab, your sample will take ${processingTime} to process.`
        : `Please mail your sample using the envelope provided. Once received by the lab, your sample will take ${processingTime} to process.`;
      return {
        description: desc,
        icon: 'checkBackground' as IconName,
        iconColor: 'grey' as IconColor,
      };
    },
  },
  {
    title: 'Wrapping up results',
    getStepContent: () => {
      return {
        description: `We’ll email you when your results are ready`,
        icon: 'checkBackground' as IconName,
        iconColor: 'grey' as IconColor,
      };
    },
  },
];

type ProcessStepProps = {
  kit?: Kit;
  step: Step;
  stepIndex: number;
  currentStep: number;
  showAllSteps: boolean;
};
const ProcessStep = ({
  kit,
  step,
  stepIndex,
  currentStep,
  showAllSteps = false,
}: ProcessStepProps) => {
  const showThisStepRow =
    showAllSteps || (stepIndex === currentStep && currentStep > 1);
  const { description, icon, iconColor } = step.getStepContent();
  const isPreviousStep = stepIndex < currentStep;

  return !showThisStepRow ? (
    <></>
  ) : (
    <div className={c(styles.processStep, styles[iconColor])}>
      <div className={styles.rowTitle}>
        <Typography
          variant='heading-s'
          className={isPreviousStep ? 'green' : 'dark-grey-text'}
        >{`${++stepIndex}. ${step.title}`}</Typography>
      </div>
      <Typography
        variant='body-s'
        responsive
        serif
        className={isPreviousStep ? 'light-grey-text' : 'grey-text'}
      >
        {description}
      </Typography>
    </div>
  );
};

export const KitProcessWithProfile = () => {
  const [showAllSteps, setShowAllSteps] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isMissingBirthMother, setIsMissingBirthMother] = useState(false);
  const { currentKit, currentKitId, currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  const { needsProfile } = useProfileQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const { needsSampleInfo } = useSampleInfoQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const { needsSurveys, isNewProfileFlow } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );

  const STEPS: Step[] = useMemo(() => {
    const needsProfileOrSurveys =
      needsSurveys || needsProfile || needsSampleInfo;
    return getKitProcessSteps(currentKit, needsProfileOrSurveys);
  }, [currentKit, needsProfile, needsSampleInfo, needsSurveys]);

  useEffect(() => {
    if (!currentKit || !currentTinyAccount) return;
    //birth mother check
    let missingBirthMother = false;
    if (!currentTinyAccount?.birth_mother_id && currentKit.age_at_sampling) {
      const threeYears = 1095;
      missingBirthMother = currentKit.age_at_sampling < threeYears;
      setIsMissingBirthMother(missingBirthMother);
    }
    //define in which step we are
    if (missingBirthMother || needsProfile || needsSampleInfo || needsSurveys) {
      setCurrentStep(1);
    } else if (!currentKit?.date_sequences_delivered) {
      setCurrentStep(2);
    } else {
      setCurrentStep(3);
    }
  }, [
    currentKit,
    currentTinyAccount,
    needsProfile,
    needsSampleInfo,
    needsSurveys,
  ]);

  const estimatedDate = useMemo(() => {
    if (currentKit?.date_delivered_to_lab) {
      const estimated = addWeeks(
        parseDate(currentKit.date_delivered_to_lab),
        4,
      );
      if (!isBefore(estimated, startOfDay(new Date()))) {
        return format(estimated, 'MMMM d');
      }
    }
    return null;
  }, [currentKit]);

  if (!isNewProfileFlow) return <></>;
  return (
    <PageContainer
      size={ContainerSizes.MD}
      className={styles.sectionsContainer}
    >
      <main>
        <div className='flex flex-column gap-4 mb-4'>
          <Card variant='wrapper'>
            <div className='flex flex-column gap-1 p-3'>
              <motion.div
                key='header'
                className={styles.header}
                role='button'
                onClick={() => setShowAllSteps(!showAllSteps)}
              >
                <div className='flex flex-column gap-1'>
                  {!estimatedDate ? (
                    <Typography variant='heading-s'>
                      {`Your kit's progress`}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant='label'>Estimated results</Typography>
                      <Typography variant='heading-l'>
                        {estimatedDate}
                      </Typography>
                    </>
                  )}
                </div>
                <Icon
                  name='chevronDown'
                  className={c(
                    styles.rotate,
                    showAllSteps
                      ? [styles.iconRotateUp]
                      : [styles.iconRotateDown],
                  )}
                />
              </motion.div>
              <div className={styles.stepsContainer}>
                {STEPS.map((step, idx) => (
                  <div
                    key={`step_${idx}`}
                    className={c(
                      styles.step,
                      idx < currentStep && styles.full,
                      idx === currentStep && styles.half,
                    )}
                  ></div>
                ))}
              </div>
            </div>
            <motion.div layout transition={{ duration: 0.05 }}>
              {STEPS.map((step, stepIndex) => (
                <ProcessStep
                  key={`row_${stepIndex}`}
                  kit={currentKit}
                  step={step}
                  stepIndex={stepIndex}
                  currentStep={currentStep}
                  showAllSteps={showAllSteps}
                />
              ))}
            </motion.div>
          </Card>
          {currentStep === 1 && <NextSteps />}
          {currentStep > 1 && (
            <>
              <RelatedActions
                title='Personalized action plan based on your survey responses'
                description='While we process your results, you can start improving your gut health right away'
              />
              <div className={styles.hideOnMobile}>
                <NextSteps />
              </div>
            </>
          )}
        </div>
      </main>
      <section className={styles.right}>
        {currentStep > 1 && (
          <>
            <TopGuides qty={3} withLink title='Read the guides' />
            <div className={c(styles.hideOnDesktop, 'mt-4')}>
              <NextSteps />
            </div>
          </>
        )}
      </section>
    </PageContainer>
  );
};
