import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LINKS } from 'assets';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  ContainerSizes,
  LinkButton,
  PageContainer,
  Spinfinity,
} from 'components';
import { VaginalType } from './VaginalType';

import styles from './VaginalHealth.module.scss';

export const CstRoutes = () => {
  return (
    <Routes>
      <Route path={``} element={<VaginalHealth />} />
    </Routes>
  );
};

export const VaginalHealth = () => {
  return (
    <PageContainer size={ContainerSizes.MD}>
      <section id='cst'>
        <div className={styles.titleRow}>
          <h2 data-test='vaginal-type-title'>Vaginal Community State</h2>
        </div>

        <p>
          The Community State Type (CST) reflects the microbes that currently
          make up your vaginal microbiome, which can change over time.{' '}
          <LinkButton
            label='Learn&nbsp;More'
            href={LINKS.cst}
            external
            variant={ButtonVariant.TEXT}
            size={ButtonSize.M}
            color={ButtonColor.PURPLE}
          />
        </p>

        <Suspense fallback={<Spinfinity />}>
          <VaginalType />
        </Suspense>
      </section>
    </PageContainer>
  );
};
