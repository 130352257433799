import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { getMainAccountId } from 'store/account';
import { Subscription } from 'types/Subscription';

export async function getSubscriptions() {
  const response = await API.get(
    apiName,
    `${apiBasePath}/subscriptions?type=kits`,
    {},
  );
  return response?.result;
}

const useSubscriptions = () => {
  const mainAccountId = useSelector(getMainAccountId);

  const subscriptionsListQuery = useQuery<Subscription[]>({
    queryKey: [apiName, `subscriptionsList`, mainAccountId],
    queryFn: () => getSubscriptions(),
  });

  return subscriptionsListQuery;
};

export default useSubscriptions;
