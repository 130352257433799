import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TinyAccount from 'types/TinyAccount';

import { Card, Icon, NewFamilyMemberModal, Typography } from 'components';

import styles from './NewFamilyMemberCard.module.scss';

export const NewFamilyMemberCard = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/?account=${tinyAccount.id}`);
      }
    },
    [navigate],
  );

  return (
    <>
      <NewFamilyMemberModal
        toggle={() => setShowModal(prev => !prev)}
        showModal={showModal}
        //after a new family member is created we select it (same as clicking on it afterwards)
        onFinishCallback={tinyAccountSelected}
        withProfile={true}
      />
      <div className={styles.content} onClick={() => setShowModal(true)}>
        <Card>
          <div className='flex align-items-center gap-2'>
            <Icon name='add' />
            <Typography variant='heading-s'>
              Want to add another family profile?
            </Typography>
          </div>
        </Card>
      </div>
    </>
  );
};
