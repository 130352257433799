import { useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import useFeature from 'services/features/useFeature';
import { colorForTinyAccount } from 'helpers';
import { getTinyAccountsOrderedByLatestKitResults } from '../../store/account';
import TinyAccount from 'types/TinyAccount';

import { Icon, NewFamilyMemberModal, Typography } from 'components';

import styles from './Kits.module.scss';

export const MyFamily = ({
  type = 'row',
  tinyAccounts,
  onSelection,
  title = '',
  strictDisplayView = false,
  withProfile = false,
}: {
  type?: 'row' | 'column';
  tinyAccounts?: TinyAccount[];
  onSelection?: (tinyAccount?: Partial<TinyAccount>) => void;
  title?: string;
  strictDisplayView?: boolean;
  withProfile?: boolean;
}) => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const [showModal, setShowModal] = useState(false);

  const handleSelection = (tinyAccount: TinyAccount) => {
    //call the function passed down on props with the selected family member (tinyAccount)
    if (onSelection && typeof onSelection === 'function') {
      onSelection(tinyAccount);
    }
  };

  return strictDisplayView &&
    tinyAccounts &&
    tinyAccounts.length === 0 ? null : (
    <>
      <NewFamilyMemberModal
        toggle={() => setShowModal(prev => !prev)}
        showModal={showModal}
        //after a new family member is created we select it (same as clicking on it afterwards)
        onFinishCallback={onSelection}
        withProfile={showProfiles && withProfile}
      />

      <div
        data-testid={`${type === 'row' ? 'row' : 'column'}Container`}
        className={c(
          styles.familyContainer,
          type === 'row' ? styles.rowContainer : styles.columnContainer,
        )}
      >
        {title && (
          <Typography variant='heading-m' className='dark-grey-text'>
            {title}
          </Typography>
        )}
        <div
          data-testid='familyAvatarWrapper'
          className={styles.avatarsWrapper}
        >
          {tinyAccounts?.map(ta => (
            <div
              key={ta.id}
              data-testid='familyAvatar'
              className={c(styles.avatarButton)}
              onClick={() => handleSelection(ta)}
            >
              <div
                className={c(styles.avatarBubble)}
                style={{
                  backgroundColor: colorForTinyAccount(
                    orderedTinyAccounts,
                    ta.id,
                  ),
                }}
              >
                {ta.first_name.substr(0, 1)}
              </div>
              <div className={styles.avatarName}>{ta.first_name}</div>
            </div>
          ))}
          {!strictDisplayView && (
            <div
              className={c(styles.avatarButton)}
              onClick={() => setShowModal(true)}
            >
              <div
                data-testid='addNew'
                className={c(styles.avatarBubble, styles.bubbleAdd, 'btn')}
              >
                <Icon name='plus' size='m' />
              </div>
              <div className={styles.avatarName}>Add New</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
