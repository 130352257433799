import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import { kitStatusColorParser, kitStatusParser, parseDate } from 'helpers';
import { getSurveysStatusForKitId } from 'store/kits';
import TinyAccount from 'types/TinyAccount';
import Kit from 'types/Kit';

import { Badge, Card, Icon, CircularProgress } from 'components';

import styles from './KitCard.module.scss';

export const KitCard = ({
  tinyAccount,
  kit,
  maxWidthSm = false,
  pointer = false,
}: {
  tinyAccount: TinyAccount;
  kit: Kit;
  maxWidthSm?: boolean;
  pointer?: boolean;
}) => {
  const surveysStatus = useSelector(getSurveysStatusForKitId(kit.id));
  const navigate = useNavigate();
  if (!kit.status) return null;
  const isVoid = kitStatusParser(kit.status) === 'Void';
  const kitType = kit.sampling_type === 'stool' ? 'Gut' : 'Vag';
  const formattedDate = format(
    parseDate(kit.sample_date) ?? new Date(),
    'MM/dd/yy',
  );

  //check for the survey status
  const neededSurveys = surveysStatus?.needed.length ?? 0;
  const completedSurveys =
    Array.from(new Set(surveysStatus?.completed.map(obj => obj.typeform_id)))
      .length ?? 0;

  const titleTemplate = (
    <div
      className={c(
        'flex align-items-center justify-content-between w-100',
        styles.customTitle,
      )}
    >
      <span>{`${kitType} test @${kit.sample_label}`}</span>
      {!isVoid && <Icon name='chevronForward' size='m' />}
    </div>
  );

  const handleCardClick = () => {
    if (!isVoid) {
      navigate(`/results/${kit.id}`);
    }
  };

  return (
    <div
      className={c(
        styles.kitCard,
        maxWidthSm ? styles.maxWidthSm : '',
        pointer && !isVoid ? styles.pointer : '',
      )}
      onClick={handleCardClick}
    >
      <Card title={titleTemplate}>
        <div className='flex flex-column gap-1'>
          <div className={styles.secondaryText}>
            <div className='flex align-items-center justify-content-between gap-1 w-100'>
              <div className='flex align-items-center gap-1'>
                <Icon name='calendarFilled' size='s' />
                <span className='font-weight-light'>{formattedDate}</span>
              </div>
              <div className='flex align-items-center gap-1'>
                <Icon name='priceTagFilled' size='s' />
                <span className='font-weight-light'>{`Kit ${kit.id}`}</span>
              </div>
            </div>
          </div>
          <div className='flex my-2'>
            <Badge
              title={kitStatusParser(kit.status)}
              state={kitStatusColorParser(kit.status)}
            />
          </div>
          {!isVoid && (
            <div className='flex align-items-center gap-4'>
              <CircularProgress
                strokeWidth={8}
                percentage={(completedSurveys * 100) / neededSurveys}
                label={`${completedSurveys} / ${neededSurveys}`}
              />
              <div className='flex flex-column'>
                <span>Surveys </span>
                <span>Complete</span>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
