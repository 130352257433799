import { Modal } from 'reactstrap';
import c from 'classnames';
import { Icon, Typography } from 'components';

import styles from './SlideUpModal.module.scss';

type SlideUpModalProps = {
  toggle: () => void;
  showModal: boolean;
  title?: string;
  collapse?: boolean;
  children?: React.ReactNode;
};
const SlideUpModal = ({
  showModal,
  toggle,
  title,
  collapse,
  children,
}: SlideUpModalProps) => {
  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      contentClassName={styles.modalContent}
      fullscreen='md'
    >
      {showModal && (
        <>
          <div className={styles.modalHeader}>
            {title && (
              <Typography
                variant='label'
                className={c(styles.modalTitle, 'mx-auto')}
              >
                {title}
              </Typography>
            )}
            <div className={styles.modalClose} role='button' onClick={toggle}>
              <Icon name={collapse ? 'collapse' : 'closeSm'} size='m' />
            </div>
          </div>
          <div className={styles.modalBody}>{children}</div>
        </>
      )}
    </Modal>
  );
};

export default SlideUpModal;
