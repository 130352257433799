import { addWeeks, format } from 'date-fns';
import { kitResultsViewable } from 'helpers';
import { parseDate } from 'helpers/parseDate';
import { KitSurveyStatus } from 'types/Survey';
import { Step } from 'views/Results/KitProcess/KitProcess';

export const getKitProcessSteps = (status?: KitSurveyStatus): Step[] => [
  {
    title: 'Kit activated',
    getStepContent: kit => {
      return {
        description: `Your kit was successfully activated${
          kit?.date_activated
            ? ` on ${format(parseDate(kit.date_activated), 'MMMM d')}`
            : ''
        }.`,
        icon: 'checkBackground',
        iconColor: 'green',
      };
    },
  },
  {
    title: 'Complete your surveys',
    getStepContent: kit => {
      const pendingSurveys = !!status?.pending?.length;
      if (!pendingSurveys)
        return {
          description: `Your surveys were successfully completed.`,
          icon: 'checkBackground',
          iconColor: 'green',
        };
      //since we have pending surveys, depending if we already have results from lab:
      return kitResultsViewable(kit)
        ? {
            description: `We’re not able to deliver your report until all surveys are completed.`,
            icon: 'alertTriangleDoubleFilled',
            iconColor: 'red',
          }
        : {
            description: `We need a bit more info to interpret your sample and won’t be able to deliver your report without it.`,
            icon: 'checkBackground',
            iconColor: 'grey',
          };
    },
  },
  {
    title: 'Lab analysis',
    getStepContent: kit => {
      if (kit?.date_sequences_delivered)
        return {
          description: `Our lab has finished processing your sample and your results will be ready soon.`,
          icon: 'checkBackground',
          iconColor: 'green',
        };
      const processingTime = '3-4 weeks';
      const desc = kit?.date_delivered_to_lab
        ? `Your sample was delivered to our lab on ${format(
            parseDate(kit.date_delivered_to_lab),
            'MMMM d',
          )}. We estimate you will have your results by ${format(
            addWeeks(parseDate(kit.date_delivered_to_lab), 4),
            'MMMM d',
          )}.`
        : kit?.date_in_transit_to_lab
        ? `Your sample is en route to our lab. Once received by the lab, your sample will take ${processingTime} to process.`
        : `Please mail your sample using the envelope provided. Once received by the lab, your sample will take ${processingTime} to process.`;
      return {
        description: desc,
        icon: 'checkBackground',
        iconColor: 'grey',
      };
    },
  },
  {
    title: 'Wrapping up results',
    getStepContent: kit => {
      return {
        description: `We’ll email you when your results are ready`,
        icon: 'checkBackground',
        iconColor: 'grey',
      };
    },
  },
];
