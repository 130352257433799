import { useLocation } from 'react-router-dom';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useProfileQuestions from 'services/general/profileQuestions';

import {
  Icon,
  Card,
  LinkButton,
  ButtonVariant,
  ButtonSize,
  ButtonColor,
  Typography,
} from 'components';

export const ProfileAnswersCard = () => {
  const { pathname } = useLocation();
  const { currentKit, currentKitId, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  const { needsProfile } = useProfileQuestions(
    currentTinyAccountId,
    currentKitId,
  );

  return (
    <Card id={'profileCard'} variant='wrapper' className='p-3'>
      <div className='flex flex-column gap-3'>
        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center justify-content-between gap-1 w-100'>
            <Typography variant='heading-m'>Profile questions</Typography>
            {!needsProfile && (
              <div className='flex align-items-center gap-1'>
                <Icon name='check' size='m' active />
              </div>
            )}
          </div>
          {/* <Typography variant='body-s' className='grey-text'>
            A few questions about what was going on at the time of sampling.
          </Typography> */}
        </div>

        <div className='flex align-items-center justify-content-between gap-1 w-100'>
          {needsProfile ? (
            <LinkButton
              label={
                currentKit?.is_resample ? 'Review & confirm' : 'Get started'
              }
              href={`/profile?account=${currentTinyAccountId}${
                currentKit?.is_resample ? `&review=${currentKitId}` : ''
              }`}
              state={{ from: pathname }}
              variant={ButtonVariant.SOLID}
              size={ButtonSize.S}
              color={ButtonColor.PURPLE}
            />
          ) : (
            <LinkButton
              label='View response'
              href={`/results/${currentKitId}/surveys/profile`}
              state={{ from: pathname }}
              variant={ButtonVariant.TEXT}
              size={ButtonSize.S}
              color={ButtonColor.PURPLE}
            />
          )}
        </div>
      </div>
    </Card>
  );
};
