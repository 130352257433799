import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { ProfileSurveys } from 'types/Surveys';

export const postSurveySubmitted = async ({
  tinyaccount_id,
  kit_id,
  form_id,
}: {
  tinyaccount_id: string;
  kit_id?: string;
  form_id: string;
}) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/tinyAccounts/${tinyaccount_id}/typeform_submit`,
    {
      form_id,
      kit_id: kit_id ?? null,
    },
  );
  return response;
};
const useSubmitFinishedSurvey = () => {
  const trackEvent = useAnalytics().trackEvent;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (properties: {
      form_id: string;
      tinyaccount_id: string;
      kit_id: string;
    }) => {
      return postSurveySubmitted(properties);
    },
    onMutate: async variables => {
      const queryKey = [
        apiName,
        `profileSurveys`,
        variables.tinyaccount_id,
        variables.kit_id,
      ];
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousSurveys = queryClient.getQueryData(queryKey);
      // Optimistically update the cache with the new value
      queryClient.setQueryData(queryKey, (oldSurveys: ProfileSurveys[]) => {
        return oldSurveys.map(oldSurvey =>
          oldSurvey.typeform_survey_id === variables.form_id
            ? { ...oldSurvey, is_complete: true }
            : oldSurvey,
        );
      });
      // Return the previous value in case of error
      return { previousSurveys };
    },
    onSuccess(data, variables, context) {
      toast.success('Survey Completed');
      trackEvent('Survey Completed', variables);
    },
    onError: (err, variables, context) => {
      const queryKey = [
        apiName,
        `profileSurveys`,
        variables.tinyaccount_id,
        variables.kit_id,
      ];
      // Roll back the cache to the previous value
      queryClient.setQueryData(queryKey, context?.previousSurveys);
      toast.error(
        'There was an error submitting the survey. Please try again.',
      );
      Sentry.captureException(err);
    },
    onSettled: (data, error, variables, context) => {
      const queryKey = [
        apiName,
        `profileSurveys`,
        variables.tinyaccount_id,
        variables.kit_id,
      ];
      // Invalidate the query to refetch the updated data from the backend
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
export default useSubmitFinishedSurvey;
