import { NavLink } from 'react-router-dom';
import c from 'classnames';

import styles from './PageHeader.module.scss';
import { Icon } from 'components/Icon';

export const PageHeader = ({
  linkBack,
  title,
}: {
  linkBack?: string;
  title: string;
}) => {
  return (
    <div className={styles.container}>
      {!!linkBack && (
        <NavLink to={linkBack} className={c('green', styles.back)}>
          <Icon name='chevronBack' size='s' />
          <span className={styles.backLabel}>Back</span>
        </NavLink>
      )}
      {title && <h2 className={styles.headerTitle}>{title}</h2>}
      <span className={styles.rightPlaceholder}>&nbsp;</span>
    </div>
  );
};
