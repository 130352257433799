import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useFeature from 'services/features/useFeature';
import useProfileQuestions from 'services/general/profileQuestions';
import useSampleInfoQuestions from 'services/surveys/useSampleInfoQuestions';
import useUpdateProfileAnswer from 'services/surveys/useUpdateProfileAnswer';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { useUrlQuery } from 'hooks/useUrlQuery';
import {
  ProfileQuestion,
  ProfileQuestionAnswer,
  ProfileQuestionsGroup,
} from 'types/ProfileQuestions';

import {
  ButtonColor,
  ButtonSize,
  ContainerSizes,
  DesignButton,
  Header,
  PageContainer,
} from 'components';
import { ProfileConfirmationGroup } from './ProfileConfirmationGroup';

import styles from './ProfileConfirmation.module.scss';

export type ProfileFormData = {
  [key: string]: number | string | string[];
};
const ProfileConfirmation = ({ viewOnly }: { viewOnly?: boolean }) => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const flowType = useUrlQuery().get('flow');
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  if (!showProfiles) return <></>;
  if (currentKitId && currentTinyAccountId) {
    return flowType === 'sampleinfo' ? (
      <SampleInfoFlow
        tinyAccountId={currentTinyAccountId}
        kitId={currentKitId}
        viewOnly={viewOnly}
      />
    ) : (
      <ProfileFlow
        tinyAccountId={currentTinyAccountId}
        kitId={currentKitId}
        viewOnly={viewOnly}
      />
    );
  }
  return <></>;
};

const ProfileFlow = ({
  tinyAccountId,
  kitId,
  viewOnly,
}: {
  tinyAccountId: string;
  kitId: string;
  viewOnly?: boolean;
}) => {
  const { data: profileQuestions } = useProfileQuestions(tinyAccountId, kitId);
  const profileAnswerMutation = useUpdateProfileAnswer();

  const mutationCallback = useCallback(
    (answers: ProfileQuestionAnswer[]) => {
      return profileAnswerMutation.mutateAsync({
        answers,
        tinyAccountId,
        kitId,
      });
    },
    [profileAnswerMutation],
  );

  if (!tinyAccountId) return <></>;
  return (
    <THSurveyConfirmation
      questionGroups={profileQuestions}
      mutationCallback={mutationCallback}
      kitId={kitId}
      viewOnly={viewOnly}
    />
  );
};

const SampleInfoFlow = ({
  tinyAccountId,
  kitId,
  viewOnly,
}: {
  tinyAccountId: string;
  kitId: string;
  viewOnly?: boolean;
}) => {
  const { data: sampleInfoQuestions } = useSampleInfoQuestions(
    tinyAccountId,
    kitId,
  );
  const profileAnswerMutation = useUpdateProfileAnswer();

  const mutationCallback = useCallback(
    (answers: ProfileQuestionAnswer[]) => {
      return profileAnswerMutation.mutateAsync({
        answers,
        tinyAccountId,
        kitId,
      });
    },
    [profileAnswerMutation],
  );

  if (!tinyAccountId) return <></>;
  return (
    <THSurveyConfirmation
      questionGroups={sampleInfoQuestions}
      mutationCallback={mutationCallback}
      kitId={kitId}
      viewOnly={viewOnly}
    />
  );
};

const THSurveyConfirmation = ({
  questionGroups,
  mutationCallback,
  kitId,
  viewOnly,
}: {
  questionGroups?: ProfileQuestionsGroup[] | null;
  mutationCallback?: (answers: ProfileQuestionAnswer[]) => Promise<void>;
  kitId: string;
  viewOnly?: boolean;
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');

  const onSubmit = async () => {
    setIsSubmitting(true);
    const allQuestions =
      questionGroups?.reduce<ProfileQuestion[]>(
        (
          accumulator: ProfileQuestion[],
          currentGroup: ProfileQuestionsGroup,
        ) => {
          return accumulator.concat(currentGroup.questions);
        },
        [],
      ) ?? [];
    const answers = allQuestions?.map(question => {
      return { question_id: question.id, answer_text: question.answer_text };
    });
    if (mutationCallback) {
      try {
        await mutationCallback(answers);
        if (isHomeScreenEnabled) {
          navigate(state?.from ? state.from : `/`);
        } else {
          navigate(state?.from ? state.from : `/results/${kitId}`);
        }
        toast.success('Answers saved successfully');
      } catch (error) {
        toast.error('Something went wrong, please try again');
      }
    }
    setIsSubmitting(false);
  };

  if (!questionGroups?.length) return <></>;
  return (
    <>
      <Header
        title={viewOnly ? 'View your answers' : 'Review your previous answers'}
      />
      <PageContainer
        size={ContainerSizes.SM}
        className={styles.profileContainer}
      >
        <div className={styles.groupsContainer}>
          {questionGroups.map(questionGroup => (
            <ProfileConfirmationGroup
              key={questionGroup.id}
              questionGroup={questionGroup}
              viewOnly={viewOnly}
            />
          ))}
          <div className={styles.buttons}>
            {!viewOnly && (
              <DesignButton
                label='Continue'
                loading={isSubmitting}
                size={ButtonSize.M}
                color={ButtonColor.PURPLE}
                fullWidth
                className='mt-2'
                onClick={onSubmit}
              />
            )}
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default ProfileConfirmation;
