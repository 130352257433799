import c from 'classnames';

import { Button } from 'components';

import styles from './TaxaFilters.module.scss';

export const TaxaFilter = ({
  title,
  active,
  color,
  onClick,
}: {
  title: string;
  active: boolean;
  color: string;
  onClick: () => void;
}) => {
  return (
    <div className={c(styles.inlineButton)} onClick={onClick}>
      <Button
        color={active ? color : ''}
        size='small'
        className={c(
          styles.taxaFilterButton,
          styles[color],
          active ? styles.active : styles.inactive,
        )}
      >
        {title}
      </Button>
    </div>
  );
};
