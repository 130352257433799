import { KitType } from './Kit';

export enum AssociationEvaluation {
  GOOD = 'good',
  BAD = 'bad',
}
export enum AssociationDirection {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type Association = {
  id: string;
  display_name: string;
  evaluation: AssociationEvaluation;
  sampling_type: KitType;
  direction?: AssociationDirection; //extra from relation table
};
