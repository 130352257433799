import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { KitType } from '../../types/Kit';

export const getTaxaBarChartData = async (kitId: string, kitType: KitType) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/kits/gut_vag_composition/${kitId}/${kitType}`,
    {},
  );

  return response?.result;
};
type TaxaBarChartData = {
  [key: string]: number;
};
export const getTaxaBarChartDataQuery = (
  kitId?: string,
  kitType?: KitType,
) => ({
  queryKey: [apiName, 'taxaBarChartData', kitId],
  queryFn: () =>
    (kitId && kitType && getTaxaBarChartData(kitId, kitType)) || null,
});
const useTaxaBarChartData = (kitId: string, kitType = KitType.STOOL) => {
  const gutTypeQuery = useSuspenseQuery<TaxaBarChartData | null>(
    getTaxaBarChartDataQuery(kitId, kitType),
  );

  return gutTypeQuery;
};
export default useTaxaBarChartData;
