import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { capitalizeFirstLetter, copyToClipboard } from 'helpers';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { Icon } from 'components';

import styles from './CopyField.module.scss';

const CopyField = ({ value, name }: { value: string; name: string }) => {
  const { trackEvent } = useAnalytics();

  const copyCodeToClipboard = useCallback(() => {
    if (!value) return;
    copyToClipboard(value);
    toast.success(`${capitalizeFirstLetter(name)} copied to clipboard`);
    trackEvent('Clicked Button', {
      text: `Copy ${name}`,
      href: '',
      variety: 'Button',
    });
  }, [name, trackEvent, value]);
  return (
    <div className={styles.copyField}>
      <span>{value}</span>
      <>
        <label htmlFor='copy-button' className='hidden'>
          Copy {name}
        </label>
        <button
          id='copy-button'
          className={styles.copyIcon}
          onClick={copyCodeToClipboard}
        >
          <Icon name='copy' size='m' />
        </button>
      </>
    </div>
  );
};
export default CopyField;
