export type ImportantQuestionsBySurveyId = {
  profile?: string[];
  sample?: string[];
  gcKqsqD8?: string[];
  Jz2sYg0x?: string[];
  skpTe0TS?: string[];
  IYH0dy52?: string[];
  X9ri1D2J?: string[];
  HyCkVdiY?: string[];
  Qqup3Txd?: string[];
};

const importantQuestionsBySurveyType: ImportantQuestionsBySurveyId = {
  profile: [
    '22',
    '24',
    '26',
    '36',
    '10',
    '14',
    '16',
    '6',
    '18',
    '38',
    '39',
    '45',
    '46',
  ],
  sample: [],
  //th_diet_bundle
  gcKqsqD8: [
    't5MBKmKSfpU3',
    '95rCIMoLw0xT',
    '908fKUlj0Adt',
    'fxRWeLRK1Cx3',
    'PeCwIsRyad6v',
    'AtaHheTSTK8P',
    'CvXE4XEMN7vW',
    'onK7QlGUoNF1',
    'itkURYNPuSqf',
    'f2R3DUzDH8dQ',
  ],
  //th_ttc_bundle
  Jz2sYg0x: ['vKCshAZWz4U9', 'gexrMQobvZiC'],
  //th_pregnancy_adult_bundle
  skpTe0TS: ['mIImNU6oOmC9', 'KdJVSSbABGCy', 'PqJdhJGWz152'],
  //th_pregnancy_baby_bundle
  IYH0dy52: [
    'UJSWLGluUqjx',
    'ySIFsOPimvTQ',
    'OTGIRNCMYYhy',
    'oa5TKF814h0o',
    'mpPKupldzYw8',
    '6Zd3zouYWsJ9',
    '1FpLE6AX5cCk',
    'sPRB8ZpogEFO',
    'wp7shnhTukZL',
    'kgsg5ZT64u1j',
    'Rdhbx6dXizJS',
    'PUAdjkSS38eN',
    'Fdr5RHcyAz0t',
    '6p76EkZ5JVwz',
    'qCC4bA6RGSJm',
  ],
  //th_conditions_bundle
  X9ri1D2J: [
    'FUThGkqGg3Wc',
    'Qj3sAitK2A3I',
    'tn4CzEabUxYD',
    'kYM1ii5ljITa',
    'u8zSnvf761uh',
    'j9hPCDOwWAs0',
    'rsnuigGOIaKs',
    'zVHD3Nz1R6Cr',
    'C6jYXdnYlu9q',
    'LhdfM87uYidg',
    'IDYI931skETS',
    'jPDe4OrjVmUD',
    'ji3cbldwyu4d',
    '68geoyijLhlB',
    'jvAjmYIwy6kP',
    'EaJJyH2eeD0K',
    'keuSa7Qj2IXT',
    'FwBL0MfT0num',
    'PpWt74rdcOwD',
    'Stws5dC5V8Y6',
    'jR6dqdkQKmrB',
    'TcniRfEVKwUS',
    'J6XOBvPMNnmd',
    'eovL20lNu3bv',
    'w8h5ICq0x9ru',
    'IMA4DddlX26z',
    'SrHLp1YBa1uS',
    '0F9NSxBWHAmk',
    's61niQRyFz3J',
    'I9cyCcDP7Be3',
    'J9gCPjmwVRkh',
    'gFhW0Ym9lPqx',
    'Yc3XBoacvOqU',
    'T1mqFOEmi15M',
    'bdvJWiKGzC93',
    'cRuLXaTpViRg',
    'b4SFr8JLRIUL',
    'tjabagQL6Pjj',
    '2YNwp5hfCdYm',
    'm9gpoTHSztYr',
    'XL2DUFj9NadK',
    'WX9L5syG9WDn',
    'Yrou5rVz57mb',
    'he98EOgG9iWZ',
  ],
  //th_daily_life_bundle
  HyCkVdiY: [
    '3RY9wwUE6DJ1',
    'igSDGpBncSca',
    'yZywYdrY3mSL',
    '7veysb6q77Mg',
    'shtGT8rN6EwV',
    'M15SqmXyUkLg',
    'FFTPU85vVJPU',
    '43yYlHL0cxfC',
    'TNtwufciIMJf',
    'MIZ8qypPCo2h',
    'kW62KWVUXxgh',
    'nSL1zNG7AUdE',
    '4AvCR4KOZiS1',
  ],
  //th_medication_bundle
  Qqup3Txd: [
    'nMwulgwgCbYI',
    '1DboMNKFdj4k',
    'hvnMBJZ6RkTV',
    'rlIoojuMxycZ',
    'Yl2pbTWifPb5',
    'lqyhU3cGIBaU',
    '9zuIpEZETSzJ',
    'YqsUQP5iWjaI',
    'MFNFthmZv2Ms',
    'Et9FDovuonSK',
    'ZNxr2jxefbpP',
    'Mh8GDVttovjB',
    'ue3wuklo3GzF',
    'RLxZa2LQNSER',
    'EKCSROPdB4fG',
    '8jqNjYWFWeDW',
    '8Viq5E3YU8ye',
    'bATrNjnfKCMV',
    'GPSZbk47h19P',
    'tg3bJRNf4bdc',
    'Xl8AWDcJIQ3v',
    'Ok4bqyW4NXMb',
    'AgSBxZ3G3VcM',
    'ZxKlGAJan0LS',
    'YpaXDsn3xZtZ',
    'X6vVrJkdGYNQ',
    'RwT3ZBXEvolH',
    'YRNQuPRdPItj',
    '1SJR0JYMELZx',
    'EPgvjllC4hDz',
    'JBV0jjo1VWok',
    'y4NNgpCzXU18',
    'LXnsnCgr5Kf0',
  ],
};

export const getImportantSurveyQuestionsWithProfile =
  (): ImportantQuestionsBySurveyId => {
    return importantQuestionsBySurveyType;
  };
