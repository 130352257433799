import { NavLink } from 'react-router-dom';
import c from 'classnames';

import usePartner from 'services/partner/usePartner';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';

import WhiteLogoSvg from 'assets/images/logos/logo-full-white.svg?react';
import LogoSvg from 'assets/images/logos/TH-logo.svg?react';

import styles from './Brand.module.scss';

export const WhiteLogo = () => (
  <div className={styles.logoWrap}>
    <WhiteLogoSvg className={c(styles.logoSvg)} />
  </div>
);
export const Logo = () => (
  <div className={styles.logoWrap}>
    <LogoSvg className={styles.logoSvg} />
  </div>
);
export const Brand = ({ href = '/', color = 'dark' }) => {
  const { data: partner } = usePartner();
  const { currentTinyAccount } = useCurrentKitAndTinyAccount();
  return (
    <NavLink
      to='/'
      state={{ tinyAccount: currentTinyAccount }}
      className={styles.logoLink}
    >
      <h1 className={'invisible'}>Tiny Health</h1>
      {color !== 'dark' ? (
        partner?.light_logo ? (
          <div className={styles.logoWrap}>
            <img
              className={styles.logoSvg}
              src={partner?.light_logo}
              alt='partner logo'
            />
          </div>
        ) : (
          <WhiteLogo />
        )
      ) : partner?.logo ? (
        <div className={styles.logoWrap}>
          <img
            className={styles.logoSvg}
            src={partner?.logo}
            alt='partner logo'
          />
        </div>
      ) : (
        <Logo />
      )}
    </NavLink>
  );
};
