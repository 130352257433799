import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format, differenceInCalendarMonths } from 'date-fns';
import c from 'classnames';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useTaxaOverTime from 'services/insights/useTaxaOverTime';
import { userValueFormat } from 'helpers';
import { LINKS } from 'assets';
import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { ButtonColor, ButtonSize, ButtonVariant, LinkButton } from 'components';

import styles from './TaxaOverTimeLineChart.module.scss';

type TaxaOverTimeLineChartProps = {
  taxa: number[];
  range: number;
  dataType: string;
  animate?: boolean;
};
export const TaxaOverTimeLineChart = ({
  taxa,
  range,
  dataType,
  animate = true,
}: TaxaOverTimeLineChartProps) => {
  const { currentTinyAccount, currentKitId } = useCurrentKitAndTinyAccount();
  const isTinyPlus = useSelector(getIsTinyPlus);
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const { data: taxaOverTimeData } = useTaxaOverTime({
    kitId: currentKitId,
    metricId: taxa[0],
  });

  const currentKitBarIndex: number | null = useMemo(() => {
    let tempIndex = null;
    taxaOverTimeData?.forEach((data, idx) => {
      if (data.kit_id === currentKitId) {
        tempIndex = idx;
      }
    });
    return tempIndex;
  }, [taxaOverTimeData, currentKitId]);

  const needsExtraPadding = (axisLabel: string) => {
    return axisLabel?.length > 5;
  };

  type ThisSampleDotProps = {
    cx?: number;
    cy?: number;
    index?: number;
    payload?: { evaluation_color: string };
    sampleIndex: number | null;
    isActive: boolean;
  };
  const ThisSampleDot = (props: ThisSampleDotProps) => {
    const { cx, cy, index, sampleIndex, payload, isActive } = props;
    let translateX = 0; //for points in the boundaries
    const color = useMemo(
      () =>
        !payload?.evaluation_color
          ? 'var(--warm-grey-400)'
          : payload.evaluation_color === 'red'
          ? 'var(--red-200)'
          : payload.evaluation_color === 'green'
          ? 'var(--green-200)'
          : payload.evaluation_color === 'orange'
          ? 'var(--orange-200)'
          : 'var(--warm-grey-400)',
      [payload],
    );
    if (index === 0) translateX = 25;
    if (index === taxaOverTimeData.length - 1) translateX = -25;
    if (cx === undefined || cy === undefined) return <></>;
    //each of the sample dots except for "this sample"
    if (index !== sampleIndex || taxaOverTimeData.length === 1) {
      return (
        <svg
          x={cx - 35}
          y={cy - 55}
          width='71'
          height='68'
          overflow='visible'
          viewBox='0 0 73 68'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='31'
            y='50.5'
            width='10'
            height='10'
            rx='5'
            fill={isActive ? color : 'white'}
            stroke={color}
            strokeWidth='2'
          />
        </svg>
      );
    }
    //special case for "this sample"
    return (
      <svg
        x={cx - 35}
        y={cy - 55}
        width='71'
        height='68'
        overflow='visible'
        viewBox='0 0 73 68'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='26'
          y='45.5'
          width='20'
          height='20'
          rx='10'
          fill='var(--purple-200)'
          fillOpacity='0.3'
        />
        <rect
          x='28.5'
          y='48'
          width='15'
          height='15'
          rx='7.5'
          fill='white'
          fillOpacity='0.9'
        />
        <rect
          x={1 + translateX}
          y={16}
          width='71'
          height='21'
          rx='12'
          ry='12'
          fill='var(--purple-100)'
          stroke='var(--purple-100)'
          strokeWidth='1'
        />
        <text
          textAnchor='middle'
          dy={30}
          dx={37 + translateX}
          fontSize={11}
          fill='var(--purple-300)'
        >
          This sample
        </text>

        <rect
          x='31'
          y='50.5'
          width='10'
          height='10'
          rx='5'
          fill={isActive ? color : 'white'}
          stroke={color}
          strokeWidth='2'
        />
      </svg>
    );
  };

  type ValueAxisTickProps = {
    x?: number;
    y?: number;
    payload?: { value: number };
    dataType: string;
  };
  const FormValueAxisTick = (props: ValueAxisTickProps) => {
    if (props.x === undefined || props.y === undefined || !props.payload?.value)
      return <></>;
    return (
      <g transform={`translate(${props.x}, ${props.y})`}>
        <text x={0} y={0} textAnchor='end' fill='var(--warm-grey-300)'>
          {userValueFormat(props.payload.value, props.dataType)}
        </text>
      </g>
    );
  };

  const formatSampleDateLabel = useCallback(
    (sampleDate: Date) => {
      if (!currentTinyAccount?.birthdate) return 'Unknown';
      const birthDate: Date = new Date(currentTinyAccount.birthdate);
      const ageInYears = Math.floor(
        (sampleDate.getTime() - birthDate.getTime()) /
          (1000 * 60 * 60 * 24 * 365),
      );
      return ageInYears < 3
        ? differenceInCalendarMonths(sampleDate, birthDate) + ' mo.'
        : format(sampleDate, 'MMM d');
    },
    [currentTinyAccount],
  );

  type DateAxisTickProps = {
    x?: number;
    y?: number;
    payload?: { value: string };
  };
  const FormDateAxisTick = (props: DateAxisTickProps) => {
    const displayValue = useMemo(
      () =>
        !props.payload?.value
          ? ''
          : formatSampleDateLabel(new Date(props.payload.value)),
      [props.payload],
    );
    if (props.x === undefined) return <></>;
    return (
      <g
        transform={`translate(${
          props.x + (needsExtraPadding(displayValue) ? 21 : 16) //this helps centering the label
        }, ${props.y})`}
      >
        <text x={0} y={0} dy={12} textAnchor='end' fill='var(--warm-grey-300)'>
          {displayValue}
        </text>
      </g>
    );
  };

  type CustomTooltipProps = {
    dataType: string;
    payload?: { value: number; payload: { evaluation_color: string } }[];
    active?: boolean;
  };
  const CustomTooltip = ({ active, payload, dataType }: CustomTooltipProps) => {
    if (active && payload?.length && payload[0]?.value) {
      return (
        <div
          className={c(
            styles.taxaOverTimeTooltip,
            !!payload[0]?.payload?.evaluation_color &&
              styles[payload[0].payload.evaluation_color],
          )}
        >
          <span className='my-1'>
            {userValueFormat(payload[0].value, dataType)}
          </span>
        </div>
      );
    }
    return null;
  };

  const firstSampleDateLabel = useMemo(
    () =>
      taxaOverTimeData?.length > 0 && taxaOverTimeData[0]
        ? formatSampleDateLabel(new Date(taxaOverTimeData[0].sample_date))
        : '',
    [formatSampleDateLabel, taxaOverTimeData],
  );

  return taxaOverTimeData?.length ? (
    <div className={styles.overtimeWrapper}>
      <ResponsiveContainer width='99%' height={200}>
        <AreaChart
          id={taxa.join('')}
          data={taxaOverTimeData}
          margin={{
            left: needsExtraPadding(firstSampleDateLabel) ? 20 : 15,
            // right: 15,
            right: 0,
            top: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid
            stroke={'var(--warm-grey-300)'}
            strokeDasharray='3 3'
          />
          <XAxis
            dataKey={'sample_date'}
            interval={0}
            tick={<FormDateAxisTick />}
            axisLine={{ stroke: 'var(--warm-grey-400)' }}
            tickLine={{ stroke: 'var(--warm-grey-400)' }}
          />
          <YAxis
            // label={{
            // value: taxa,
            // angle: -90,
            // offset: 9,
            // position: 'right',
            // style: { textAnchor: 'middle', fontSize: '12px' },
            // }}
            orientation='right'
            domain={[0, range / 10000]}
            padding={{ top: 30 }}
            tick={<FormValueAxisTick dataType={dataType} />}
            tickMargin={50}
            axisLine={{ stroke: 'var(--warm-grey-400)' }}
          />
          <Area
            type='linear'
            dataKey='user_value'
            isAnimationActive={animate}
            strokeWidth={2}
            stroke={'var(--warm-grey-400)'}
            fill='transparent'
            activeDot={
              <ThisSampleDot sampleIndex={currentKitBarIndex} isActive={true} />
            }
            dot={
              <ThisSampleDot
                sampleIndex={currentKitBarIndex}
                isActive={false}
              />
            }
            // padding={{ left: 10, right: 10 }}
          />
          <Tooltip
            wrapperStyle={{ position: 'absolute', top: 0, left: 0 }}
            content={<CustomTooltip dataType={dataType} />}
            cursor={false}
          />
        </AreaChart>
      </ResponsiveContainer>
      {taxaOverTimeData?.length === 1 && (
        <div className={styles.resampleContainer}>
          <div className={styles.resampleMessage}>
            <span>Your over time chart will appear here once you resample</span>
            <LinkButton
              label='Order your kit'
              href={`
                ${
                  isAnyTinyPlus
                    ? isTinyPlus
                      ? LINKS.checkoutWithKitTinyPlus
                      : LINKS.checkoutWithKitTinyPlusProgramOrProactive
                    : LINKS.checkoutWithKitNonTinyPlus
                }&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=ki-overtime
                  `}
              external
              variant={ButtonVariant.TEXT}
              size={ButtonSize.M}
              color={ButtonColor.GREEN}
            />
          </div>
        </div>
      )}
    </div>
  ) : null;
};
