import { useLocation } from 'react-router-dom';

import EvaluationSummaryCard from '../EvaluationSummaryCard/EvaluationSummaryCard';
import useGutType from 'services/gutType/useGutType';
import { DiveDeeperSummaryCard } from 'views/Results/Summary/SummaryCards/DiveDeeperSummaryCard/DiveDeeperSummaryCard';
import styles from './GutTypeSummaryCard.module.scss';
import { Suspense } from 'react';
import { Spinfinity, Typography } from 'components';
import { TextColor } from 'components/Typography/Typography';
export const CstSummaryCard = () => {
  const { pathname } = useLocation();
  const { gutVaginalType } = useGutType();
  if (!gutVaginalType) return <></>;
  return (
    <DiveDeeperSummaryCard
      title='Community State Type'
      link={`${pathname}/cst`}
      linkText='Learn more'
    >
      <Suspense fallback={<Spinfinity />}>
        <Typography
          variant='heading-2xl'
          color={TextColor.PURPLE}
          className='my-auto'
        >
          Type {gutVaginalType}
        </Typography>
      </Suspense>
    </DiveDeeperSummaryCard>
  );
};
