import { useForm, Controller } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import {
  add,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  format,
  isBefore,
} from 'date-fns';
import c from 'classnames';

import { parseDate } from 'helpers';
import useUpdateKit from 'services/kits/useUpdateKit';
import useUpdateTinyAccount from 'services/account/useUpdateTinyAccount';

import { DesignButton, ButtonColor, ButtonSize } from 'components';
import { KitStepProps } from 'views/Kits/ActivateKit';
import Select from 'components/Form/Select/Select';

import styles from '../Kits.module.scss';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';

export const getIsAdult = (birthdate: string) => {
  const EighteenYearsAgo = add(new Date(), { years: -18 });
  return isBefore(new Date(birthdate), EighteenYearsAgo);
};

type SampleDetailsFormValues = {
  sampleDate: string;
  babyBirthdate?: string;
  dueDate?: string;
  samplingDetail?: number;
};
const samplingDetailOptions = [
  { label: 'Pregnant', value: 1 },
  { label: 'Previously given birth', value: 2 },
  {
    label: 'Trying to conceive (currently or starting to try soon)',
    value: 3,
  },
  { label: 'None of the above', value: 4 },
];
const SampleDetailsStep = ({
  setActivationState,
  activationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SampleDetailsFormValues>({
    mode: 'onTouched',
  });
  const kitMutation = useUpdateKit();
  const tinyAccountMutation = useUpdateTinyAccount();
  useCurrentKitAndTinyAccount();
  const { tinyAccount, kit } = activationState;
  const isFemale = tinyAccount.sex === 'F';
  const isAdult = getIsAdult(tinyAccount.birthdate ?? '');

  const samplingDetailAnswer = watch('samplingDetail');

  const submitKitDetails = async (formData: SampleDetailsFormValues) => {
    if (!tinyAccount.id || !kit.id) return;
    const sampleDate = parseDate(formData.sampleDate);
    const birthdate = parseDate(tinyAccount.birthdate);
    const babyBirthdate = formData.babyBirthdate
      ? parseDate(formData.babyBirthdate)
      : null;
    const dueDate = formData.dueDate ? parseDate(formData.dueDate) : null;

    const sampleDateYears = differenceInCalendarYears(new Date(), sampleDate);
    const sampleDateMonths = differenceInCalendarMonths(new Date(), sampleDate);
    const sampleDateDays = differenceInCalendarDays(new Date(), sampleDate);

    let weekOfPregnancy = null;
    if (samplingDetailAnswer === 1 && dueDate) {
      const dueDateWeeks = differenceInCalendarWeeks(dueDate, sampleDate);
      weekOfPregnancy = 40 - dueDateWeeks;
    }

    let daysPP = null;
    if (babyBirthdate) {
      daysPP = differenceInCalendarDays(sampleDate, babyBirthdate);
    }

    const ageAtSampleDays = differenceInCalendarDays(sampleDate, birthdate);
    try {
      if (isNaN(sampleDateDays) || isNaN(ageAtSampleDays)) {
        throw new Error('Birthdate is required');
      }
      if (sampleDateYears < 0 || sampleDateDays < 0) {
        throw new Error('Sample cannot be taken in the future');
      }
      if (babyBirthdate && babyBirthdate > sampleDate) {
        throw new Error('Sample cannot be taken before birth');
      }
      if (sampleDateMonths > 3) {
        throw new Error('Sample must be taken within the last 3 months');
      }

      const formattedSampleDate = format(sampleDate, 'yyyy-MM-dd');
      await kitMutation.mutateAsync({
        id: kit.id,
        sampling_type: activationState.kit?.sampling_type,
        sample_date: formattedSampleDate,
        pregnant: samplingDetailAnswer === 1,
        post_partum: !!formData.babyBirthdate,
        age_at_sampling: ageAtSampleDays,
        week_of_pregnancy: weekOfPregnancy,
        days_pp: daysPP,
        //if there is a trying to conceive date
        ttc: samplingDetailAnswer === 3,
        tinyaccount_id: tinyAccount.id,
      });

      const newKit = {
        id: kit.id,
        practitioner: kit.practitioner,
        sampling_type: activationState.kit?.sampling_type,
        sample_date: formattedSampleDate,
        pregnant: samplingDetailAnswer === 1,
        post_partum: !!formData.babyBirthdate,
        age_at_sampling: ageAtSampleDays,
        week_of_pregnancy: weekOfPregnancy,
        days_pp: daysPP,
        ttc: samplingDetailAnswer === 3,
        tinyaccount_id: tinyAccount.id,
      };

      if (samplingDetailAnswer === 1) {
        await tinyAccountMutation.mutate({
          body: { expected_due_date: formData.dueDate },
          tinyAccountId: tinyAccount.id,
        });
      }
      if (samplingDetailAnswer === 2) {
        await tinyAccountMutation.mutate({
          body: { delivery_date: formData.babyBirthdate },
          tinyAccountId: tinyAccount.id,
        });
      }

      if (samplingDetailAnswer === 3) {
        const tryingToConceiveDate = format(new Date(), 'yyyy-MM-dd');
        await tinyAccountMutation.mutate({
          body: { ttc_date: tryingToConceiveDate },
          tinyAccountId: tinyAccount.id,
        });
      }

      setActivationState({ ...activationState, kit: newKit });
      setCurrentActivationStep(step + 1);
    } catch (err) {
      const error = err as Error;
      Sentry.captureException(error);
      if (error?.message) {
        setError('sampleDate', {
          type: 'timing',
          message: error.message,
        });
      }
    }
  };

  return (
    <div className={c(styles.stepContainer)}>
      <h2>Thank you! A few more details please:</h2>
      <form data-testid='sample-form' onSubmit={handleSubmit(submitKitDetails)}>
        <div className={c('field', styles.fieldContainer)}>
          <label htmlFor='sampleDate'>What date was this sample taken?</label>
          <div>
            <input
              id='sampleDate'
              className={c(
                styles.inputField,
                errors.sampleDate && styles.inputError,
              )}
              defaultValue={kit.sample_date}
              placeholder='SampleDate'
              type='date'
              {...register('sampleDate', { required: true })}
            />
            {errors.sampleDate?.type === 'required' && (
              <div
                data-testid='form-error'
                className={c(styles.formError, 'ps-2')}
              >
                Please enter a date
              </div>
            )}
            {errors.sampleDate?.type === 'timing' && (
              <div
                data-testid='form-error'
                className={c(styles.formError, 'ps-2')}
              >
                {errors.sampleDate.message}
              </div>
            )}
          </div>
        </div>

        {isFemale && isAdult && (
          <>
            <div className={c('field', styles.fieldContainer)}>
              <label htmlFor='samplingDetail'>
                At the time of sampling, did any of these apply?
              </label>
              <Controller
                name='samplingDetail'
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    name={name}
                    options={samplingDetailOptions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.samplingDetail?.type === 'required' && (
                <div data-testid='form-error' className={styles.formError}>
                  Please choose an option
                </div>
              )}{' '}
              <p className='mb-0 f7'>
                We use your answer to include insights related to conception and
                pregnancy in your report.
              </p>
            </div>
            {samplingDetailAnswer === 1 && (
              <div className={c('field', styles.fieldContainer)}>
                <label htmlFor='due date'>When is the due date?</label>
                <input
                  className={c(
                    styles.inputField,
                    errors.dueDate && styles.inputError,
                  )}
                  placeholder='Due Date'
                  type='date'
                  {...register('dueDate', {
                    required: samplingDetailAnswer === 1,
                    shouldUnregister: true,
                  })}
                />
                {errors.dueDate?.type === 'required' && (
                  <div data-testid='form-error' className={styles.formError}>
                    Due date is required
                  </div>
                )}
              </div>
            )}
            {samplingDetailAnswer === 2 && (
              <div className={c('field', styles.fieldContainer)}>
                <label htmlFor='birth date'>When was the baby born?</label>
                <input
                  className={c(
                    styles.inputField,
                    errors.babyBirthdate && styles.inputError,
                  )}
                  placeholder='Baby birth date'
                  type='date'
                  {...register('babyBirthdate', {
                    required: samplingDetailAnswer === 2,
                    shouldUnregister: true,
                  })}
                />
                {errors.babyBirthdate?.type === 'required' && (
                  <div data-testid='form-error' className={styles.formError}>
                    Please enter a date
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <DesignButton
          label='Activate this kit'
          type='submit'
          loading={isSubmitting}
          disabled={!isValid}
          size={ButtonSize.M}
          color={ButtonColor.GREEN}
          fullWidth
          className='mt-4'
        />
      </form>
    </div>
  );
};

export default SampleDetailsStep;
