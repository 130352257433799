import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { ProfileQuestion } from 'types/ProfileQuestions';

import { Icon, THField, Typography } from 'components';
import { ProfileFormData } from './Profile';
import Select from 'components/Form/Select/Select';
import THCheckboxGroup from 'components/THCheckbox/THCheckboxGroup';

import styles from './ProfileQuestion.module.scss';

export const ProfileQuestionItem = ({
  question,
  register,
  errors,
  control,
}: {
  question: ProfileQuestion;
  register: UseFormRegister<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
  control: Control<ProfileFormData>;
}) => {
  if (question.answer_type === 'boolean') {
    return (
      <QuestionBoolean question={question} errors={errors} control={control} />
    );
  }
  if (question.answer_type === 'percent') {
    return (
      <QuestionPercent
        question={question}
        register={register}
        errors={errors}
      />
    );
  }
  if (question.answer_type === 'string' && question.answer_options === '') {
    return (
      <QuestionText question={question} register={register} errors={errors} />
    );
  }
  if (question.answer_type === 'number' && question.answer_options === '') {
    return (
      <QuestionNumber question={question} register={register} errors={errors} />
    );
  }
  if (
    question.answer_type === 'string' &&
    question.answer_options?.length > 0
  ) {
    return (
      <QuestionDropdown question={question} errors={errors} control={control} />
    );
  }
  if (question.answer_type === 'array' && question.answer_options?.length > 0) {
    return (
      <QuestionCheckbox question={question} errors={errors} control={control} />
    );
  }
  return <></>;
};

const QuestionBoolean = ({
  question,
  errors,
  control,
}: {
  question: ProfileQuestion;
  errors: FieldErrors<ProfileFormData>;
  control: Control<ProfileFormData>;
}) => {
  const booleanOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div className='flex flex-column gap-1' key={question.id}>
      <Typography variant='body-s'>{question.question_text}</Typography>
      <Controller
        name={question.id + ''}
        control={control}
        defaultValue={question.answer_text}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value, name } }) => {
          if (Array.isArray(value)) return <></>;
          return (
            <Select
              options={booleanOptions}
              name={name}
              value={value}
              onChange={onChange}
            />
          );
        }}
      />
      <div className={styles.errors}>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={question.id + ''}
            render={({ message }) => (
              <div
                className={styles.error}
                id={`error-${question.id}`}
                key={message}
                role='alert'
              >
                <Icon name='alertCircle' size='xs' /> {message}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

const QuestionText = ({
  question,
  register,
  errors,
}: {
  question: ProfileQuestion;
  register: UseFormRegister<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
}) => {
  const questionFormField = register(question.id + '', {
    required: 'This field is required',
  });
  return (
    <div className='flex flex-column gap-1' key={question.id}>
      <THField
        type='text'
        label={question.question_text}
        defaultValue={question.answer_text ?? ''}
        errors={errors}
        {...questionFormField}
      />
    </div>
  );
};

const QuestionNumber = ({
  question,
  register,
  errors,
}: {
  question: ProfileQuestion;
  register: UseFormRegister<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
}) => {
  const questionFormField = register(question.id + '', {
    required: 'This field is required',
  });
  return (
    <div className='flex flex-column gap-1' key={question.id}>
      <THField
        type='number'
        step='0.1'
        label={question.question_text}
        defaultValue={question.answer_text ?? ''}
        errors={errors}
        {...questionFormField}
      />
    </div>
  );
};

const QuestionDropdown = ({
  question,
  errors,
  control,
}: {
  question: ProfileQuestion;
  errors: FieldErrors<ProfileFormData>;
  control: Control<ProfileFormData>;
}) => {
  const dropdownOptions =
    typeof question.answer_options === 'string'
      ? [{ value: question.answer_options, label: question.answer_options }]
      : question.answer_options.map(option => ({
          value: option,
          label: option,
        }));
  return (
    <div className='flex flex-column gap-1' key={question.id}>
      <Typography variant='body-s'>{question.question_text}</Typography>
      <Controller
        name={question.id + ''}
        control={control}
        defaultValue={question.answer_text ?? ''}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value, name } }) => {
          if (Array.isArray(value)) return <></>;
          return (
            <Select
              options={dropdownOptions}
              name={name}
              value={value}
              onChange={onChange}
            />
          );
        }}
      />
      <div className={styles.errors}>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={question.id + ''}
            render={({ message }) => (
              <div
                className={styles.error}
                id={`error-${question.id}`}
                key={message}
                role='alert'
              >
                <Icon name='alertCircle' size='xs' /> {message}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

const QuestionCheckbox = ({
  question,
  errors,
  control,
}: {
  question: ProfileQuestion;
  errors: FieldErrors<ProfileFormData>;
  control: Control<ProfileFormData>;
}) => {
  return (
    <div>
      <div className='flex flex-column gap-2' key={question.id}>
        <Typography variant='body-s'>{question.question_text}</Typography>
        {typeof question.answer_options === 'string' ? (
          question.answer_options
        ) : (
          <THCheckboxGroup
            options={question.answer_options}
            name={question.id + ''}
            control={control}
            id={question.id + ''}
            defaultValues={
              Array.isArray(question.answer_text) ? question.answer_text : []
            }
            rules={{
              validate: value =>
                (Array.isArray(value) && value.length > 0) ||
                'At least one option must be selected',
            }}
          />
        )}
      </div>
      <div className={styles.errors}>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={question.id + ''}
            render={({ message }) => (
              <div
                className={styles.error}
                id={`error-${question.id}`}
                key={message}
                role='alert'
              >
                <Icon name='alertCircle' size='xs' /> {message}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

const QuestionPercent = ({
  question,
  register,
  errors,
}: {
  question: ProfileQuestion;
  register: UseFormRegister<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
}) => {
  const questionFormField = register(question.id + '', {
    required: 'This field is required',
    min: { value: 0, message: 'Min value is 0' },
    max: { value: 100, message: 'Max value is 100' },
  });
  return (
    <div className='flex flex-column gap-1' key={question.id}>
      <THField
        type='number'
        step='1'
        min={0}
        max={100}
        defaultValue={question.answer_text ?? ''}
        label={question.question_text}
        errors={errors}
        {...questionFormField}
      />
    </div>
  );
};
