import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  ContainerSizes,
  LinkButton,
  PageContainer,
  Spinfinity,
} from 'components';

import { SeedingSample } from './SeedingSample';

const CSectionNICU = lazy(() => import('./PieCharts/CSectionNICU'));
const CSectionNoNICU = lazy(() => import('./PieCharts/CSectionNoNICU'));
const VaginalFewerInterventions = lazy(
  () => import('./PieCharts/VaginalFewerInterventions'),
);
const VaginalMoreInterventions = lazy(
  () => import('./PieCharts/VaginalMoreInterventions'),
);
export const SeedingRoutes = () => {
  return (
    <Routes>
      <Route path={``} element={<Seeding />} />
    </Routes>
  );
};
export const Seeding = () => {
  return (
    <PageContainer size={ContainerSizes.MD}>
      <section id='seeding'>
        <h2>Microbiome Seeding</h2>
        <p>
          The microbes you pass to baby set the stage for their gut development.
          Let’s take a look.
        </p>

        <h4>Early microbiome seeding</h4>
        <p>
          Your baby’s earliest sample will show those pioneer microbes that
          seeded from mom during delivery. But microbes can continue to be
          passed from mom during breastfeeding, and those first microbes from
          mom may expand over time.
        </p>

        <Suspense fallback={<Spinfinity />}>
          <SeedingSample />
        </Suspense>

        <h4 className='mt-5'>What impacts seeding at birth?</h4>
        <p>
          Birth mode has the largest demonstrated effect. Vaginal deliveries
          seed more microbes from mom to baby than C-sections.
          <sup>
            <LinkButton
              label='1'
              href='https://pubmed.ncbi.nlm.nih.gov/31534227'
              external
              variant={ButtonVariant.WRAPPER}
              size={ButtonSize.S}
            />
          </sup>
        </p>

        <div className='flex mb-3 justify-content-center'>
          <div className='p-2'>
            <div>Vaginal fewer interventions</div>
            <VaginalFewerInterventions />

            <div>Vaginal more interventions</div>
            <VaginalMoreInterventions />
          </div>
          <div className='p-2'>
            <div>C-section Baby, no NICU</div>
            <CSectionNoNICU />
            <div>C-section Baby in NICU</div>
            <CSectionNICU />
          </div>
        </div>

        <h5>Other factors that may have an impact:</h5>
        <ul>
          <li>
            <p>
              <span>Antibiotics during labor</span>
              <sup>
                <LinkButton
                  label='2'
                  href='https://pubmed.ncbi.nlm.nih.gov/26412384/'
                  external
                  variant={ButtonVariant.WRAPPER}
                  size={ButtonSize.S}
                />
              </sup>
            </p>
          </li>
          <li>
            <p>
              <span>Location (hospital, birthing center, home)</span>
              <sup>
                <LinkButton
                  label='3'
                  href='https://pubmed.ncbi.nlm.nih.gov/32212591/'
                  external
                  variant={ButtonVariant.WRAPPER}
                  size={ButtonSize.S}
                />
                {', '}
                <LinkButton
                  label='4'
                  href='https://pubmed.ncbi.nlm.nih.gov/30353125/'
                  external
                  variant={ButtonVariant.WRAPPER}
                  size={ButtonSize.S}
                />
              </sup>
            </p>
          </li>
          <li>
            <p>Interventions during labor</p>
          </li>
        </ul>
        <p>
          <span>
            After birth, beneficial microbes such as bifidobacteria can transfer
            to baby via breastfeeding.
          </span>
          <sup>
            <LinkButton
              label='5'
              href='https://pubmed.ncbi.nlm.nih.gov/33897650/'
              external
              variant={ButtonVariant.WRAPPER}
              size={ButtonSize.S}
            />
          </sup>
          {' You can '}
          <LinkButton
            label='learn more about seeding on our Tiny Blog'
            href='https://www.tinyhealth.com/blog/microbiome-seeding-at-birth'
            external
            variant={ButtonVariant.TEXT}
            size={ButtonSize.M}
            color={ButtonColor.PURPLE}
          />
          {'.'}
        </p>
      </section>
    </PageContainer>
  );
};
