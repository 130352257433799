import { useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Widget } from '@typeform/embed-react';

import useFeature from 'services/features/useFeature';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import useSubmitFinishedSurvey from 'services/surveys/useSubmitFinishedSurvey';
import { EventPropertiesMap } from 'contexts/analytics/types';
import { getTinyAccountById } from 'store/account';
import { getKitById } from 'store/kits';

import { Header } from 'components';

import styles from './TypeformSurvey.module.scss';

type EventSurveyCompletedWithKit = EventPropertiesMap['Survey Completed'] & {
  kit_id: string; //kit_id is not undefined with the new profile flow, all are kit related surveys
};

const TypeformSurvey = () => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const { tinyAccountId, kitId, typeformId } = useParams<{
    tinyAccountId: string;
    kitId: string;
    typeformId: string;
  }>();
  const navigate = useNavigate();
  const { data: surveys } = useProfileSurveys(tinyAccountId, kitId);
  const [surveyFinished, setSurveyFinished] = useState(false);
  const submitSurveyMutation = useSubmitFinishedSurvey();
  const tinyAccount = useSelector(getTinyAccountById(tinyAccountId));
  const kit = useSelector(getKitById(kitId));

  const currentTypeformSurvey = surveys?.find(
    survey => survey.typeform_survey_id === typeformId,
  );

  const isSurveyCompleted = useMemo(() => {
    return !!typeformId && !!currentTypeformSurvey?.is_complete;
  }, [currentTypeformSurvey, typeformId]);

  const formProperties = useMemo(() => {
    if (!tinyAccountId || !kitId || !currentTypeformSurvey) return {};
    const properties = {} as Record<string, string>;
    currentTypeformSurvey?.form_properties.map(property => {
      properties[property.hidden_field] = property.value;
    });
    return properties;
  }, [tinyAccountId, kitId, currentTypeformSurvey]);

  if (
    !showProfiles ||
    !typeformId ||
    !kitId ||
    !tinyAccountId ||
    !tinyAccount ||
    (!!kitId && (!kit || kit.tinyaccount_id !== tinyAccountId))
  ) {
    //No data, or the data doesn't belong to this mainaccount
    return null;
  }

  const onSubmitTypeform = async () => {
    const eventProperties: EventSurveyCompletedWithKit = {
      kit_id: kitId,
      form_id: typeformId,
      tinyaccount_id: tinyAccountId,
    };
    // redirect to survey complete page
    if (isHomeScreenEnabled) {
      kitId
        ? navigate(`/information/${kitId}`)
        : tinyAccountId
        ? navigate(`/`, { state: { tinyAccountId } })
        : navigate('/');
    } else {
      kitId ? navigate(`/results/${kitId}`) : navigate('/results');
    }
    submitSurveyMutation.mutateAsync(eventProperties);
    setSurveyFinished(true);
  };

  return (
    <>
      {(surveyFinished || isSurveyCompleted) && (
        <Navigate to={kitId ? `/results/${kitId}` : '/results'} replace />
      )}
      {!!currentTypeformSurvey?.name && (
        <Header title={currentTypeformSurvey.name} />
      )}

      <div className={styles.widgetContainer}>
        <Widget
          id={typeformId}
          style={{ width: '100%', height: '100%' }}
          hidden={formProperties}
          onSubmit={onSubmitTypeform}
        />
      </div>
    </>
  );
};

export default TypeformSurvey;
