import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSuspenseQueries } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

import { getEvaluationsQuery } from 'services/insights/useSubMetricEvaluations';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';
import { InsightMetric } from 'types/Insights';
import {
  Accordion,
  ContainerSizes,
  PageContainer,
  Typography,
} from 'components';
import { EvaluationGroupsSummary } from 'views/Results/Insights/EvaluationGroupsSummary/EvaluationGroupsSummary';
import { CategoryMetricsFilter } from 'views/Results/Insights/CategoryMetrics/CategoryMetricsFilter';
import {
  MetricCard,
  SubMetricRow,
} from 'views/Results/Insights/CategoryMetrics/CategoryMetrics';

import styles from './CategoryMetrics.module.scss';

type Categories = {
  [key: string]: {
    priority: number;
    metrics: {
      [key: string]: {
        priority: number;
        submetrics: (InsightMetric & { includedByFilters: boolean })[];
      };
    };
  };
};
export const AllMetrics = () => {
  const { kitId: currentKitId } = useParams<{ kitId: string }>();
  const [{ data: submetrics }] = useSuspenseQueries({
    queries: [
      getEvaluationsQuery(currentKitId),
      getActionPlanItemsQuery(currentKitId),
    ],
  });
  const [evaluationFilters, setEvaluationFilters] = useState<string[]>([]);
  const groupedSubMetrics: Categories = useMemo(
    () =>
      submetrics
        .sort((a, b) => a.category_order - b.category_order)
        .reduce((acc: Categories, submetric: InsightMetric) => {
          const category =
            submetric.gut_vaginal_health?.key_insights_category
              ?.key_insights_sections;
          const categoryKey = category?.display_title ?? 'Other';
          const categoryPriority = category?.priority ?? 0;
          const metric = submetric.gut_vaginal_health?.key_insights_category;
          const metricKey = metric?.category_title ?? 'Other';
          const metricPriority = metric?.section_order ?? 0;
          const includedByFilters = evaluationFilters.length
            ? evaluationFilters.includes(submetric.evaluation_color ?? '')
            : true;
          if (!acc[categoryKey]) {
            acc[categoryKey] = {
              priority: categoryPriority,
              metrics: {
                [metricKey]: {
                  priority: metricPriority,
                  submetrics: [{ ...submetric, includedByFilters }],
                },
              },
            };
          } else if (!acc[categoryKey]?.metrics[metricKey]) {
            acc[categoryKey].metrics[metricKey] = {
              priority: metricPriority,
              submetrics: [{ ...submetric, includedByFilters }],
            };
          } else {
            acc[categoryKey].metrics[metricKey].submetrics.push({
              ...submetric,
              includedByFilters,
            });
          }
          return acc;
        }, {}),
    [submetrics, evaluationFilters],
  );

  if (!submetrics?.length) return <></>;
  const greenSubMetrics = submetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'green',
  );
  const greySubMetrics = submetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'grey',
  );
  const orangeSubMetrics = submetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'orange',
  );
  const redSubMetrics = submetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'red',
  );

  if (!currentKitId) return <></>;
  return (
    <PageContainer size={ContainerSizes.MD} className={styles.allMetrics}>
      <div className={styles.header}>
        <div className='flex flex-column gap-2'>
          <Typography variant='heading-2xl' medium>
            All Insights
          </Typography>
          <div className={styles.dividerLine} />
          <div className={styles.summaryGrid}>
            <Typography variant='body-s' className={styles.pageSummary}>
              This page shows all metrics from the Beneficial Microbes,
              Disruptive Microbes, Balance and Robustness, and Digestion
              sections of your report.
            </Typography>
            <EvaluationGroupsSummary
              greenCount={greenSubMetrics?.length ?? 0}
              greyCount={greySubMetrics?.length ?? 0}
              orangeCount={orangeSubMetrics?.length ?? 0}
              redCount={redSubMetrics?.length ?? 0}
              variant='both'
            />
          </div>
        </div>
        <CategoryMetricsFilter setFiltered={setEvaluationFilters} />
      </div>

      <div className={styles.cardsContainer}>
        {Object.keys(groupedSubMetrics)
          .sort(
            (a, b) =>
              groupedSubMetrics[b].priority - groupedSubMetrics[a].priority,
          )
          .map((categoryKey: string) => {
            const category = groupedSubMetrics[categoryKey];
            const emptyCategory = !Object.keys(category.metrics).some(
              metricKey =>
                category.metrics[metricKey].submetrics.filter(
                  submetric => submetric.includedByFilters,
                ).length,
            );
            return (
              <Accordion
                key={`${uuid()}`}
                header={
                  <Typography
                    variant='heading-l'
                    className={styles.categoryTitle}
                  >
                    {categoryKey}
                  </Typography>
                }
                initialState={!emptyCategory}
                className={styles.metricAccordion}
              >
                {' '}
                {emptyCategory && (
                  <>
                    <Typography
                      variant='body-s'
                      className={styles.emptyCategory}
                    >
                      No metrics matching your active filters.
                    </Typography>
                  </>
                )}
                {Object.keys(category.metrics)
                  .sort(
                    (a, b) =>
                      category.metrics[a].priority -
                      category.metrics[b].priority,
                  )
                  .map((metricKey: string) => {
                    const submetrics = groupedSubMetrics[categoryKey]?.metrics[
                      metricKey
                    ].submetrics.filter(
                      submetric => submetric.includedByFilters,
                    );

                    if (!submetrics?.length) return <></>;
                    return (
                      <div className={styles.categoryMetric} key={`${uuid()}`}>
                        <Typography
                          variant='heading-s'
                          className={styles.metricTitle}
                        >
                          {metricKey}
                        </Typography>
                        <MetricCard key={`card_${metricKey}`}>
                          {submetrics.map(subMetric => (
                            <SubMetricRow
                              key={`${metricKey}_${subMetric.name}`}
                              kitId={currentKitId}
                              subMetric={subMetric}
                            />
                          ))}
                        </MetricCard>
                      </div>
                    );
                  })}
              </Accordion>
            );
          })}
      </div>
    </PageContainer>
  );
};
