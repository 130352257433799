import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { copyToClipboard, formatToDollarsString } from 'helpers';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { LINKS } from 'assets/constants';
import { getReferralCode, getReferralCredit } from 'store/account';

import { Card, Icon, Typography } from 'components';
import CopyField from 'components/CopyField/CopyField';
import AccountField from '../Account/AccountField/AccountField';
import RedeemCreditModal from '../Account/RedeemCreditModal/RedeemCreditModal';

import styles from '../Account/Account.module.scss';

export const Referrals = () => {
  const { trackEvent } = useAnalytics();
  const referralCode = useSelector(getReferralCode);
  const referralCredit = useSelector(getReferralCredit);
  const [showRedeemCreditModal, setShowRedeemCreditModal] =
    useState<boolean>(false);

  const toggleRedeemCreditModal = () => setShowRedeemCreditModal(prev => !prev);

  const copyReferralLink = useCallback(() => {
    const checkoutLink = `Check out Tiny Health, the most complete gut test for babies and toddlers. Use my discount code ${referralCode} at checkout for $40 off your first order: ${LINKS.store}`;
    copyToClipboard(checkoutLink);
    trackEvent('Clicked Button', {
      text: 'Copy invite',
      href: '',
      variety: 'Button',
    });
    toast.success('Referral link copied to clipboard');
  }, [referralCode, trackEvent]);

  return (
    <>
      <RedeemCreditModal
        toggle={toggleRedeemCreditModal}
        showModal={showRedeemCreditModal}
      />
      <div className={styles.accountContainer}>
        <Typography variant='heading-xl' className='mb-4'>
          Refer a Friend
        </Typography>
        {referralCode && (
          <div className={styles.section}>
            <AccountField
              title='Invite friends & family'
              action={copyReferralLink}
              actionName='Copy invite'
            >
              <Card>
                <div className='flex'>
                  <div className={styles.circledIcon}>
                    <Icon name='giftFilled' size='m' />
                  </div>
                  <div>
                    <h5>Give $40 and get $40!</h5>
                    <p>
                      Invite your friends to give them $40 off their kit and get
                      $40 in store credit you can apply to anything you&apos;d
                      like when they use this code:
                    </p>
                    <CopyField value={referralCode} name='referral code' />
                  </div>
                </div>
              </Card>
            </AccountField>
            {referralCredit > 0 && (
              <AccountField
                title='Store credit'
                action={toggleRedeemCreditModal}
                actionName='Redeem'
              >
                You have {formatToDollarsString(referralCredit)} in store
                credit. Invite others to earn more!
              </AccountField>
            )}
          </div>
        )}
      </div>
    </>
  );
};
