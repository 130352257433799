/*
 *  CONSTANTS
 *  intended to be a single source of truth for constants that impact the UX
 */

export const brandPalette = {
  green: {
    300: 'hsl(151, 55%, 75%)', // light-green
    400: 'hsl(161, 50%, 50%)', // mid-green
    '400a': 'hsla(161, 50%, 50%, 0.8)', // mid-green
    500: 'hsl(172, 100%, 30%)', // green
    700: 'hsl(172, 100%, 24%)', // green-bifido
    '700a': 'hsla(172, 100%, 24%, 0.8)', // green-bifido
  },
  white: {
    100: 'hsl(72, 26%, 96%)', // off-white
  },
  grey: {
    0: 'hsl(255, 7%, 99%)', // grey-white
    100: 'hsl(255, 7%, 96%)', // grey-xlite
    200: 'hsl(255, 7%, 91%)', // grey-xlite
    300: 'hsl(255, 7%, 76%)', // grey-lite
    400: 'hsl(255, 7%, 66%)', // grey-mid
    500: 'hsl(255, 7%, 56%)', // grey
    600: 'hsl(255, 7%, 46%)', // grey-600
    700: 'hsl(255, 7%, 36%)', // grey-xdark
    800: 'hsl(255, 7%, 24%)', // slate
    900: 'hsl(255, 7%, 19%)', // black
  },
  orange: {
    300: 'hsl(32, 91%, 73%)', // light-orange
    '300a': 'hsla(32, 91%, 73%, 0.8)', // light-orange
    500: 'hsl(38, 84%, 56%)', // orange
    '500a': 'hsla(38, 84%, 56%, 0.8)', // orange
  },
  red: {
    300: 'hsl(360, 100%, 80%)', // pink
    '300a': 'hsla(360, 100%, 80%, 0.8)', // pink
    400: 'hsl(360, 80%, 70%)', // pink
    500: 'hsl(360, 63%, 61%)', // red
    '500a': 'hsla(360, 63%, 61%, 0.8)', // red
  },
  yellow: {
    300: 'hsl(53, 80%, 74%)', // yellow
    '300a': 'hsla(53, 80%, 74%, 0.8)', // yellow
    400: 'hsl(53, 80%, 62%)', // yellow
    500: 'hsl(53, 84%, 50%)', // yellow dark
    '500a': 'hsla(53, 84%, 50%, 0.8)', // yellow dark
  },
  indigo: {
    300: 'hsl(231, 100%, 84%)', // née light-purple
    '300a': 'hsla(231, 100%, 84%, 0.8)', // née light-purple
    500: 'hsl(230, 40%, 55%)', // née purple
    '500a': 'hsla(230, 40%, 55%, 0.8)', // née purple
  },
  purple: {
    300: 'hsl(271, 100%, 84%)',
    500: 'hsl(271, 40%, 55%)',
  },
  blue: {
    50: 'hsl(202, 82%, 99%)', // light-blue
    100: 'hsl(202, 82%, 89%)', // light-blue
    200: 'hsl(202, 82%, 79%)', // blue-lite
    300: 'hsl(202, 82%, 69%)', // light-blue
    400: 'hsl(202, 82%, 59%)',
    500: 'hsl(202, 82%, 49%)', // blue
    600: 'hsl(202, 82%, 39%)', // link-blue
    700: 'hsl(202, 82%, 29%)', // blue
    800: 'hsl(202, 82%, 19%)', // blue
    900: 'hsl(202, 82%, 9%)', // blue
  },
  brown: {
    300: 'hsl(28, 44%, 65%)', // light brown
    '300a': 'hsla(28, 44%, 65%)', // light brown
    500: 'hsl(28, 44%, 41%)', // dark brown
    '500a': 'hsla(28, 44%, 41%, 0.8)', // dark brown
  },
  cadet: {
    300: 'hsl(202, 65%, 76%)',
    '300a': 'hsla(202, 65%, 76%, 0.8)',
    500: 'hsl(202, 55%, 52%)',
    '500a': 'hsla(202, 55%, 52%, 0.8)',
  },
};

export const brandColors = {
  ...brandPalette,
  gray: brandPalette.grey,
  clear: 'hsla(231, 50%, 50%, 0)',
  white: brandPalette.grey[0],
  offwhite: brandPalette.white[100],
  slate: brandPalette.grey[800],
  black: brandPalette.grey[900],
  bifidoGreen: brandPalette.green[700],
};

export const palette = {
  fg: brandColors.black,
  bg: brandColors.white,
  paper: brandColors.offwhite,
  ink: brandColors.slate,
  seed: brandPalette.blue[200],
  neutral: brandPalette.grey[300],
  link: brandPalette.indigo[500],
  accent: brandPalette.yellow[400],
  bifido: brandPalette.green[700],
  beneficial: brandPalette.green[500],
  variable: brandPalette.orange[500],
  unfriendly: brandPalette.red[500],
  unknown: brandPalette.grey[300],
  type1: brandPalette.green['700a'],
  type2: brandPalette.orange['500a'],
  type3: brandPalette.red['500a'],
  type4: brandPalette.yellow['500a'],
  type5: brandPalette.brown['500a'],
  type6: brandPalette.cadet['500a'],
  type7: brandPalette.indigo['500a'],
};

export const COLORS = {
  ...brandColors,
  ...palette,
  light: palette,
};

export const LINKS = {
  baseGuides: 'https://www.tinyhealth.com/',
  gutKitSales: 'https://www.tinyhealth.com/store',
  babyGutKitSales: 'https://www.tinyhealth.com/store/baby-gut-health-test',
  childGutKitSales: 'https://www.tinyhealth.com/store/child-gut-health-test',
  adultGutKitSales: 'https://www.tinyhealth.com/store/adult-gut-health-test',
  vaginalKitSales: 'https://www.tinyhealth.com/store/vaginal-health-test',
  store: 'https://www.tinyhealth.com/store/',
  storeKitGut:
    'https://tinyhealth.myshopify.com/cart/48032286507306:1?channel=buy_button',
  storeKitVaginal:
    'https://tinyhealth.myshopify.com/cart/48032654131498:1?channel=buy_button',
  privacyPolicy: 'https://www.tinyhealth.com/privacy-policy',
  termsOfService: 'https://www.tinyhealth.com/terms',
  membership: 'https://www.tinyhealth.com/memberships/tiny-family-membership',
  checkoutWithKitTinyPlus:
    'https://tinyhealth.myshopify.com/cart/48032286507306:1?channel=buy_button',
  checkoutWithKitTinyPlusVaginal:
    'https://tinyhealth.myshopify.com/cart/48032654131498:1?channel=buy_button',
  checkoutWithKitTinyPlusProgramOrProactive:
    'https://tinyhealth.myshopify.com/cart/48032280772906:1?channel=buy_button',
  checkoutWithKitTinyPlusProgramOrProactiveVaginal:
    'https://tinyhealth.myshopify.com/cart/48032627130666:1?channel=buy_button',
  checkoutWithKitNonTinyPlus:
    'https://tinyhealth.myshopify.com/cart/46898032935210:1?discount=TINYRESAMPLE20',
  babyGutTypes: 'https://www.tinyhealth.com/blog/baby-gut-types',
  consultationCalendly: 'https://calendly.com/tinyhealth-consultation',
  consultationFreeCalendly:
    'https://calendly.com/tinyhealth-consultation-credit',
  practitionerCalendly: 'https://calendly.com/tinyhealth-practitioners',
  practitionerStore: 'https://www.poweredbytiny.com/store',
  practitionerBulkDiscounts:
    'https://tinyhealth.notion.site/Bulk-and-Wholesale-Kits-15caa9a25a864987b3f3843ec16c46a4',
  practitionerFAQ:
    'https://support.tinyhealth.com/hc/en-us/categories/20264870973211-Practitioner-Resources',
  subscriptionsLearnMore:
    'https://www.tinyhealth.com/store/baby-gut-health-test',
  cst: 'https://www.tinyhealth.com/blog/cst',
  helpCenter: 'https://support.tinyhealth.com/',
  seeHowItWorks:
    'https://support.tinyhealth.com/hc/en-us/articles/24667642748571-How-do-I-get-access-to-patient-results',
  toxinFAQ:
    'http://www.tinyhealth.com/blog/tiny-health-bacterial-toxins-frequently-asked-questions',
  facebookGroup: 'https://www.facebook.com/groups/tinyhealth',
};

export const CONSULT_ACTIVE_STATUS = ['rescheduled', 'scheduled'];

export function hourToSeconds(hour: number) {
  return hour * 60 * 60;
}

export const PREGNANCY_AGE_BOUNDARY = 18; // used as < 18 or >= 18

export const additionalTimeInSeconds = hourToSeconds(1); // will be set to 1 hour

export const apiName = 'tinyhealthapi';
export const apiBasePath = '/api';

export const PLACEHOLDER = {
  firstName: 'Jane',
  lastName: 'Appleby',
  email: 'jane@example.com',
  new_email: 'jane@example.com',
  phone: '(555) 555-5555',
  new_phone: '(555) 555-5555',
  password: '••••••••',
  passwordVisible: 'a nice, long password',
  verification_code: '123 456',
  impersonateId: 'e.g., aabfs5-678afg-98afdg-896adfg',
};

export const AUTOLOGOUT_REASONS = {
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
};

export const RESERVED_SUBDOMAINS = [
  'account',
  'admin',
  'api',
  'app',
  'auth',
  'dev',
  'docs',
  'email',
  'help',
  'mail',
  'ops',
  'portal',
  'practitioner',
  'staging',
  'support',
  'test',
  'www',
];

export const KIT_PRICE = 169;
export const KIT_PRICE_LEGACY = 149;
export const KIT_PRICE_20_OFF = 229;

export const PRACTITIONER_AUTO_RELEASE_DELAY = 3; // days

export const lowRelativeAbundanceLimit = 0.00005;
export const highRelativeAbundanceLimit = 0.0001;
