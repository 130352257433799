import { useState, useEffect } from 'react';
import { Modal as ReactStrapModal, ModalHeader, ModalBody } from 'reactstrap';
import c from 'classnames';

import { Icon } from '../Icon/Icon';
import Typography from 'components/Typography/Typography';

import styles from './Modal.module.scss';

// TODO: figure out how to untoggle other modals when closing them so they will reopen without an extra click
function closeOtherModals() {
  const modals = document.getElementsByClassName('modal');
  for (const m of modals) {
    m.remove();
  }
}

export type ModalProps = {
  label?: React.ReactNode;
  labelClassName?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  preToggle?: boolean;
  collapse?: boolean;
  showHeader?: boolean;
  controlledIsOpen?: boolean;
  onModalOpen?: () => void;
  onModalClose?: () => void;
};

const Modal = ({
  label,
  labelClassName,
  children,
  title,
  preToggle,
  collapse,
  showHeader = true,
  controlledIsOpen,
  onModalOpen,
  onModalClose,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (preToggle) {
      setIsOpen(true);
      onModalOpen && onModalOpen();
    }
  }, [onModalOpen, preToggle]);

  useEffect(() => {
    if (controlledIsOpen !== undefined) {
      setIsOpen(controlledIsOpen);
    }
  }, [controlledIsOpen]);

  const toggle = () => {
    closeOtherModals();
    if (onModalOpen && !isOpen) {
      onModalOpen();
    }
    if (onModalClose && isOpen) {
      onModalClose();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {!!label && (
        <div role='button' className={labelClassName} onClick={toggle}>
          {label}
        </div>
      )}

      <ReactStrapModal
        centered
        size='xl'
        isOpen={isOpen}
        toggle={toggle}
        modalClassName={styles.modal}
        backdropClassName={styles.backdrop}
        contentClassName={styles.modalContent}
        className={styles.modalDialog}
      >
        {showHeader ? (
          <ModalHeader tag='h3' className={styles.modalHeader}>
            <Typography variant='heading-s'>{title || ''}</Typography>
            <div className={styles.modalClose} role='button' onClick={toggle}>
              <Icon name={collapse ? 'collapse' : 'closeSm'} size='m' />
            </div>
          </ModalHeader>
        ) : (
          <div
            className={c(styles.modalClose, styles.topRightAbsolute)}
            role='button'
            onClick={toggle}
          >
            <Icon name={collapse ? 'collapse' : 'closeSm'} size='m' />
          </div>
        )}
        <ModalBody
          className={c(styles.modalBody, !showHeader && styles.removePadding)}
        >
          {children}
        </ModalBody>
      </ReactStrapModal>
    </div>
  );
};

export default Modal;
