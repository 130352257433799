import { useLocation } from 'react-router-dom';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  Icon,
  LinkButton,
  Typography,
} from 'components';
import { TextColor } from 'components/Typography/Typography';

import styles from './NextStep.module.scss';

type NextStepProps = {
  title: string;
  isActive: boolean;
  isCompleted: boolean;
  linkCompleted?: string;
  children: React.ReactNode;
};
export const NextStep = ({
  isActive,
  isCompleted,
  linkCompleted,
  title,
  children,
}: NextStepProps) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.nextStep}>
      <div className='flex align-items-center justify-content-between gap-2'>
        <div className='flex align-items-center gap-2'>
          {isCompleted ? (
            <div className='green-icon flex align-items-center'>
              <Icon name='check' size='m' />
            </div>
          ) : (
            <div className='grey-icon flex align-items-center'>
              <Icon name='circle' size='m' />
            </div>
          )}
          <Typography
            variant='heading-s'
            className='dark-grey-text'
            color={isActive || isCompleted ? undefined : TextColor.LIGHT_GREY}
          >
            {title}
          </Typography>
        </div>
        {isCompleted && !!linkCompleted && (
          <LinkButton
            label='View'
            href={linkCompleted}
            state={{ from: pathname }}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.S}
            color={ButtonColor.PURPLE}
          />
        )}
      </div>
      {isActive && <div className={styles.content}>{children}</div>}
    </div>
  );
};
