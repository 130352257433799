import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { useSuspenseQueries } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import c from 'classnames';

import { getEvaluationsQuery } from 'services/insights/useSubMetricEvaluations';
import useActionPlanData, {
  getActionPlanItemsQuery,
} from 'services/actionPlan/actionPlanData';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { stringToUrl, userValueFormat } from 'helpers';
import { getIsMetricAccordionOpened, setMetricAccordionState } from 'store/ui';
import { getActionsForSubMetric } from '../SubMetricDetail/SubMetricDetail';
import { ActionPlanItem } from 'types/ActionPlan';
import { InsightMetric } from 'types/Insights';

import {
  Icon,
  PageContainer,
  ContainerSizes,
  Accordion,
  Typography,
} from 'components';
import {
  EvaluationGroupsSummary,
  getEvaluationIconFromColor,
} from '../EvaluationGroupsSummary/EvaluationGroupsSummary';
import { CategoryMetricsFilter } from './CategoryMetricsFilter';

import styles from './CategoryMetrics.module.scss';

const ActionItemCount = ({ qty }: { qty?: number }) => {
  if (!qty) return <></>;
  return (
    <div className={styles.actionsCount}>
      <Icon name='flashFilled' size='s' />
      <Typography variant='label' className={styles.greyText}>
        {`${qty} recommended action${qty > 1 ? 's' : ''}`}
      </Typography>
    </div>
  );
};
const MetricActionsCount = ({ subMetricsIds }: { subMetricsIds: number[] }) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { data: allActions } = useActionPlanData({ kitId: currentKitId });
  if (!subMetricsIds?.length || !allActions?.length) return <></>;
  const actionItems = allActions.filter((item: ActionPlanItem) =>
    item.desired_outcomes?.some(
      outcome =>
        outcome.gut_vaginal_health &&
        subMetricsIds.includes(outcome.gut_vaginal_health.id),
    ),
  );
  return <ActionItemCount qty={actionItems?.length} />;
};
const SubMetricActionsCount = ({ subMetricId }: { subMetricId: number }) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { data: allActions } = useActionPlanData({ kitId: currentKitId });
  if (!subMetricId || !allActions?.length) return <></>;
  const actionItems = getActionsForSubMetric(allActions, subMetricId);
  return <ActionItemCount qty={actionItems?.length} />;
};
const SubMetricStrainsCount = ({ subMetric }: { subMetric: InsightMetric }) => {
  const strainCount = new Set(
    subMetric.gut_vaginal_health_strain_kits?.map(item => item.strain_name),
  ).size;
  const showStrainCount =
    !!subMetric.include_strains &&
    !!subMetric.sample_qc?.probiotics_completed &&
    !!strainCount;
  return showStrainCount ? (
    <div className={styles.actionsCount}>
      <Icon name='flashFilled' size='s' />
      <Typography variant='label' className={styles.greyText}>
        {`${strainCount} strain${strainCount > 1 ? 's' : ''} detected`}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

export const SubMetricRow = ({
  kitId,
  subMetric,
}: {
  kitId: string;
  subMetric: InsightMetric;
}) => {
  const { category } = useParams<{ category: string }>();
  const { pathname } = useLocation();
  return (
    // default to all-insights if category param is not present
    <NavLink
      to={`/results/${kitId}/insights/${category ?? 'all-insights'}/${
        subMetric.sample_health_id
      }`}
      state={{ from: pathname }}
      className={styles.metricRowAnchor}
    >
      <div className={styles.metricRow}>
        <div className={styles.leftSide}>
          <div className={styles.mainRow}>
            <Icon
              name={getEvaluationIconFromColor(subMetric.evaluation_color)}
              size='m'
              className={c(styles.icon, styles[subMetric.evaluation_color])}
            />
            <Typography variant='body-s' className={styles.greyText}>
              {subMetric.name}
            </Typography>
          </div>
          <SubMetricActionsCount subMetricId={subMetric.sample_health_id} />
          <SubMetricStrainsCount subMetric={subMetric} />
        </div>
        <div className={styles.rightSide}>
          <Typography variant='body-s' className={styles.greyText}>
            {userValueFormat(
              subMetric.user_value,
              subMetric.data_type,
              subMetric.user_string,
            )}
          </Typography>
          <Icon name='chevronForward' size='s' />
        </div>
      </div>
    </NavLink>
  );
};

export type Metrics = {
  [key: string]: { submetrics: InsightMetric[]; priority: number };
};
type MetricAccordionHeaderProps = {
  title: string;
  isOpen?: boolean;
  children?: React.ReactNode;
};
const MetricAccordionHeader = ({
  title,
  isOpen = false,
  children,
}: MetricAccordionHeaderProps) => {
  return (
    <motion.div className={styles.accordionHeaderRow}>
      <Typography variant='heading-s'>{title}</Typography>
      {!!children && !isOpen && (
        <div className={styles.extraRow}>{children}</div>
      )}
    </motion.div>
  );
};
export const MetricCard = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.metricCard}>{children}</div>;
};
type MetricAccordionProps = {
  kitId: string;
  categoryTitle: string;
  groupSubMetrics: InsightMetric[];
  hideToggle?: boolean;
};
export const MetricAccordion = ({
  kitId,
  categoryTitle,
  groupSubMetrics,
  hideToggle,
}: MetricAccordionProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsMetricAccordionOpened(kitId, categoryTitle));
  const updateUiState = (updatedIsOpen: boolean) => {
    dispatch(
      setMetricAccordionState({
        name: `${kitId}_${categoryTitle}`,
        props: {
          kitId: kitId,
          metric: categoryTitle,
          isOpen: updatedIsOpen,
        },
      }),
    );
  };
  return (
    <Accordion
      header={
        <MetricAccordionHeader title={categoryTitle}>
          {groupSubMetrics?.length && (
            <>
              {' '}
              <Typography variant='label' className={styles.greyText}>
                {`${groupSubMetrics.length} metric${
                  groupSubMetrics.length > 1 ? 's' : ''
                }`}
              </Typography>
              <MetricActionsCount
                subMetricsIds={groupSubMetrics.map(
                  subMetric => subMetric.sample_health_id,
                )}
              />
            </>
          )}
        </MetricAccordionHeader>
      }
      initialState={hideToggle || isOpen}
      onToggle={updateUiState}
      key={categoryTitle}
      className='mb-4'
      hideToggleIcon={hideToggle}
    >
      <MetricCard key={`card_${categoryTitle}`}>
        {groupSubMetrics?.map(subMetric => (
          <SubMetricRow
            key={`${categoryTitle}_${subMetric.name}`}
            kitId={kitId}
            subMetric={subMetric}
          />
        ))}
        {!groupSubMetrics?.length && (
          <div className='p-4'>
            <Typography variant='body-s' className={styles.greyText}>
              No metrics matching your active filters.
            </Typography>
          </div>
        )}
      </MetricCard>
    </Accordion>
  );
};

export const CategoryMetrics = () => {
  const { category, kitId: currentKitId } = useParams<{
    category: string;
    kitId: string;
  }>();
  const [{ data: metrics }] = useSuspenseQueries({
    queries: [
      getEvaluationsQuery(currentKitId),
      getActionPlanItemsQuery(currentKitId),
    ],
  });
  const [filteredSubMetrics, setFilteredSubMetrics] = useState<Metrics>({});
  const [evaluationsFilter, setEvaluationsFilter] = useState<string[]>([]);
  const currentCategoryMetrics = useMemo(
    () =>
      metrics.filter(
        (metric: InsightMetric) =>
          !!metric?.gut_vaginal_health?.key_insights_category
            ?.key_insights_sections?.display_title &&
          stringToUrl(
            metric?.gut_vaginal_health?.key_insights_category
              ?.key_insights_sections?.display_title,
          ) === category,
      ),
    [metrics, category],
  );

  const groupedSubMetrics: Metrics = useMemo(
    () =>
      currentCategoryMetrics.reduce(
        (acc: Metrics, submetric: InsightMetric) => {
          const categoryKey =
            submetric?.gut_vaginal_health?.key_insights_category
              ?.category_title;
          const section_order =
            submetric?.gut_vaginal_health?.key_insights_category?.section_order;
          if (categoryKey && acc[categoryKey]?.submetrics) {
            acc[categoryKey]?.submetrics.push(submetric);
          } else if (categoryKey) {
            acc[categoryKey] = {
              submetrics: [submetric],
              priority: section_order ?? Infinity,
            };
          }
          if (categoryKey && section_order) {
            acc[categoryKey].priority = section_order;
          }
          return acc;
        },
        {},
      ),
    [currentCategoryMetrics],
  );

  useEffect(() => {
    if (!evaluationsFilter.length) {
      setFilteredSubMetrics(groupedSubMetrics); // Return a shallow copy of the original data
    } else {
      const filteredGroupedSubmetrics: Metrics = {};
      for (const key in groupedSubMetrics) {
        const filteredItems = groupedSubMetrics[key]?.submetrics.filter(item =>
          evaluationsFilter.includes(item.evaluation_color),
        );
        if (!!filteredItems && filteredItems.length > 0) {
          filteredGroupedSubmetrics[key] = {
            submetrics: filteredItems,
            priority: groupedSubMetrics[key].priority,
          };
        }
      }
      setFilteredSubMetrics(filteredGroupedSubmetrics);
    }
  }, [evaluationsFilter, groupedSubMetrics]);

  if (!metrics?.length || !currentCategoryMetrics?.length) return <></>;
  const greenSubMetrics = currentCategoryMetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'green',
  );
  const greySubMetrics = currentCategoryMetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'grey',
  );
  const orangeSubMetrics = currentCategoryMetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'orange',
  );
  const redSubMetrics = currentCategoryMetrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'red',
  );
  if (!currentKitId) return <></>;
  return (
    <PageContainer size={ContainerSizes.MD} className='flex flex-column gap-2'>
      <div className={styles.header}>
        <div className='flex flex-column gap-2'>
          <Typography variant='heading-2xl'>
            {
              currentCategoryMetrics[0]?.gut_vaginal_health
                ?.key_insights_category?.key_insights_sections?.display_title
            }
          </Typography>
          <div className={styles.dividerLine} />
          <div className={styles.summaryGrid}>
            <Typography variant='body-s' serif className={styles.greyText}>
              {
                currentCategoryMetrics[0]?.gut_vaginal_health
                  ?.key_insights_category?.key_insights_sections
                  ?.long_description
              }
            </Typography>
            <EvaluationGroupsSummary
              greenCount={greenSubMetrics?.length ?? 0}
              greyCount={greySubMetrics?.length ?? 0}
              orangeCount={orangeSubMetrics?.length ?? 0}
              redCount={redSubMetrics?.length ?? 0}
              variant='both'
            />
          </div>
          <CategoryMetricsFilter setFiltered={setEvaluationsFilter} />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        {Object.keys(groupedSubMetrics)
          .sort((a, b) => {
            return (
              groupedSubMetrics[a].priority - groupedSubMetrics[b].priority
            );
          })
          .map(categoryTitle => (
            <MetricAccordion
              kitId={currentKitId}
              categoryTitle={categoryTitle}
              groupSubMetrics={filteredSubMetrics[
                categoryTitle
              ]?.submetrics?.sort(
                (a, b) => (a?.category_order ?? 0) - (b?.category_order ?? 0),
              )}
              key={currentKitId + '_' + categoryTitle}
            />
          ))}
      </div>
    </PageContainer>
  );
};
