import { TextLink, ImageFallback, Typography } from 'components';
import guidePlaceholder from 'assets/images/placeholders/book.svg';

import styles from './GuideCard.module.scss';

export const GuideCard = ({ guide }) => (
  <div className={styles.guideCard}>
    <div className={styles.imgWrapper}>
      <ImageFallback src={guide.image_url} fallback={guidePlaceholder} />
    </div>
    <div className={styles.section}>
      <div>
        <Typography variant='heading-m' className={styles.guideTitle}>
          {guide.display_title}
        </Typography>
        <Typography variant='label' serif className={styles.description}>
          {guide.description}
        </Typography>
      </div>
      <TextLink href={guide.url} external label='Read more' />
    </div>
  </div>
);
