import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useFeature from 'services/features/useFeature';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import useSurveyResponses from 'services/surveys/surveyResponses';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { SurveyResponsesType, QuestionAnswerPairType } from 'types/Surveys';
import { getSurveysStatusForKitId } from 'store/kits';

import {
  PageContainer,
  QuestionAnswerList,
  SectionPlaceholder,
  Header,
  Spinfinity,
} from 'components';
import { SurveyCard } from '../SurveyCard/SurveyCard';
import { SurveyChangesCard } from '../SurveysDetail/SurveysDetail';
import { TypeformSurveyCard } from '../TypeformSurveyCard/TypeformSurveyCard';
import { ProfileAnswersCard } from '../ProfileAnswersCard/ProfileAnswersCard';
import { SampleInfoAnswersCard } from '../SampleInfoAnswersCard/SampleInfoAnswersCard';

import styles from './SurveysSummary.module.scss';

export const getSubmittedDate = (
  surveyResponses: SurveyResponsesType[],
  formId: string,
) => {
  const surveyResponseOfFormId = surveyResponses.find(
    response => response.form_id === formId,
  );
  return surveyResponseOfFormId?.submitted_at
    ? surveyResponseOfFormId.submitted_at.slice(0, 10) //use only date
    : '';
};

export const getAllQuestionsAnswers = (
  surveyResponses: SurveyResponsesType[],
  onlyFormIds?: string[],
) => {
  let all: QuestionAnswerPairType[] = [];
  surveyResponses.forEach(response => {
    if (!onlyFormIds?.length || onlyFormIds.includes(response.form_id)) {
      all = [...all, ...response.questions];
    }
  });
  return all;
};

export const SurveysSummary = () => {
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const { isNewProfileFlow } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  return showProfiles && isNewProfileFlow ? (
    <SurveysSummaryWithProfile />
  ) : (
    <LegacySurveysSummary />
  );
};

const SurveysSummaryWithProfile = () => {
  const { currentKit, currentKitId, currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  //surveys check
  const { data: surveys } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  const {
    data: surveyResponses,
    importantQuestions,
    kitBelongsToAccount,
    isLoading,
  } = useSurveyResponses(currentKitId, true);

  const onlyImportantQuestionsAnswers = useMemo(
    () =>
      getAllQuestionsAnswers(
        importantQuestions,
        surveys?.map(s => s.typeform_survey_id),
      ),
    [importantQuestions, surveys],
  );

  if (!currentKit || !currentTinyAccount) return null;
  if (!kitBelongsToAccount) {
    return (
      <>
        <Header title={'What you told us'} />
        <PageContainer>
          <div className={styles.sectionsWrapper}>
            <main className={styles.mainContent}>
              <SectionPlaceholder
                title={`No access to kit ${currentKitId} under this account`}
              />
            </main>
          </div>
        </PageContainer>
      </>
    );
  }
  if (isLoading) return <Spinfinity />;
  return (
    <>
      <Header title='What you told us' />
      <PageContainer>
        <div className={styles.sectionsWrapper}>
          <main className={styles.mainContent}>
            {!!onlyImportantQuestionsAnswers?.length && (
              <>
                <div className={styles.sectionTitle}>Notable</div>
                <QuestionAnswerList list={onlyImportantQuestionsAnswers} />
              </>
            )}
          </main>

          <section className={styles.section}>
            {!surveyResponses?.length ? (
              <p>There are no survey responses for this kit {currentKitId}</p>
            ) : (
              <div className={styles.cardsContainer}>
                <ProfileAnswersCard />
                <SampleInfoAnswersCard />
                {surveys
                  ?.filter(survey => !survey.is_complete)
                  .map(surveyType => (
                    <TypeformSurveyCard
                      key={surveyType.typeform_survey_id}
                      typeform_id={surveyType.typeform_survey_id}
                      display_title={surveyType?.name ?? 'Survey'}
                      estimated_time={surveyType.time_estimate}
                      linkTypeform={`/typeform/${currentTinyAccountId}/${currentKitId}/${surveyType.typeform_survey_id}`}
                      needed
                    />
                  ))}
                {surveys
                  ?.filter(survey => survey.is_complete)
                  .map(surveyType => (
                    <TypeformSurveyCard
                      key={surveyType.typeform_survey_id}
                      typeform_id={surveyType.typeform_survey_id}
                      display_title={surveyType?.name ?? 'Survey'}
                      linkDetail={`/results/${currentKitId}/surveys/${surveyType.typeform_survey_id}`}
                      dateSubmitted={getSubmittedDate(
                        surveyResponses,
                        surveyType.typeform_survey_id,
                      )}
                    />
                  ))}
                <SurveyChangesCard />
              </div>
            )}
          </section>
        </div>
      </PageContainer>
    </>
  );
};

const LegacySurveysSummary = () => {
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  //surveys check
  const status = useSelector(getSurveysStatusForKitId(currentKitId)) ?? {
    completed: [],
    pending: [],
    needed: [],
  };

  const {
    data: surveyResponses,
    importantQuestions,
    kitBelongsToAccount,
    isLoading,
  } = useSurveyResponses(currentKitId);
  const onlyImportantQuestionsAnswers = useMemo(
    () =>
      getAllQuestionsAnswers(
        importantQuestions,
        status?.completed?.map(s => s.typeform_id),
      ),
    [importantQuestions, status?.completed],
  );

  if (!currentKit || !currentTinyAccount) return null;
  if (!kitBelongsToAccount) {
    return (
      <>
        <Header title={'What you told us'} />
        <PageContainer>
          <div className={styles.sectionsWrapper}>
            <main className={styles.mainContent}>
              <SectionPlaceholder
                title={`No access to kit ${currentKitId} under this account`}
              />
            </main>
          </div>
        </PageContainer>
      </>
    );
  }
  if (isLoading) return <Spinfinity />;
  return (
    <>
      <Header title='What you told us' />
      <PageContainer>
        <div className={styles.sectionsWrapper}>
          {!!onlyImportantQuestionsAnswers?.length && (
            <main className={styles.mainContent}>
              <div className={styles.sectionTitle}>Notable</div>
              <QuestionAnswerList list={onlyImportantQuestionsAnswers} />
            </main>
          )}

          <section className={styles.section}>
            <div className={styles.sectionTitle}>Your surveys</div>
            {!surveyResponses?.length ? (
              <p>There are no survey responses for this kit {currentKitId}</p>
            ) : (
              <div className={styles.cardsContainer}>
                {status?.pending?.map(surveyType => (
                  <SurveyCard
                    key={surveyType.typeform_id}
                    {...surveyType}
                    kit={currentKit}
                    tinyAccount={currentTinyAccount}
                    needed
                  />
                ))}
                {status?.completed?.map(surveyType => (
                  <SurveyCard
                    key={surveyType.typeform_id}
                    {...surveyType}
                    kit={currentKit}
                    tinyAccount={currentTinyAccount}
                    linkDetail={`/results/${currentKitId}/surveys/${surveyType.typeform_id}`}
                    dateSubmitted={getSubmittedDate(
                      surveyResponses,
                      surveyType.typeform_id,
                    )}
                  />
                ))}
                <SurveyChangesCard />
              </div>
            )}
          </section>
        </div>
      </PageContainer>
    </>
  );
};
