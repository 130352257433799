import c from 'classnames';

import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import useCheckoutKit from 'hooks/useCheckoutKit';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  Card,
  LinkButton,
  Typography,
} from 'components';
import { FadedCard } from '../FadedCard/FadedCard';

import styles from './MembershipSection.module.scss';

export const MembershipSection = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { hasMembership, latestKit } = useTinyAccountForHome(tinyAccountId);
  const { price, checkoutUrl } = useCheckoutKit(latestKit?.sampling_type);
  const checkoutUrlWithUtm = `${checkoutUrl}&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=benefits`;

  if (!hasMembership) return <></>;
  return (
    <FadedCard>
      <div className='flex flex-column gap-3'>
        <Typography variant='heading-l'>Your Tiny+ Benefits</Typography>

        <div className={styles.grid}>
          <Card
            variant='wrapper'
            clickable={{
              text: 'coaching sessions',
              href: `/results/${latestKit?.id}/consults/book`,
              isExternal: false,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography variant='label' className='grey-text'>
                Your Tiny+ Benefits
              </Typography>
              <Typography variant='body-s'>
                $39 / additional coaching call
              </Typography>
              <div className={c(styles.pill, styles.blue)}>
                <Typography variant='label'>coaching sessions</Typography>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: 'additional kit',
              href: checkoutUrlWithUtm,
              isExternal: true,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography variant='label' className='grey-text'>
                Your Tiny+ Benefits
              </Typography>
              <Typography variant='body-s'>
                {`$${price} / additional kit`}
              </Typography>
              <div className={c(styles.pill, styles.purple)}>
                <Typography variant='label'>test kits</Typography>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: 'Email support',
              href: 'mailto:hello@tinyhealth.com',
              isExternal: true,
              variety: 'Card',
            }}
            className={c(styles.cardContent, styles.big)}
          >
            <Typography variant='heading-xl' serif>
              Unlimited
            </Typography>
            <div className={c(styles.pill, styles.green)}>
              <Typography variant='label'>SMS and Email support</Typography>
            </div>
          </Card>
        </div>

        <LinkButton
          label='Buy additional kits'
          href={checkoutUrlWithUtm}
          external
          variant={ButtonVariant.SOLID}
          size={ButtonSize.M}
          color={ButtonColor.DARK_PURPLE}
          fullWidth
        />
      </div>
    </FadedCard>
  );
};
