import { useSelector } from 'react-redux';
import { getConsultCreditsCount } from 'store/account';

import { Typography } from 'components';

export const ConsultPricing = () => {
  const consultCreditsCount = useSelector(getConsultCreditsCount);
  return consultCreditsCount > 0 ? (
    <div className='text-center'>
      <Typography variant='label'>{`You have ${consultCreditsCount} free call${
        consultCreditsCount === 1 ? '' : 's'
      } remaining.`}</Typography>
    </div>
  ) : (
    <div className='text-center'>
      <Typography variant='label'>Calls are </Typography>
      <Typography variant='label' className='line-through'>
        $100
      </Typography>
      <Typography variant='label'>&nbsp;$39 per 20 minute call. </Typography>
    </div>
  );
};
