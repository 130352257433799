import { useMemo, useState, useEffect, Children } from 'react';
import { motion } from 'framer-motion';

import { Icon } from 'components';

import styles from './ExpandableList.module.scss';

export const ExpandableList = ({
  max,
  children,
}: {
  max: number;
  children: React.ReactNode;
}) => {
  const arrayChildren = useMemo(() => Children.toArray(children), [children]);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    //display "max" at most on load
    if (arrayChildren?.length > max) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [arrayChildren, max]);

  return (
    <>
      <motion.div layout transition={{ duration: 0.15 }}>
        {Children.map(arrayChildren, (child, index) => {
          return index + 1 > max && !isExpanded ? <></> : child;
        })}
      </motion.div>
      {!isExpanded && (
        <button
          className={styles.expandButton}
          onClick={() => setIsExpanded(true)}
        >
          <Icon name='fileSearch02Filled' size='s' />
          <span>See full list</span>
        </button>
      )}
    </>
  );
};
