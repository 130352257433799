import { useSelector } from 'react-redux';

import { getSortedKitsForTinyAccountId } from 'store/kits';
import TinyAccount from 'types/TinyAccount';

import { Typography } from 'components';
import { KitCard } from '../KitCard/KitCard';

import styles from './TinyAccountKits.module.scss';

export const TinyAccountKits = ({
  tinyAccount,
}: {
  tinyAccount: TinyAccount;
}) => {
  const kits = useSelector(getSortedKitsForTinyAccountId(tinyAccount.id));
  if (!kits.length) return <></>;
  return (
    <div key={tinyAccount.id}>
      {!!kits.length && (
        <Typography variant='heading-l' className='dark-grey-text'>{`${
          tinyAccount.first_name
        }'s kit${kits.length ? 's' : ''}`}</Typography>
      )}
      <div className={styles.kitsContainer}>
        {!!kits.length && kits.map(kit => <KitCard key={kit.id} kit={kit} />)}
      </div>
    </div>
  );
};
