import { useMemo } from 'react';
import { add, format } from 'date-fns';
import c from 'classnames';

import useConsults from 'services/general/consultsList';
import { CONSULT_ACTIVE_STATUS } from 'assets';
import { sortRecentToOldestConsult } from 'views/Consults/Consults';
import { Card, Icon, Typography } from 'components';

import styles from './ConsultReminder.module.scss';

export const ConsultReminder = ({ className = '' }: { className: string }) => {
  const { data: consultsList } = useConsults();

  const nextConsult = useMemo(() => {
    const upcomingConsults =
      consultsList
        ?.filter(consult => CONSULT_ACTIVE_STATUS.includes(consult.status))
        .sort(sortRecentToOldestConsult) ?? [];
    return upcomingConsults.pop();
  }, [consultsList]);

  const agentName = nextConsult?.consult_agent?.first_name
    ? `${nextConsult.consult_agent.first_name} ${nextConsult.consult_agent.last_name}`
    : 'our team';

  if (!consultsList || !nextConsult) return <></>;
  return (
    <Card
      variant='wrapper'
      clickable={{
        text: 'Upcoming: Coaching session',
        href: '/consults',
        variety: 'Card',
        isExternal: false,
      }}
      className={c(styles.card, 'blue-border-left', className)}
    >
      <div className='flex flex-column gap-2'>
        <div className='flex align-items-center gap-1 blue-icon'>
          <Icon name='calendar' size='m' />
          <Typography variant='heading-m'>
            {`Upcoming: Coaching session`}
          </Typography>
        </div>
        <div className='flex align-item-center justify-between gap-1'>
          <Typography variant='label'>{`With ${agentName}`}</Typography>
          <Typography variant='label'>
            {format(nextConsult.scheduled_dt * 1000, 'EEE MMM d')}
          </Typography>
          <div className='flex align-item-center'>
            <Typography variant='label'>
              {format(nextConsult.scheduled_dt * 1000, 'hh:mm')}
            </Typography>
            <Typography variant='label'>
              {`- ${format(
                add(nextConsult.scheduled_dt * 1000, { minutes: 30 }),
                'hh:mm a',
              )}`}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};
