import * as Sentry from '@sentry/react';
import c from 'classnames';
import { SpinnerInfinity } from 'spinners-react';

import { getColors } from 'contexts';

import styles from './Spinfinity.module.scss';

export const Spinfinity = ({
  fixed = false,
  size = 60,
  speed = 100,
  thickness = 100,
  color = getColors().link,
  secondaryColor = 'var(--warm-grey-300)',
  fullScreen = false,
}) => {
  return (
    <div
      className={c(
        styles.spinfinity,
        fixed && styles.fixed,
        fullScreen && styles.fullScreen,
      )}
      data-testid='spinfinity'
    >
      <SpinnerInfinity
        size={size}
        speed={speed}
        thickness={thickness}
        color={color}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};
