import { format } from 'date-fns';

import { kitStatusParser, parseDate } from 'helpers';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import Kit from 'types/Kit';

import { Icon, TextLink, Typography } from 'components';
import { TextColor } from 'components/Typography/Typography';
import { FadedCard } from '../FadedCard/FadedCard';

import styles from './KitsSection.module.scss';

const KitSectionCard = ({ kit }: { kit: Kit }) => {
  const isVoid = kitStatusParser(kit.status) === 'Void';
  const kitType = kit.sampling_type === 'stool' ? 'Gut' : 'Vag';
  const formattedDate = format(
    parseDate(kit.sample_date) ?? new Date(),
    'MM/dd/yy',
  );
  return (
    <div className={styles.card}>
      <div className='flex align-items-center gap-2 pb-1'>
        <Typography variant='heading-m'>{formattedDate}</Typography>
        <Typography variant='heading-m'>{`${kitType} health test`}</Typography>
      </div>
      <div className='flex align-items-center justify-content-between gap-1 flex-wrap'>
        <div className={styles.iconRow}>
          <Icon name='priceTagFilled' size='s' />
          <Typography variant='label'>{`Kit ID: ${kit.id}`}</Typography>
        </div>
        <div className={styles.iconRow}>
          <Icon name='atSign' size='s' />
          <Typography variant='label'>{kit.sample_label}</Typography>
        </div>
        {isVoid && (
          <div className='flex align-items-center gap-1 red'>
            <Typography variant='label'>Void</Typography>
            <Icon name='add' size='s' className={styles.rotate} />
          </div>
        )}
      </div>
      {!isVoid && (
        <div className='flex align-items-center justify-content-between gap-1'>
          <TextLink
            label='See health responses'
            iconNameRight='chevronForward'
            href={`/results/${kit.id}/surveys`}
            state={{ from: `/` }}
            size='body-s'
          />
          <TextLink
            label='See Results'
            iconNameRight='chevronForward'
            href={`/results/${kit.id}`}
            size='body-s'
          />
        </div>
      )}
    </div>
  );
};

export const KitsSection = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { getDisplayName, viewableKits } = useTinyAccountForHome(tinyAccountId);

  if (!viewableKits?.length) return <></>;
  return (
    <FadedCard>
      <div className={styles.container}>
        <div className='flex align-items-center justify-content-between gap-2'>
          <Typography variant='heading-l'>{`${getDisplayName()} Tests`}</Typography>
          <TextLink
            label='Activate kit'
            iconNameLeft='add'
            iconSize='m'
            href={`/kits/activate`}
            state={{ from: `/` }}
            size={'label'}
            color={TextColor.GREY}
            className='gap-1'
          />
        </div>
        {viewableKits?.map(kit => (
          <KitSectionCard key={kit.id} kit={kit} />
        ))}
      </div>
    </FadedCard>
  );
};
