import { useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import c from 'classnames';

import useAllBookmarks from 'services/actionPlan/useAllBookmarks';
import TinyAccount from 'types/TinyAccount';
import Kit from 'types/Kit';
import { BookmarkedItem, RecommendationItemType } from 'types/ActionPlan';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getLatestKits } from 'store/kits';
import { getKits } from 'store/shared';

import { PageContainer, ContainerSizes, Button, Typography } from 'components';
import { IconName } from 'components/Icon/getSvgByName';
import { BookmarkItemDetail } from '../BookmarkItemDetail/BookmarkItemDetail';
import { TinyAccountsFilter } from './TinyAccountsFilter';
import { BookmarksGroup } from './BookmarksGroup';

import NoBookmarks from 'assets/images/no-bookmarks.svg?react';
import styles from './MyList.module.scss';

type KeyType = 'Products' | 'Groceries' | 'Recipes' | 'Tips' | 'Resources';

const getBookmarksGrouped = (
  bookmarks: BookmarkedItem[],
): { name: string; icon: IconName; content: BookmarkedItem[] }[] => {
  const reduced = bookmarks.reduce(
    (initialObj, bookmark) => {
      let key: KeyType = 'Tips';
      switch (bookmark.recommendation_item?.type) {
        case RecommendationItemType.PROBIOTIC:
        case RecommendationItemType.SUPPLEMENT:
        case RecommendationItemType.PRODUCT:
          key = 'Products';
          break;
        case RecommendationItemType.FOOD:
          key = 'Groceries';
          break;
        case RecommendationItemType.RECIPE:
          key = 'Recipes';
          break;
        case RecommendationItemType.TIP:
          key = 'Tips';
          break;
        case RecommendationItemType.RESOURCE:
          key = 'Resources';
          break;
      }
      initialObj[key].push(bookmark);
      return initialObj;
    },
    {
      Products: [],
      Groceries: [],
      Recipes: [],
      Tips: [],
      Resources: [],
    } as {
      [K in KeyType]: BookmarkedItem[];
    },
  );
  return [
    { name: 'Products', icon: 'shoppingCart', content: reduced.Products },
    { name: 'Groceries', icon: 'groceries', content: reduced.Groceries },
    { name: 'Recipes', icon: 'recipe', content: reduced.Recipes },
    { name: 'Tips', icon: 'zapCircleFilled', content: reduced.Tips },
    { name: 'Resources', icon: 'link', content: reduced.Resources },
  ];
};

export const MyList = () => {
  const [selectedAccount, setSelectedAccount] = useState<TinyAccount | null>(
    null,
  );
  const { data: bookmarks } = useAllBookmarks();
  const activeBookmarks = useMemo(
    () =>
      bookmarks.filter((bookmark: BookmarkedItem) => bookmark.is_bookmarked),
    [bookmarks],
  );

  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const latestKits = useSelector(getLatestKits());
  const latestKitsIds = useMemo(
    () => latestKits?.map(kit => kit.id),
    [latestKits],
  );

  const latestBookmarks = useMemo(() => {
    if (!activeBookmarks?.length) return [];
    return activeBookmarks.filter(
      (bookmark: BookmarkedItem) =>
        !!latestKitsIds?.length &&
        bookmark.kit_id &&
        latestKitsIds?.includes(bookmark.kit_id),
    );
  }, [activeBookmarks, latestKitsIds]);

  const bookmarksToDisplay = useMemo(() => {
    if (!selectedAccount) return latestBookmarks; //no filter so all bookmarks for all kits
    const selectedAccountKits =
      latestKits
        ?.filter(kit => kit.tinyaccount_id === selectedAccount.id)
        ?.map(kit => kit.id) || [];
    return latestBookmarks.filter(
      (bookmark: BookmarkedItem) =>
        bookmark.kit_id && selectedAccountKits.includes(bookmark.kit_id),
    );
  }, [latestBookmarks, selectedAccount, latestKits]);

  const groupsOfBookmarks = useMemo(
    () => getBookmarksGrouped(bookmarksToDisplay),
    [bookmarksToDisplay],
  );

  // This is for all the other previous bookmarks
  const previousBookmarks = useMemo(() => {
    if (!activeBookmarks?.length) return [];
    return activeBookmarks.filter(
      (bookmark: BookmarkedItem) =>
        !!latestKitsIds?.length &&
        bookmark.kit_id &&
        !latestKitsIds?.includes(bookmark.kit_id),
    );
  }, [activeBookmarks, latestKitsIds]);

  const allKits = useSelector(getKits);
  const previousBookmarksToDisplay = useMemo(() => {
    if (!selectedAccount) return previousBookmarks; //no filter so all bookmarks for all kits
    const selectedAccountOldKits =
      allKits
        ?.filter(
          kit =>
            kit.tinyaccount_id === selectedAccount.id &&
            !latestKitsIds.includes(kit.id),
        )
        ?.map(kit => kit.id) || [];
    return previousBookmarks.filter(
      (bookmark: BookmarkedItem) =>
        bookmark.kit_id && selectedAccountOldKits.includes(bookmark.kit_id),
    );
  }, [previousBookmarks, selectedAccount, allKits, latestKitsIds]);

  const groupsOfPreviousBookmarks = useMemo(
    () => getBookmarksGrouped(previousBookmarksToDisplay),
    [previousBookmarksToDisplay],
  );

  if (!activeBookmarks?.length)
    return (
      <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
        <Typography variant='heading-xl' className={styles.mainHeader}>
          Bookmarks
        </Typography>
        <NothingSavedYet />
      </PageContainer>
    );
  return (
    <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
      <Typography variant='heading-xl' className={styles.mainHeader}>
        Bookmarks
      </Typography>
      {orderedTinyAccounts?.length > 1 && (
        <TinyAccountsFilter
          tinyAccounts={orderedTinyAccounts}
          onSelection={setSelectedAccount}
        />
      )}
      {!groupsOfBookmarks?.filter(group => group.content.length)?.length ? (
        <NothingSavedYet
          tinyAccount={selectedAccount}
          latestKits={latestKits}
        />
      ) : (
        <div className={styles.groupsContainer}>
          {groupsOfBookmarks.map(group => (
            <BookmarksGroup group={group} key={group.name} />
          ))}
        </div>
      )}
      {!!groupsOfPreviousBookmarks?.filter(group => group.content.length)
        ?.length && (
        <div className={c(styles.groupsContainer, 'mt-4')}>
          <h2 className={styles.mainHeader}>Previous Bookmarks</h2>
          {groupsOfPreviousBookmarks.map(group => (
            <BookmarksGroup group={group} key={group.name} />
          ))}
        </div>
      )}
    </PageContainer>
  );
};

export const NothingSavedYet = ({
  tinyAccount,
  latestKits,
}: {
  tinyAccount?: TinyAccount | null;
  latestKits?: Kit[];
}) => {
  const latestKitId = useMemo(() => {
    if (!tinyAccount || !latestKits?.length) return null;
    const latestKit = latestKits?.find(
      kit => kit.tinyaccount_id === tinyAccount?.id,
    );
    return latestKit ? latestKit.id : null;
  }, [tinyAccount, latestKits]);

  return (
    <div className={styles.placeholder}>
      <div className={styles.image}>
        <NoBookmarks />
      </div>
      <h5 className={styles.detailHeading}>Nothing saved, yet!</h5>
      <div className={styles.secondary}>
        Bookmark products, tips and resources from your Action Plan for quick
        access when taking action
      </div>
      <Button
        href={`/action-plan${latestKitId ? '/' + latestKitId : ''}`}
        size='medium-large'
        color='purple'
        buttonStyle='outline'
        className='mt-3'
        width='max-width'
      >
        Go to Action Plan
      </Button>
    </div>
  );
};

export const BookmarksRoutes = () => (
  <Routes>
    <Route path='item/:itemId' element={<BookmarkItemDetail />} />
    <Route path='' element={<MyList />} />
  </Routes>
);
