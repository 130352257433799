import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { formatToDollarsString } from 'helpers';
import useRedeemCredit from 'services/discounts/useRedeemCredit';
import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';

import { Button, Spinfinity, Typography } from 'components';
import { LINKS } from 'assets';
import CopyField from 'components/CopyField/CopyField';
import SlideUpModal from 'components/Modal/SlideUpModal';

import styles from './RedeemCreditModal.module.scss';

type RedeemCreditModalProps = {
  toggle: () => void;
  showModal: boolean;
};
const RedeemCreditModal = ({ showModal, toggle }: RedeemCreditModalProps) => {
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isTinyPlus = useSelector(getIsTinyPlus);
  const {
    data: redemption,
    mutate,
    isSuccess,
    isError,
    isPending: isLoading,
  } = useRedeemCredit();
  useEffect(() => {
    if (showModal && !redemption && !isSuccess && !isError && !isLoading)
      mutate();
  }, [redemption, mutate, showModal, isSuccess, isError, isLoading]);

  return (
    <SlideUpModal toggle={toggle} showModal={showModal} title='Store credit'>
      {showModal && isLoading && <Spinfinity />}
      {showModal && redemption && (
        <div className={styles.modalContent}>
          <Typography variant='body-s'>Congratulations!</Typography>
          <Typography variant='body-s'>
            {' '}
            You have redeemed {formatToDollarsString(
              redemption.amount / 100,
            )}{' '}
            in store credit 🎉
          </Typography>
          <Typography variant='body-s'>
            Use this discount code on checkout to redeem it:
          </Typography>
          <CopyField value={redemption.discount_code} name='discount code' />
          <Button
            className={'mt-auto'}
            color='green'
            href={`${
              isAnyTinyPlus
                ? isTinyPlus
                  ? LINKS.checkoutWithKitTinyPlus
                  : LINKS.checkoutWithKitTinyPlusProgramOrProactive
                : LINKS.checkoutWithKitNonTinyPlus
            }?discount=${redemption.discount_code}`}
            external
          >
            Order your kit
          </Button>
        </div>
      )}
    </SlideUpModal>
  );
};

export default RedeemCreditModal;
