import { useSelector } from 'react-redux';
import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { getMainAccountId } from 'store/account';
import { Consult } from 'types/Consult';

export async function getConsults() {
  const response = await API.get(apiName, `${apiBasePath}/consults`, {});
  return response;
}

const useConsults = () => {
  const mainAccountId = useSelector(getMainAccountId);

  const consultsListQuery = useSuspenseQuery<Consult[]>({
    queryKey: [apiName, `consultsList`, mainAccountId],
    queryFn: () => getConsults(),
    staleTime: 0,
  });

  return consultsListQuery;
};

export default useConsults;
