import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import useAccountsAndKits from 'services/account/useAccountsAndKits';
import { getImpersonateId } from 'store/user';

import { Sidebar, Spinfinity, ErrorDisplay } from 'components';
import MobileNav from '../MobileNav/MobileNav';
import ImpersonateBanner from 'views/PractitionerPortal/ImpersonateBanner/ImpersonateBanner';

import styles from './ResponsiveLayout.module.scss';

export const PageAnalytics = ({ children }: { children: React.ReactNode }) => {
  useAnalytics().pageEvent();
  return <>{children}</>;
};
const ResponsiveLayout = () => {
  useAccountsAndKits();
  const location = useLocation();
  const impersonateId = useSelector(getImpersonateId);

  useEffect(() => {
    // on desktop the centerpane scrolls, on mobile the whole page scrolls
    const centerPane = document.getElementById('center-pane');
    const root = document.getElementsByTagName('html')[0];
    root?.scrollTo?.(0, 0);
    centerPane?.scrollTo?.(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.appWrapper}>
      {impersonateId && <ImpersonateBanner />}
      <div className={styles.gridLayout}>
        <div className={styles.leftPane}>
          <Sidebar />
        </div>

        <div className={styles.centerPane} id='center-pane'>
          <Suspense fallback={<Spinfinity />}>
            <ErrorBoundary fallback={<ErrorDisplay />}>
              <PageAnalytics>
                <Outlet />
              </PageAnalytics>
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
      <MobileNav />
    </div>
  );
};
export default ResponsiveLayout;
