import { Suspense } from 'react';
import c from 'classnames';
import {
  Brand,
  ButtonColor,
  ButtonSize,
  LinkButton,
  Typography,
  WhiteLogo,
} from 'components';
import styles from './SplashScreen.module.scss';
import SignInForm from 'views/Auth/Login/SignInForm';
import { Link, NavLink } from 'react-router-dom';
const PractitionerSplashScreen = () => {
  return (
    <>
      <div className={c(styles.splashBg, styles.practitionerBg)} />
      <Suspense fallback={<WhiteLogo />}>
        <div className={styles.logoContainer}>
          <Brand color='light' />
        </div>
      </Suspense>
      <div className={styles.splashText}>
        <Typography variant='heading-xl'>Practitioner Portal</Typography>
        <Typography variant='body-s'>
          Interactive portal for practitioners using Tiny Health
        </Typography>
      </div>
      <div className={c('w-100 flex flex-column')}>
        <SignInForm darkBackground />
        <LinkButton
          href='/register'
          label='Get started'
          size={ButtonSize.M}
          color={ButtonColor.DARK}
          fullWidth
        />
        <NavLink
          to={'/reset-password'}
          className={c(styles.passwordTrouble, 'mt-3', 'p-0')}
        >
          Forgot password?
        </NavLink>
        <div className='mt-0 flex gap-1 text-center'>
          <Typography variant='body-s' className='ml-auto white'>
            Not a practitioner?
          </Typography>
          <Link
            to='https://account.tinyhealth.com/login'
            className='purple-text mr-auto'
          >
            Login here
          </Link>
        </div>
      </div>
    </>
  );
};
export default PractitionerSplashScreen;
