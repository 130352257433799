import { useSelector } from 'react-redux';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getConsultCreditsCount } from 'store/account';

import { Icon, Typography } from 'components';

import { DiveDeeperSummaryCard } from 'views/Results/Summary/SummaryCards/DiveDeeperSummaryCard/DiveDeeperSummaryCard';

import styles from './ConsultSummaryCard.module.scss';

export const ConsultSummaryCard = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const consultCreditsCount = useSelector(getConsultCreditsCount);

  return (
    <DiveDeeperSummaryCard
      title='Consultation'
      link={`/results/${currentKitId}/consults/book`}
      linkText='Book your call'
    >
      <div className={styles.content}>
        <Icon name='messageHearthSquare' size='m' />
        <Typography
          variant='heading-m'
          className={c(styles.forceLineHeight, 'ml-1 my-auto')}
        >
          {consultCreditsCount
            ? 'Your test includes a coaching session'
            : 'Navigate your results with an expert.'}
        </Typography>
      </div>
    </DiveDeeperSummaryCard>
  );
};
