import { useState } from 'react';
import { toast } from 'react-toastify';
import c from 'classnames';

import useUpdateBookmark from 'services/actionPlan/useUpdateBookmark';
import { BookmarkedItem } from 'types/ActionPlan';

import { Icon } from 'components';

import styles from './Bookmark.module.scss';

export const Bookmark = ({
  kit_ids,
  recommendation_item_id,
  is_bookmarked,
  action_plan_recommendation_id,
}: BookmarkedItem) => {
  const [showBookmarked, setShowBookmarked] = useState(is_bookmarked);
  const updateBookmarkMutation = useUpdateBookmark();

  const toggleBookmark = async () => {
    try {
      //toggle visually optimistically since the network request has a small delay
      setShowBookmarked(wasBookmarked => !wasBookmarked);
      await updateBookmarkMutation.mutateAsync({
        kit_ids,
        recommendation_item_id,
        action_plan_recommendation_id,
        is_bookmarked: !is_bookmarked,
      });
    } catch (error) {
      //revert the optimistic update
      setShowBookmarked(wasBookmarked => !wasBookmarked);
      toast.error('Your request could not be completed. Please try again');
    }
  };

  return (
    <div
      className={c(
        styles.bookmark,
        !showBookmarked ? styles.empty : styles.checked,
      )}
      onClick={toggleBookmark}
    >
      <Icon name={!showBookmarked ? 'bookmark' : 'bookmarkFilled'} />
    </div>
  );
};
