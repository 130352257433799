import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import { kitStatusColorParser, kitStatusParser, parseDate } from 'helpers';
import Kit from 'types/Kit';

import { Badge, Card, Icon, Typography } from 'components';

import styles from './KitCard.module.scss';

export const KitCard = ({ kit }: { kit: Kit }) => {
  const navigate = useNavigate();
  if (!kit.status) return null;
  const isVoid = kitStatusParser(kit.status) === 'Void';
  const kitType = kit.sampling_type === 'stool' ? 'Gut' : 'Vag';
  const formattedDate = format(
    parseDate(kit.sample_date) ?? new Date(),
    'MM/dd/yy',
  );

  const handleCardClick = () => {
    if (!isVoid) {
      navigate(`/results/${kit.id}`);
    }
  };

  return (
    <div
      className={c(styles.maxWidthSm, !isVoid ? 'clickable' : '')}
      onClick={handleCardClick}
    >
      <Card variant='wrapper' className='p-3'>
        <div className='flex flex-column gap-3'>
          <div
            className={c(
              'flex align-items-center justify-content-between w-100',
            )}
          >
            <Typography variant='heading-s'>{`${kitType} test @${kit.sample_label}`}</Typography>
          </div>
          <div className={styles.secondaryContent}>
            <div className='flex align-items-center justify-content-between gap-1 w-100'>
              <div className='flex align-items-center gap-3'>
                <div className='flex align-items-center gap-1'>
                  <Icon name='calendarFilled' size='s' />
                  <span className='font-weight-light'>{formattedDate}</span>
                </div>
                <div className='flex align-items-center gap-1'>
                  <Icon name='priceTagFilled' size='s' />
                  <span className='font-weight-light'>{kit.id}</span>
                </div>
              </div>
              <div className='flex'>
                <Badge
                  title={kitStatusParser(kit.status)}
                  state={kitStatusColorParser(kit.status)}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
