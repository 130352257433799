import { useMemo } from 'react';

import { kitResultsViewable } from 'helpers';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import Kit from 'types/Kit';

import { groupByOutcomeAndFilter } from 'views/ActionPlan/ActionPlanAccordionList/ActionPlanAccordionListV2';
import ActionPlanGroup from 'views/ActionPlan/ActionPlanGroupV2/ActionPlanGroupV2';

import styles from './HomeActions.module.scss';

export const HomeActions = ({ kit }: { kit: Kit | null }) => {
  const { data: actionItems } = useActionPlanData({ kitId: kit?.id });
  const actionItemsGroupedByOutcome = useMemo(
    () => groupByOutcomeAndFilter(actionItems ?? []) ?? {},
    [actionItems],
  );
  const surveyActions = actionItemsGroupedByOutcome['informationBased'];

  //Only show this card after surveys and until results are ready
  if (!kit?.date_survey_completed || kitResultsViewable(kit)) return <></>;
  if (
    !actionItems?.length ||
    !surveyActions ||
    !Object.entries(surveyActions)?.length
  )
    return <></>;
  return (
    <div className={styles.forceChildPadding}>
      <ActionPlanGroup
        title={'Resources'}
        icon={'clipboard'}
        color={'green'}
        description={
          'These recommendations may be of interest to you based on what you told us in your surveys.'
        }
        actionsGroupedByOutcome={surveyActions}
        defaultOpen
        hideToggleIcon
      />
    </div>
  );
};
