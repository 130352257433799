import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LINKS, KIT_PRICE, KIT_PRICE_LEGACY, KIT_PRICE_20_OFF } from 'assets';
import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';
import { KitType } from 'types/Kit';

export const useCheckoutKit = (kitType?: KitType) => {
  const isTinyPlus = useSelector(getIsTinyPlus);
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);

  const checkoutUrl = useMemo(() => {
    if (kitType === KitType.VAGINAL) {
      return isAnyTinyPlus
        ? isTinyPlus
          ? LINKS.checkoutWithKitTinyPlusVaginal
          : LINKS.checkoutWithKitTinyPlusProgramOrProactiveVaginal
        : LINKS.checkoutWithKitNonTinyPlus;
    } else {
      return isAnyTinyPlus
        ? isTinyPlus
          ? LINKS.checkoutWithKitTinyPlus
          : LINKS.checkoutWithKitTinyPlusProgramOrProactive
        : LINKS.checkoutWithKitNonTinyPlus;
    }
  }, [isAnyTinyPlus, isTinyPlus, LINKS]);

  const price = useMemo(() => {
    return isAnyTinyPlus
      ? isTinyPlus
        ? KIT_PRICE_LEGACY
        : KIT_PRICE
      : KIT_PRICE_20_OFF;
  }, [
    isAnyTinyPlus,
    isTinyPlus,
    KIT_PRICE,
    KIT_PRICE_LEGACY,
    KIT_PRICE_20_OFF,
  ]);

  return {
    checkoutUrl,
    price,
  };
};
export default useCheckoutKit;
