import { useMemo } from 'react';

import { Accordion, Markdown, Typography } from 'components';

import styles from './FAQ.module.scss';

export const FAQ = ({ faq, title }: { faq: string; title?: string }) => {
  //faq: markdown string
  const faqData = useMemo(() => {
    if (!faq) return null;
    //this regular expression will match the headings that are meant to be the questions (content formatting guidelines)
    const regexp = /####.+(?=\n)/g;
    const matches = faq?.matchAll(regexp); //the questions still with markdown
    const questions = [];
    const answers = [];
    let previousEnd = null;
    //with the help of the "matches" we can parse the whole text into the questions and answers
    for (const match of matches) {
      questions.push(match[0].replace('####', '').trim());
      if (previousEnd) {
        answers.push(faq.substring(previousEnd + 1, match.index));
      }
      previousEnd = match.index
        ? match.index + match[0].length
        : match[0].length;
    }
    if (previousEnd) {
      answers.push(faq.substring(previousEnd + 1));
    }
    return { questions, answers };
  }, [faq]);

  if (!faqData || !faqData?.questions?.length || !faqData?.answers?.length)
    return <></>;
  return (
    <div>
      {!!title && (
        <Typography variant='heading-l' className={styles.title}>
          {title}
        </Typography>
      )}
      {faqData.answers.length === faqData.questions.length ? (
        faqData.questions.map((question, idx) => (
          <Accordion
            header={question}
            initialState={idx === 0}
            key={'question_' + idx}
            className={styles.accordionContainer}
          >
            <Markdown>{faqData.answers[idx] as string}</Markdown>
          </Accordion>
        ))
      ) : (
        <Markdown>{faq}</Markdown>
      )}
    </div>
  );
};
