import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useProfileQuestions from 'services/general/profileQuestions';
import useUpdateProfileAnswer from 'services/surveys/useUpdateProfileAnswer';
import useFeature from 'services/features/useFeature';
import { ProfileQuestionAnswer } from 'types/ProfileQuestions';
import { THSurvey } from '../Profile';

export const ProfileEditGroup = () => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const { questionGroupId } = useParams<{ questionGroupId: string }>();
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const { data: profileQuestions } = useProfileQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const profileAnswerMutation = useUpdateProfileAnswer();

  const questionGroup = profileQuestions?.find(
    questionGroup => questionGroup.id === Number(questionGroupId),
  );

  if (!showProfiles || !currentTinyAccountId || !questionGroup) return <></>;

  const mutationCallback = useCallback(
    (answers: ProfileQuestionAnswer[]) => {
      return profileAnswerMutation.mutateAsync({
        answers,
        tinyAccountId: currentTinyAccountId,
        kitId: currentKitId,
      });
    },
    [profileAnswerMutation],
  );

  return (
    <THSurvey
      questionGroups={[questionGroup]}
      mutationCallback={mutationCallback}
      isSingleEdit={true}
    />
  );
};
