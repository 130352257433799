import { useSelector } from 'react-redux';
import { API } from 'api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import {
  getImportantSurveyQuestions,
  ImportantQuestionsBySurvey,
} from './getImportantSurveyQuestions';
import {
  getImportantSurveyQuestionsWithProfile,
  ImportantQuestionsBySurveyId,
} from './getImportantSurveyQuestionsWithProfile';
import { apiName, apiBasePath } from 'assets';
import { getKitBelongsToAccount } from 'store/kits';
import { SurveyResponsesType } from 'types/Surveys';

const getKitSurveyResponses = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/kits/${kitId}/typeform/responses`,
    {},
  );
  return response.result;
};

export const getSurveyResponsesQuery = (
  kitId: string | null | undefined,
  kitBelongsToAccount: boolean,
) => ({
  queryKey: [apiName, 'surveyResponses', kitId],
  queryFn: () =>
    (kitId && kitBelongsToAccount && getKitSurveyResponses(kitId)) || null,
});

const useSurveyResponses = (kitId?: string | null, withProfiles = false) => {
  const kitBelongsToAccount = useSelector(getKitBelongsToAccount(kitId));
  const { data, error, isLoading } = useQuery<SurveyResponsesType[] | null>(
    getSurveyResponsesQuery(kitId ?? null, kitBelongsToAccount),
  );

  const importantQuestions: SurveyResponsesType[] = [];
  //find the questions that are important
  data?.forEach((survey: SurveyResponsesType) => {
    if (withProfiles) {
      const importantQuestionsMapProfileFlow =
        getImportantSurveyQuestionsWithProfile();
      if (!survey.hide_responses) {
        const tempQuestions = survey.questions?.filter(question =>
          importantQuestionsMapProfileFlow[
            survey.form_id as keyof ImportantQuestionsBySurveyId
          ]?.includes(question.question_id),
        );
        importantQuestions.push({ ...survey, questions: tempQuestions });
      }
    } else {
      const importantQuestionsMap = getImportantSurveyQuestions();
      if (!survey.hide_responses) {
        const tempQuestions = survey.questions?.filter(question =>
          importantQuestionsMap[
            survey.title as keyof ImportantQuestionsBySurvey
          ]?.question_ids?.includes(question.question_id),
        );
        importantQuestions.push({ ...survey, questions: tempQuestions });
      }
    }
  });
  return { data, error, isLoading, kitBelongsToAccount, importantQuestions };
};

export default useSurveyResponses;
