import styles from './CircularProgress.module.scss';

export const CircularProgress = ({
  percentage = 0,
  strokeWidth = 8,
  label = '',
  labelFontSize = '1.25rem',
}) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;

  return (
    <svg
      viewBox='0 0 100 100'
      width={100}
      height={100}
      data-testid='circularProgress'
    >
      <path
        className={styles.trail}
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        data-testid='circularProgress-trail'
      />

      <path
        className={styles.path}
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          strokeLinecap: 'round',
          strokeDasharray: `${diameter}px ${diameter}px`,
          strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
        }}
        data-testid='circularProgress-path'
      />

      <text
        className={styles.text}
        x={50}
        y={50}
        style={{
          fontSize: labelFontSize,
          dominantBaseline: 'central',
          textAnchor: 'middle',
        }}
        data-testid='circularProgress-text'
      >
        {label}
      </text>
    </svg>
  );
};
