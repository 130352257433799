import { useLocation } from 'react-router-dom';
import c from 'classnames';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import { ProfileSurveys } from 'types/Surveys';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  Icon,
  LinkButton,
  Typography,
} from 'components';
import { TypeformSurveyTime } from 'views/Results/Surveys/TypeformSurveyCard/TypeformSurveyCard';

import styles from './TypeformSurveysStep.module.scss';

type TypeformSurveyRowProps = {
  survey: ProfileSurveys;
  needsSurveys: boolean;
};
const TypeformSurveyRow = ({
  survey,
  needsSurveys,
}: TypeformSurveyRowProps) => {
  const { pathname } = useLocation();
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  return (
    <div
      className={c(
        styles.surveyRow,
        survey.is_complete && styles.completed,
        !needsSurveys && styles.allCompleted,
      )}
    >
      <div className='flex align-items-center justify-content-between gap-2'>
        <div className='flex align-items-center gap-2'>
          {survey.is_complete ? (
            <div className='green-icon flex align-items-center'>
              <Icon name='check' size='m' />
            </div>
          ) : (
            <div className='grey-icon flex align-items-center'>
              <Icon name='circle' size='m' />
            </div>
          )}
          <Typography
            variant='body-s'
            className={survey.is_complete ? styles.darkGrey : styles.grey}
          >
            {survey.name}
          </Typography>
        </div>
        {!survey.is_complete && (
          <TypeformSurveyTime time={survey.time_estimate} />
        )}
      </div>
      {survey.is_complete ? (
        <div>
          <LinkButton
            label='See response'
            iconNameRight='chevronForward'
            href={`/results/${currentKitId}/surveys/${survey.typeform_survey_id}`}
            state={{ from: pathname }}
            size={ButtonSize.S}
            color={ButtonColor.PURPLE}
            variant={ButtonVariant.TEXT}
          />
        </div>
      ) : (
        <LinkButton
          label='Complete survey'
          iconNameRight='chevronForward'
          href={`/typeform/${currentTinyAccountId}/${currentKitId}/${survey.typeform_survey_id}`}
          state={{ from: pathname }}
          size={ButtonSize.S}
          color={ButtonColor.PURPLE}
        />
      )}
    </div>
  );
};

export const TypeformSurveysStep = () => {
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const { data: surveys, needsSurveys } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );

  return (
    <div>
      <div className='mb-3'>
        <Typography variant='body-s' className='dark-grey' responsive serif>
          We need a bit more info to interpret your sample and won’t be able to
          deliver your report without it.
        </Typography>
      </div>

      {surveys?.map(survey => (
        <TypeformSurveyRow
          key={survey.id}
          survey={survey}
          needsSurveys={needsSurveys}
        />
      ))}
    </div>
  );
};
