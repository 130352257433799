import axios, { Method, AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { ENVIRONMENT, USE_SANDBOX } from 'assets/env_constants';
import { impersonate, UserAttributes } from 'store/user';
import { AUTOLOGOUT_REASONS } from 'assets/constants';
import { signOutUser } from 'services/auth/useSignOut';
import store from 'store/store';
import { toast } from 'react-toastify';

let baseURL = 'https://api.tinyhealth.com';
if (ENVIRONMENT === 'preview' || USE_SANDBOX === 'true') {
  baseURL = 'https://sandbox-api.tinyhealth.com';
} else if (ENVIRONMENT === 'development') {
  baseURL = 'http://localhost:3001/sandbox';
}
// TODO: Generic typing for request data
const request = async (
  method: Method,
  url: string,
  requireAuth: boolean,
  data?: Record<string, unknown>,
) => {
  const impersonateHeader = store.getState().user.impersonateId
    ? store.getState().user.impersonateId
    : (data?.impersonateId as string) ?? null;
  try {
    const response = await axios({
      baseURL,
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: requireAuth
          ? `Bearer ${localStorage.getItem('jwtToken')}`
          : null,
        'x-impersonate-id': impersonateHeader,
      },
      params: method.toUpperCase() === 'GET' && data,
      data: data?.body || data,
    });

    return response.data;
  } catch (err) {
    const axios_error = err as AxiosError;
    if (requireAuth && axios_error.response?.status === 401) {
      if (window.queryClient) {
        const username = store.getState().user.username;
        await window.queryClient.removeQueries({
          predicate: query =>
            username ? query.queryKey.includes(username) : false,
        });
      }
      if (store.getState().user.isAuthenticated) {
        await store.dispatch(
          signOutUser({ autoLogoutReason: AUTOLOGOUT_REASONS.EXPIRED }),
        );
      }
    } else if (axios_error.response?.status === 403) {
      toast.error('You do not have permission to perform this action');
    } else {
      // unhandled if not 401 or 403
      throw err;
    }
  }
};

export const Auth = {
  signIn: async (email: string, password: string) => {
    try {
      return await request('POST', '/auth/login', false, {
        email,
        password,
      });
    } catch (err) {
      const axios_error = err as AxiosError;
      if (axios_error.response?.status === 401) {
        const error = new Error('Email or password is incorrect');
        error.name = 'NotAuthorizedException';
        throw error;
      }
      if (axios_error.response?.status === 400) {
        const error = new Error('Email not verified');
        error.name = 'UserNotConfirmedException';
        throw error;
      }

      throw err;
    }
  },
  signOut: async () => {
    localStorage.removeItem('jwtToken');
    return null;
  },
  signUp: async (
    data: { email: string; password: string } & Partial<UserAttributes>,
  ) => {
    try {
      return await request('POST', '/auth/signup?type=link', false, data);
    } catch (err) {
      const axios_error = err as AxiosError;

      if (axios_error.response?.status === 409) {
        const error = new Error('This email already has an account');
        error.name = 'UsernameExistsException';
        throw error;
      }

      throw err;
    }
  },
  confirmSignUp: async (email: string, code: string) => {
    try {
      return await request('POST', '/auth/confirm_signup', false, {
        email,
        code,
      });
    } catch (err) {
      const axios_error = err as AxiosError;
      if (axios_error.response?.status === 400) {
        const error = new Error('Invalid verification code');
        error.name = 'CodeMismatchException';
        throw error;
      }

      throw err;
    }
  },
  forgotPassword: async (email: string) => {
    try {
      return await request('POST', '/auth/forgot_password', false, {
        email,
      });
    } catch (err) {
      const axios_error = err as AxiosError;

      if (axios_error.response?.status === 400) {
        const error = new Error('There is no account for this email');
        error.name = 'UserNotFoundException';
        throw error;
      }

      throw err;
    }
  },
  forgotPasswordSubmit: async (
    email: string,
    code: string,
    password: string,
  ) => {
    try {
      return await request('POST', '/auth/forgot_password_submit', false, {
        email,
        code,
        password,
      });
    } catch (err) {
      const axios_error = err as AxiosError;

      if (axios_error.response?.status === 400) {
        const error = new Error('Invalid verification code');
        error.name = 'CodeMismatchException';
        throw error;
      }

      throw err;
    }
  },
  completeNewPassword: async (password: string) => {
    return await request('POST', '/auth/complete_new_password', true, {
      password,
    });
  },
  resendSignUp: async (email: string) => {
    return await request('POST', '/auth/resend_signup?type=link', false, {
      email,
    });
  },
  currentAuthenticatedUser: async (options?: object) => {
    const jwt_token = localStorage.getItem('jwtToken');
    if (jwt_token) {
      const decoded = jwt_decode(jwt_token) as UserAttributes;
      return decoded;
    }

    return null;
  },
};

export const API = {
  get: async (apiName: string, url: string, data?: Record<string, unknown>) => {
    return await request('GET', url, apiName === 'tinyhealthapi', data);
  },
  post: async (
    apiName: string,
    url: string,
    data?: Record<string, unknown>,
  ) => {
    return await request('POST', url, apiName === 'tinyhealthapi', data);
  },
  put: async (apiName: string, url: string, data?: Record<string, unknown>) => {
    return await request('PUT', url, apiName === 'tinyhealthapi', data);
  },
  patch: async (
    apiName: string,
    url: string,
    data?: Record<string, unknown>,
  ) => {
    return await request('PATCH', url, apiName === 'tinyhealthapi', data);
  },
  del: async (apiName: string, url: string, data?: Record<string, unknown>) => {
    return await request('DELETE', url, apiName === 'tinyhealthapi', data);
  },
};
