import c from 'classnames';
import { Modal, Typography } from 'components';
import MicrobiomeScoreChart from 'components/MicrobiomeScoreChart/MicrobiomeScoreChart';
import { getOrdinalSuffix } from 'helpers/getOrdinalSuffix';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import styles from './MicrobiomeScoreSummaryCard.module.scss';

const MicrobiomeScoreModal = ({
  isOpen,
  onClose,
  populationScore,
  percentile,
  color = 'grey',
}: {
  isOpen: boolean;
  onClose: () => void;
  populationScore: number;
  percentile: number;
  color?: string;
}) => {
  const { currentKit } = useCurrentKitAndTinyAccount();
  if (!currentKit) return null;
  return (
    <Modal
      controlledIsOpen={isOpen}
      onModalClose={onClose}
      title='How You Compare'
    >
      <div className={styles.modalLayout}>
        <div className={styles.popChartSection}>
          <div className={styles.bigNumbers}>
            <div className={styles.percentile}>
              <span
                className={c(
                  styles.percentileBig,
                  styles[color ?? 'grey'],
                  styles.modal,
                )}
              >
                {Math.floor(percentile)}
              </span>
              <span className={styles.percentileSmall}>
                {getOrdinalSuffix(Math.floor(percentile))}
              </span>

              <Typography variant='body-s' className={styles.percentileText}>
                percentile
              </Typography>
            </div>
            <div className={styles.percentile}>
              <span
                className={c(
                  styles.percentileBig,
                  styles[color ?? 'grey'],
                  styles.modal,
                )}
              >
                {Math.floor(populationScore)}
              </span>
              <Typography variant='body-s' className={styles.percentileText}>
                microbiome summary score
              </Typography>
            </div>
          </div>
          <div className={styles.popChartContainer}>
            <MicrobiomeScoreChart
              kit={currentKit}
              popScore={populationScore}
              disableAnimations
              isModal
            />
          </div>
        </div>
        <div className={styles.popChartText}>
          <Typography variant='body-m'>
            How do we calculate the Microbiome Summary Score?
          </Typography>
          <Typography variant='body-s'>
            Your Microbiome Summary Score evaluates all the relevant metrics for
            your age range into a single number, giving you a clear picture of
            how your microbiome stacks up.
          </Typography>
          <Typography variant='body-s'>
            The score ranges from 0 to 100. A score closer to 100 suggests your
            microbiome is in excellent shape compared to the reference
            population. Conversely, a score closer to 0 indicates more areas for
            improvement.
          </Typography>
        </div>
        <div className={c(styles.popChartText, styles.fullSpan)}>
          <Typography variant='body-s'>
            We calculate this score by looking at various factors in your
            microbiome, such as the balance of beneficial bacteria versus
            opportunistic pathogens. We then compare these factors to a wide
            range of individuals. Our dataset includes publicly available
            reference samples and proprietary data from Tiny Health customers.
            Since 2022, Tiny Health has served 25,000 families and continues to
            grow rapidly every month.
          </Typography>
          <Typography variant='body-m'>
            How do we calculate your Percentile?
          </Typography>
          <Typography variant='body-s'>
            To determine your percentile, we compare your Microbiome Summary
            Score to others in the same age range to help you understand what is
            typical and where your sample falls.
          </Typography>
          <Typography variant='body-s'>
            For example, if you are in the 41st percentile, 41% of comparable
            samples have a lower Microbiome Summary Score and 59% have a higher
            score.
          </Typography>
        </div>
      </div>
    </Modal>
  );
};
export default MicrobiomeScoreModal;
