import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, addWeeks, isBefore, startOfDay } from 'date-fns';
import { motion } from 'framer-motion';
import c from 'classnames';

import { parseDate } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import usePublishedGuides from 'services/general/guidesList';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import { getSurveysStatusForKitId } from 'store/kits';
import { ActionPlanItem } from 'types/ActionPlan';
import { IconName } from 'components/Icon/getSvgByName';
import Kit from 'types/Kit';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  Card,
  ContainerSizes,
  Icon,
  LinkButton,
  PageContainer,
  Spinfinity,
  Typography,
} from 'components';
import { PendingSurveys } from './PendingSurveys';
import { GuideCard } from 'views/Guides/GuideCard/GuideCard';
import { ActionPlanLinkItem } from 'views/ActionPlan/ActionPlanGroup/ActionPlanLinkItem';
import checklistIllustration from 'assets/images/placeholders/long_checklist.svg';

import styles from './KitProcess.module.scss';
import { groupByOutcomeAndFilter } from 'views/ActionPlan/ActionPlanAccordionList/ActionPlanAccordionListV2';
import useFeature from 'services/features/useFeature';
import ActionPlanGroup from 'views/ActionPlan/ActionPlanGroupV2/ActionPlanGroupV2';
import { getKitProcessSteps } from 'views/Results/KitProcess/getKitProcessSteps';

type StepContent = {
  description: string;
  icon: IconName;
  iconColor: 'red' | 'green' | 'grey';
};
export type Step = {
  title: string;
  getStepContent: (kit?: Kit) => StepContent;
};
type ProcessStepProps = {
  kit?: Kit;
  step: Step;
  stepIndex: number;
  currentStep: number;
  showAllSteps: boolean;
};
const ProcessStep = ({
  kit,
  step,
  stepIndex,
  currentStep,
  showAllSteps = false,
}: ProcessStepProps) => {
  const show = showAllSteps || stepIndex === currentStep;
  const { description, icon, iconColor } = step.getStepContent(kit);

  return !show ? (
    <></>
  ) : (
    <div className={c(styles.processStep, styles[iconColor])}>
      <div className={styles.rowTitle}>
        <Icon name={icon} size='s' />
        <Typography variant='heading-s'>{step.title}</Typography>
      </div>
      <Typography variant='body-s' responsive className={styles.greyText}>
        {description}
      </Typography>
    </div>
  );
};

type TopGuidesProps = {
  title: string;
  qty: number;
  withLink?: boolean;
};
const TopGuides = ({ title, qty, withLink = true }: TopGuidesProps) => {
  const { data: guides, isError } = usePublishedGuides();
  return (
    <div className='flex flex-column gap-3'>
      <Typography variant='heading-m'>{title}</Typography>
      <div className='flex flex-column gap-3'>
        {guides ? (
          guides
            .slice(0, qty)
            .map(guide => <GuideCard key={guide.id} guide={guide} />)
        ) : isError ? (
          <p>{`We can't display the guides at the moment`}</p>
        ) : (
          <Spinfinity />
        )}
        {withLink && (
          <LinkButton
            label='View all guides'
            href='/guides'
            variant={ButtonVariant.SOLID}
            size={ButtonSize.M}
            color={ButtonColor.LIGHT}
            fullWidth
          />
        )}
      </div>
    </div>
  );
};

type RelatedActionsProps = {
  title: string;
  description: string;
  actions?: Partial<ActionPlanItem>[] | null;
};
export const RelatedActions = ({
  title,
  description,
  actions,
}: RelatedActionsProps) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  if (!currentKitId || !actions?.length) return null;
  return (
    <div className={styles.actionPlanGroup}>
      <div className={styles.groupTitle}>
        <div className='flex flex-column gap-1 p-3'>
          <Typography variant={'heading-m'} className={styles.purpleText}>
            {title}
          </Typography>
          <Typography variant={'body-s'} responsive>
            {description}
          </Typography>
        </div>
        <img src={checklistIllustration} alt='list with check marks' />
      </div>
      <div className={styles.groupContainer}>
        {actions.map(actionPlanDetails => (
          <Fragment key={actionPlanDetails.display_title}>
            <ActionPlanLinkItem {...actionPlanDetails} kitId={currentKitId} />
          </Fragment>
        ))}
      </div>
      <div className={styles.navigation}>
        <LinkButton
          href={`/action-plan/${currentKitId}`}
          size={ButtonSize.M}
          color={ButtonColor.PURPLE}
          label='Go to action plan'
          fullWidth
        />
      </div>
    </div>
  );
};

export const KitProcess = () => {
  const [showAllSteps, setShowAllSteps] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isMissingBirthMother, setIsMissingBirthMother] = useState(false);
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  const { data: actionItems } = useActionPlanData({ kitId: currentKitId });
  const actionItemsGroupedByOutcome = useMemo(
    () => groupByOutcomeAndFilter(actionItems ?? []) ?? {},
    [actionItems],
  );
  const { isFeatureEnabled: showAPGroupedByOutcome } = useFeature(
    'ap_group_by_outcome',
  );
  const surveyActions = actionItemsGroupedByOutcome['informationBased'];

  const status = useSelector(getSurveysStatusForKitId(currentKitId));

  const STEPS: Step[] = useMemo(() => getKitProcessSteps(status), [status]);
  useEffect(() => {
    if (!currentKit || !currentTinyAccount) return;
    //birth mother check
    let missingBirthMother = false;
    if (!currentTinyAccount?.birth_mother_id && currentKit.age_at_sampling) {
      const threeYears = 1095;
      missingBirthMother = currentKit.age_at_sampling < threeYears;
      setIsMissingBirthMother(missingBirthMother);
    }
    //define in which step we are
    if (missingBirthMother || !!status?.pending?.length) {
      setCurrentStep(1);
    } else if (!currentKit?.date_sequences_delivered) {
      setCurrentStep(2);
    } else {
      setCurrentStep(3);
    }
  }, [currentKit, currentTinyAccount, status]);

  const estimatedDate = useMemo(() => {
    if (currentKit?.date_delivered_to_lab) {
      const estimated = addWeeks(
        parseDate(currentKit.date_delivered_to_lab),
        4,
      );
      if (!isBefore(estimated, startOfDay(new Date()))) {
        return format(estimated, 'MMMM d');
      }
    }
    return null;
  }, [currentKit]);

  return (
    <PageContainer
      size={ContainerSizes.LG}
      className={styles.sectionsContainer}
    >
      <main>
        <div className='flex flex-column gap-4 mb-4'>
          <Card variant='wrapper'>
            <div className='flex flex-column gap-1 p-3'>
              <motion.div
                key='header'
                className={styles.header}
                role='button'
                onClick={() => setShowAllSteps(!showAllSteps)}
              >
                <div className='flex flex-column gap-1'>
                  {!estimatedDate ? (
                    <Typography variant='heading-s'>
                      {`Your kit's process`}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant='heading-s'>
                        Estimated results
                      </Typography>
                      <Typography variant='heading-l'>
                        {estimatedDate}
                      </Typography>
                    </>
                  )}
                </div>
                <Icon
                  name='chevronDown'
                  className={c(
                    styles.rotate,
                    showAllSteps
                      ? [styles.iconRotateUp]
                      : [styles.iconRotateDown],
                  )}
                />
              </motion.div>
              <div className={styles.stepsContainer}>
                {STEPS.map((step, idx) => (
                  <div
                    key={`step_${idx}`}
                    className={c(
                      styles.step,
                      idx < currentStep && styles.full,
                      idx === currentStep && styles.half,
                    )}
                  ></div>
                ))}
              </div>
            </div>
            <motion.div layout transition={{ duration: 0.05 }}>
              {STEPS.map((step, stepIndex) => (
                <ProcessStep
                  key={`row_${stepIndex}`}
                  kit={currentKit}
                  step={step}
                  stepIndex={stepIndex}
                  currentStep={currentStep}
                  showAllSteps={showAllSteps}
                />
              ))}
            </motion.div>
          </Card>
          {currentStep === 1 && (
            <PendingSurveys isMissingBirthMother={isMissingBirthMother} />
          )}
          {showAPGroupedByOutcome ? (
            surveyActions && Object.entries(surveyActions)?.length ? (
              <ActionPlanGroup
                title={'Resources'}
                icon={'clipboard'}
                color={'green'}
                description={
                  'While we process your results, you can start improving your gut health right away'
                }
                actionsGroupedByOutcome={surveyActions}
                defaultOpen
                hideToggleIcon
              />
            ) : null
          ) : actionItems?.length ? (
            <RelatedActions
              title='Personalized action plan based on your survey responses'
              description='While we process your results, you can start improving your gut health right away'
              actions={actionItems}
            />
          ) : null}
        </div>
      </main>
      <section className={styles.right}>
        <TopGuides
          qty={3}
          withLink
          title={
            !actionItems?.length
              ? `In the meantime, dive into our guides to get started:`
              : `Explore our guides`
          }
        />
      </section>
    </PageContainer>
  );
};
