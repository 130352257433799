import c from 'classnames';
import { getSvgByName, IconName } from './getSvgByName';
import styles from './Icon.module.scss';

type IconSizes = 'xs' | 's' | 'm' | 'l' | 'xl';

export const Icon = ({
  name,
  responsive,
  className = '',
  size = 'm',
  active = false,
}: {
  name: IconName;
  responsive?: boolean;
  className?: string;
  size?: IconSizes;
  active?: boolean;
}) => {
  const Svg = getSvgByName(name);

  return (
    <span className={c(className, styles.icon)}>
      <Svg
        className={c(
          active && styles.active,
          styles.uikit,
          styles[size],
          responsive && styles.responsive,
        )}
        title={name}
        role='img'
      />
    </span>
  );
};
