import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';

import { Icon } from 'components';

import styles from './THButton.module.scss';

/*
type Props {
    size: 'small' | 'medium' | 'large'
    color: 'white' | 'blue' | 'green'
    loading: boolean
}
*/
export const Button = ({
  size = '',
  color = 'green',
  buttonStyle = 'solid',
  loading = false,
  disabled = false,
  className = '',
  href = '',
  external = false,
  width = 'full',
  iconNameLeft = '',
  iconNameRight = '',
  children,
  onClick = () => {},
  ...props
}) => {
  const { trackEvent } = useAnalytics();
  const buttonClasses = c(
    styles.button,
    styles.buttonFlex,
    styles[size],
    styles[width],
    styles[color],
    styles[buttonStyle],
    { [styles.loading]: loading },
    className,
  );

  const handleClick = () => {
    //click handler from props
    onClick();
    //tracking of the clicked event
    if (typeof children === 'string') {
      trackEvent('Clicked Button', {
        text: children,
        href: href ?? '',
        isExternal: external,
        variety: href ? 'LinkButton' : 'Button',
      });
    }
  };

  if (href) {
    if (external) {
      return (
        <a
          href={href}
          target='_blank'
          className={buttonClasses}
          rel='noreferrer'
          //flag to know that it is already tracked and avoid double tracking in the general link tracking from AnalyticsProvider
          data-tracking-source='THButton'
          onClick={handleClick}
        >
          <ButtonContent
            loading={loading}
            iconNameLeft={iconNameLeft}
            iconNameRight={iconNameRight}
          >
            {children}
          </ButtonContent>
        </a>
      );
    } else {
      return (
        <NavLink
          to={href}
          className={buttonClasses}
          rel='noreferrer'
          onClick={handleClick}
        >
          <ButtonContent
            loading={loading}
            iconNameLeft={iconNameLeft}
            iconNameRight={iconNameRight}
          >
            {children}
          </ButtonContent>
        </NavLink>
      );
    }
  }
  return (
    <button
      {...props}
      className={buttonClasses}
      disabled={loading || disabled}
      onClick={handleClick}
    >
      <ButtonContent
        loading={loading}
        iconNameLeft={iconNameLeft}
        iconNameRight={iconNameRight}
      >
        {children}
      </ButtonContent>
    </button>
  );
};

const ButtonIconLeft = ({ iconName }) => (
  <Icon size='s' name={iconName} className={styles.iconLeft} />
);

const ButtonIconRight = ({ iconName }) => (
  <Icon size='s' name={iconName} className={styles.iconRight} />
);

const ButtonContent = ({
  children,
  loading = false,
  iconNameRight = null,
  iconNameLeft = null,
}) =>
  loading ? (
    <span>Loading...</span>
  ) : (
    <>
      {iconNameLeft ? <ButtonIconLeft iconName={iconNameLeft} /> : null}
      {children}
      {iconNameRight ? <ButtonIconRight iconName={iconNameRight} /> : null}
    </>
  );
