import { useLocation } from 'react-router-dom';
import c from 'classnames';

import { useEvaluationGroups } from 'views/Results/Insights/hooks/useEvaluationGroups';

import { Card, Icon, TextLink, Typography } from 'components';

import styles from './EvaluationsAtAGlanceSummaryCard.module.scss';

export const EvaluationsAtAGlanceSummaryCard = () => {
  const { pathname } = useLocation();
  const { orangeSubMetrics, redSubMetrics } = useEvaluationGroups();

  return (
    <Card
      clickable={{
        text: 'Sample at a glance',
        href: `${pathname}/insights/all-insights?evaluations=red%2Corange`,
        variety: 'Card',
      }}
    >
      <div className={styles.content}>
        <Typography variant='heading-m'>Your sample at a glance</Typography>
        <div className='flex align-items-center gap-3'>
          <div className='flex align-items-center gap-2'>
            <div className={c(styles.iconContainer, styles.red)}>
              <Icon name='alertTriangleFilled' size='m' className='m-auto' />
            </div>
            <Typography variant='heading-l'>
              {`${redSubMetrics?.length ?? 0} Needs support`}
            </Typography>
          </div>
          <div className='flex align-items-center gap-2'>
            <div className={c(styles.iconContainer, styles.orange)}>
              <Icon name='alertCircleFilled' size='m' className='m-auto' />
            </div>
            <Typography variant='heading-l'>
              {`${orangeSubMetrics?.length ?? 0} Needs improving`}
            </Typography>
          </div>
        </div>
        <TextLink
          label='See all metrics'
          iconNameRight='chevronForward'
          href={`${pathname}/insights/all-insights?evaluations=red%2Corange`}
          className='ml-auto'
        />
      </div>
    </Card>
  );
};
export default EvaluationsAtAGlanceSummaryCard;
