import usePublishedGuides from 'services/general/guidesList';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  LinkButton,
  Spinfinity,
  Typography,
} from 'components';
import { GuideCard } from 'views/Guides/GuideCard/GuideCard';

type TopGuidesProps = {
  title: string;
  qty: number;
  withLink?: boolean;
};
export const TopGuides = ({ title, qty, withLink = true }: TopGuidesProps) => {
  const { data: guides, isError } = usePublishedGuides();
  return (
    <div className='flex flex-column gap-3'>
      <Typography variant='heading-m'>{title}</Typography>
      <div className='flex flex-column gap-3'>
        {guides ? (
          guides
            .slice(0, qty)
            .map(guide => <GuideCard key={guide.id} guide={guide} />)
        ) : isError ? (
          <p>{`We can't display the guides at the moment`}</p>
        ) : (
          <Spinfinity />
        )}
        {withLink && (
          <LinkButton
            label='View all guides'
            href='/guides'
            variant={ButtonVariant.SOLID}
            size={ButtonSize.M}
            color={ButtonColor.LIGHT}
            fullWidth
          />
        )}
      </div>
    </div>
  );
};
