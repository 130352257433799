import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTinyAccountById } from 'store/account';

import { ActivationNavigation } from './ActivationNavigation';
import MemberStep from './steps/MemberStep';
import KitActivationStep from './steps/KitActivationStep';
import SampleDetailsStep from './steps/SampleDetailsStep';
import PractitionerStep from './steps/PractitionerStep';
import ResearchStep from './steps/ResearchStep';

import Kit from 'types/Kit';
import TinyAccount from 'types/TinyAccount';
import { useUrlQuery } from 'hooks/useUrlQuery';
import useTinyAccounts from 'services/account/useTinyAccounts';

type KitActivationState = {
  kit: Partial<Kit>;
  tinyAccount: { id: string } & Partial<TinyAccount>;
};
export type KitStepProps = {
  tinyAccounts: TinyAccount[];
  step: number;
  setCurrentActivationStep: (step: number) => void;
  activationState: KitActivationState;
  setActivationState: (state: KitActivationState) => void;
};

export type KitStep = {
  id: string;
  Component: React.FC<KitStepProps>;
  exactPath?: boolean;
  optional?: boolean;
};

export const registerKitSteps: KitStep[] = [
  {
    id: 'memberStep',
    Component: MemberStep,
    exactPath: true,
  },
  {
    id: 'kitActivationStep',
    Component: KitActivationStep,
  },
  {
    id: 'sampleDetailsStep',
    Component: SampleDetailsStep,
  },
  {
    id: 'practitionerStep',
    Component: PractitionerStep,
    optional: true,
  },
  {
    id: 'researchStep',
    Component: ResearchStep,
    optional: true,
  },
];
export const ActivateKit = () => {
  const { tinyAccounts } = useTinyAccounts();
  const queryParams = useUrlQuery();
  const tinyaccount_id = queryParams.get('tinyaccount_id');
  const tinyAccount = useSelector(getTinyAccountById(tinyaccount_id ?? ''));

  const [currentActivationStep, setCurrentActivationStep] = useState(
    tinyaccount_id ? 1 : 0,
  );

  const [activationState, setActivationState] = useState<KitActivationState>({
    tinyAccount: tinyAccount ? tinyAccount : { id: '' },
    kit: {},
  });
  useEffect(() => {
    if (tinyAccount && !activationState.tinyAccount.id) {
      setActivationState({
        ...activationState,
        tinyAccount,
      });
      if (currentActivationStep === 0) setCurrentActivationStep(1);
    }
  }, [tinyAccount, activationState, currentActivationStep]);
  const StepComponent = registerKitSteps[currentActivationStep]?.Component;

  return (
    <div className='max-w-100 p-3'>
      <ActivationNavigation
        step={currentActivationStep}
        startingStep={tinyAccount ? 1 : 0}
        steps={registerKitSteps}
        setCurrentActivationStep={setCurrentActivationStep}
        kitId={activationState.kit.id}
      />
      {tinyAccounts && StepComponent && (
        <StepComponent
          tinyAccounts={tinyAccounts}
          step={currentActivationStep}
          setCurrentActivationStep={setCurrentActivationStep}
          activationState={activationState}
          setActivationState={setActivationState}
        />
      )}
    </div>
  );
};
