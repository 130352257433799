import React, { useState } from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import c from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { PLACEHOLDER } from 'assets';
import { IconName } from 'components/Icon/getSvgByName';

import styles from './THField.module.scss';
import Typography from 'components/Typography/Typography';

type FieldProps = {
  name: string;
  label: string;
  labelClasses?: string;
  type: string;
  errors?: FieldErrors;
  hideErrors?: boolean;
  autoComplete?: string;
  placeholder?: string;
  icon?: IconName;
  center?: boolean;
  defaultValue?: string | number;
  description?: string;
  classes?: string;
  step?: string;
} & UseFormRegisterReturn;

const THField = React.forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      label,
      labelClasses,
      name,
      type = 'text',
      errors,
      hideErrors,
      autoComplete,
      placeholder,
      icon,
      center = false,
      defaultValue,
      classes,
      description,
      ...rest
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className={c(styles.field, classes)}>
        <label htmlFor={name} className={c(styles.label, labelClasses)}>
          <Typography variant='body-s'>{label}</Typography>
          {type === 'password' && (
            <div onClick={togglePasswordVisibility}>
              <Typography variant='body-s' className='clickable'>
                {isPasswordVisible ? 'Hide' : 'Show'}
              </Typography>
            </div>
          )}
        </label>
        {!!description && (
          <span className={styles.description}>{description}</span>
        )}
        <div className={styles.inputContainer}>
          <input
            type={type === 'password' && isPasswordVisible ? 'text' : type}
            id={name}
            name={name}
            placeholder={
              placeholder
                ? placeholder
                : type === 'password'
                ? isPasswordVisible
                  ? PLACEHOLDER.passwordVisible
                  : PLACEHOLDER.password
                : PLACEHOLDER[name as keyof typeof PLACEHOLDER]
            }
            aria-label={label}
            className={c(
              styles.input,
              !!errors?.[name] && styles.hasError,
              !!icon && styles.hasIcon,
              {
                'text-center': center,
              },
            )}
            defaultValue={defaultValue}
            {...rest} // { onChange, onBlur }
            ref={ref}
          />
          {icon && (
            <div className={styles.inputInset}>
              <Icon name={icon} size='s' />
            </div>
          )}
        </div>
        {!hideErrors && (
          <div className={styles.errors}>
            {errors && (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <div
                    className={styles.error}
                    id={`error-${name}`}
                    key={message}
                    role='alert'
                  >
                    <Icon name='alertCircle' size='xs' /> {message}
                  </div>
                )}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);
THField.displayName = 'THField';
export default THField;
