import { Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { useSubdomain } from 'hooks/useSubdomain';

import {
  Button,
  Brand,
  MicrobesBg,
  WhiteLogo,
  DesignButton,
  ButtonColor,
  LinkButton,
  ButtonSize,
} from 'components';
import PractitionerSplashScreen from 'views/Auth/SplashScreen/PractitionerSplashScreen';

import styles from './SplashScreen.module.scss';

const SplashScreen = () => {
  const { isPractitionerPortal, isLoaded } = useSubdomain();
  if (!isLoaded) return null;
  if (isPractitionerPortal) {
    return <PractitionerSplashScreen />;
  }
  return (
    <>
      <MicrobesBg />
      <Suspense fallback={<WhiteLogo />}>
        <div className={styles.logoContainer}>
          <Brand color='light' />
        </div>
      </Suspense>
      <div className={styles.splashText}>
        <h2>You have control over your family&apos;s lifelong health.</h2>
        <p>
          Tiny Health empowers parents everywhere to stop guessing about their
          family&apos;s health.
        </p>
      </div>
      <div className='w-100'>
        <LinkButton
          label='Get started'
          href='/register'
          className='mb-4 w-100 font-weight-light'
          color={ButtonColor.GREEN}
          size={ButtonSize.M}
        />
        <div className={styles.splashText}>
          <div className='flex'>
            <span className='ml-auto'>Have an account? </span>
            <NavLink className='ml-1 mr-auto' to='/login'>
              Login
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
export default SplashScreen;
