import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import { parseDate } from 'helpers';
import useFeature from 'services/features/useFeature';
import TinyAccount from 'types/TinyAccount';
import Kit from 'types/Kit';

import {
  Icon,
  Card,
  LinkButton,
  ButtonVariant,
  ButtonSize,
  ButtonColor,
  Typography,
} from 'components';

import styles from './SurveyCard.module.scss';

type SurveyCardProps = {
  survey_key: string;
  typeform_id: string;
  display_title: string;
  description: string;
  tinyAccount?: TinyAccount;
  kit?: Kit;
  needed?: boolean;
  linkDetail?: string;
  estimated_time?: number;
  dateSubmitted?: string;
};

export const SurveyCard = ({
  description,
  needed,
  linkDetail,
  dateSubmitted,
  tinyAccount,
  kit,
  typeform_id,
  display_title,
  estimated_time,
  survey_key,
}: SurveyCardProps) => {
  const { pathname } = useLocation();
  const { isFeatureEnabled: showWYTU } = useFeature('what_you_told_us');

  return (
    <Card id={typeform_id} variant='wrapper' className='p-3'>
      <div className='flex flex-column gap-3'>
        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center justify-content-between gap-1 w-100'>
            <Typography variant='heading-m'>{display_title}</Typography>
            {needed ? (
              !!estimated_time && (
                <div
                  className={c('flex align-items-center gap-1', styles.allGrey)}
                >
                  <Icon name='clock' size='s' />
                  <Typography variant='label' className='grey'>
                    {`${estimated_time} min`}
                  </Typography>
                </div>
              )
            ) : (
              <div className='flex align-items-center gap-1'>
                <Icon name='check' size='s' active />
                <Typography variant='label' className='green'>
                  Completed
                </Typography>
              </div>
            )}
          </div>
          <Typography variant='body-s' responsive>
            {description}
          </Typography>
        </div>

        {dateSubmitted ? (
          <div className={styles.dateSubmitted}>
            <Icon name='calendarFilled' size='xs' />
            <span>
              Submitted on {format(parseDate(dateSubmitted), 'MM/dd/yy')}
            </span>
          </div>
        ) : null}

        {needed && (
          <LinkButton
            label='Complete survey'
            href={{
              pathname: `/typeform/${tinyAccount?.id}/${kit?.id}/${typeform_id}/${survey_key}`,
            }}
            state={{ from: pathname }}
            variant={ButtonVariant.SOLID}
            size={ButtonSize.S}
            color={ButtonColor.PURPLE}
          />
        )}

        {!needed && !!linkDetail && showWYTU && (
          <LinkButton
            label='View response'
            href={{
              pathname: linkDetail,
            }}
            state={{ from: pathname }}
            variant={ButtonVariant.SOLID}
            size={ButtonSize.S}
            color={ButtonColor.LIGHT}
          />
        )}
      </div>
    </Card>
  );
};
