import { CenteredColumnLayout } from '../Layout/CenteredColumnLayout';
import {
  Button,
  ButtonColor,
  ButtonSize,
  DesignButton,
  Typography,
} from 'components';
import ErrorSvg from 'assets/images/error_illustration_2.svg?react';
import styles from './ErrorDisplay.module.scss';
export const ErrorDisplay = ({ stretch }: { stretch?: boolean }) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={styles.errorDisplay}>
      <Typography variant='heading-xl' color='purple' className='text-center'>
        Oops! Try reloading.
      </Typography>
      <ErrorSvg className='mx-auto' />
      <Typography variant='body-s' color='purple' className='text-center'>
        We&apos;ve made updates to this portal. Please refresh to see them. If
        you have issues, reach out to hello@tinyhealth.com
      </Typography>
      <DesignButton
        onClick={async () => await reloadPage()}
        label='Refresh page'
        color={ButtonColor.PURPLE}
        size={ButtonSize.M}
      />
    </div>
  );
};
