import { useCallback } from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Auth } from 'api';

import { useAppDispatch } from 'store/store';
import { saveTempPassword, signIn, storeEmail } from 'store/user';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { useSubdomain } from 'hooks/useSubdomain';

const signInUser = createAsyncThunk(
  'user/signInUser',
  async (params: { email: string; password: string }, { dispatch }) => {
    const cognitoUser = await Auth.signIn(params.email, params.password);

    if (cognitoUser?.token) {
      localStorage.setItem('jwtToken', cognitoUser.token);
    }
    dispatch(signIn(cognitoUser.user));
  },
);
export const resendSignUp = async (email: string) => {
  await Auth.resendSignUp(email);
  toast('Resent verification email');
};
const useSignIn = () => {
  const navigate = useNavigate();
  const { isPractitionerPortal } = useSubdomain();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const from = location?.state?.from as string | undefined;
  const { trackEvent } = useAnalytics();
  const signIn = useCallback(
    async (email: string, password: string) => {
      toast.dismiss();
      try {
        await dispatch(signInUser({ email, password })).unwrap();
        trackEvent('Signed In');
        if (isPractitionerPortal) {
          navigate('/portal');
        } else if (from) {
          navigate(from, { state: { ...location.state, from: undefined } });
        } else {
          navigate('/', { state: { ...location.state, from } });
        }
      } catch (err) {
        const error = err as Error;
        if (error?.name === 'UserNotConfirmedException') {
          dispatch(storeEmail(email));
          dispatch(saveTempPassword(password));
          await resendSignUp(email);
          navigate('/check-email');
        } else throw error;
      }
    },
    [dispatch, trackEvent, from, isPractitionerPortal, navigate],
  );
  return signIn;
};
export default useSignIn;
