import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ResultsRoutes } from 'views/Results';
import { ActionPlan, ActionPlanRoutes } from 'views/ActionPlan';
import { ProfileRoutes } from 'views/Profiles/Profile';
import { Referrals } from 'views/Referrals/Referrals';
import { Guides } from 'views/Guides/Guides';
import { KitsRoutes } from 'views/Kits/KitsPage';
import Typeform from 'views/Typeform/Typeform';
import TypeformSurvey from 'views/TypeformSurvey/TypeformSurvey';
import Account from 'views/Account/Account';
import SubscriptionDetail from 'views/Account/Subscriptions/SubscriptionDetail';
import SubscriptionCancellation from 'views/Account/Subscriptions/SubscriptionCancellation';
import SubscriptionCancelled from 'views/Account/Subscriptions/SubscriptionCancelled';
import { BookmarksRoutes } from 'views/ActionPlan/MyList/MyList';
import { ReviewRoutes } from 'views/Review/Review';

import ProtectedRoute from 'routes/ProtectedRoute';
import CatchAllRoute from 'routes/CatchAllRoute';
import NoAuthRoute from 'routes/NoAuthRoute';
import NoAuthRouteCleanLayout from './NoAuthCleanRoute';
import PractitionerRoute from 'routes/PractitionerRoute';
import Home from 'views/Home/Home';
const Information = lazy(() => import('views/Information/Information'));
const HelpPage = lazy(() => import('views/Help/Help'));
const Nps = lazy(() => import('views/Auth/Nps/Nps'));
const Consults = lazy(() => import('views/Consults/Consults'));
const ConsultConfirmation = lazy(
  () => import('views/Consults/ConsultConfirmation/ConsultConfirmation'),
);
const ConsultsInfoPage = lazy(
  () => import('views/Consults/ConsultsInfo/ConsultsInfoPage'),
);
const ConsultNote = lazy(
  () => import('views/Consults/ConsultNote/ConsultNote'),
);
const ConsultsBooking = lazy(
  () => import('views/Consults/ConsultsBooking/ConsultsBooking'),
);
const SignUpForm = lazy(() => import('views/Auth/SignUpForm/SignUpForm'));
const Login = lazy(() => import('views/Auth/Login/Login'));
const SplashScreen = lazy(() => import('views/Auth/SplashScreen/SplashScreen'));
const AuthLayout = lazy(() => import('components/Layout/AuthLayout'));
const ForgotPasswordForm = lazy(
  () => import('views/Auth/ForgotPasswordForm/ForgotPasswordForm'),
);
const UpdatePasswordForm = lazy(
  () => import('views/Auth/UpdatePasswordForm/UpdatePasswordForm'),
);
const VerificationForm = lazy(() => import('views/Auth/Verify/Verify'));
const VerifyAccount = lazy(
  () => import('views/Auth/Verification/VerifyAccount'),
);
const EmailVerificationPage = lazy(
  () => import('views/Auth/Verification/EmailVerificationPage'),
);
const AccountPasswordPage = lazy(
  () => import('views/Auth/AccountPassword/AccountPasswordPage'),
);
const ResponsiveLayout = lazy(
  () => import('components/Layout/ResponsiveLayout'),
);
const PractitionerLayout = lazy(
  () => import('views/PractitionerPortal/PractitionerLayout'),
);
const PractitionerPortal = lazy(
  () => import('views/PractitionerPortal/PractitionerPage'),
);
const PractitionerSettings = lazy(
  () => import('views/PractitionerPortal/Settings/PractitionerSettings'),
);
export const THRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />

      <Route element={<NoAuthRoute />}>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<SignUpForm />} />
          <Route path='/check-email' element={<VerifyAccount />} />
          <Route
            path='/email-verification'
            element={<EmailVerificationPage />}
          />
          <Route path='/reset-password' element={<ForgotPasswordForm />} />
          <Route path='/update-password' element={<UpdatePasswordForm />} />
          <Route path='/verify' element={<VerificationForm />} />
          {/* <Route path='/' element={<SplashScreen />} /> */}
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<ResponsiveLayout />}>
          <Route path='/information/:kitId' element={<Information />} />
          <Route path='/results/*' element={<ResultsRoutes />} />
          <Route path='/action-plan/:kitId/*' element={<ActionPlanRoutes />} />
          <Route path='/action-plan' element={<ActionPlan />} />
          <Route path='/bookmarks/*' element={<BookmarksRoutes />} />
          <Route path='/guides' element={<Guides />} />
          <Route path='/refer-a-friend' element={<Referrals />} />
          <Route path='/profile/*' element={<ProfileRoutes />} />
          <Route
            path='/book-a-consult'
            element={<Navigate to='/consults' replace />}
          />
          <Route
            path='/consults/confirmation'
            element={<ConsultConfirmation />}
          />
          <Route path='/consults/info' element={<ConsultsInfoPage />} />
          <Route path='/consults/book' element={<ConsultsBooking />} />
          <Route path='/consults/:consultId' element={<ConsultNote />} />
          <Route path='/consults' element={<Consults />} />

          <Route path='/help' element={<HelpPage />} />
          <Route path='/kits/*' element={<KitsRoutes />} />
          <Route path='/review/*' element={<ReviewRoutes />} />
          <Route
            path='/account/subscription/:subscriptionId/cancelled'
            element={<SubscriptionCancelled />}
          />
          <Route
            path='/account/subscription/:subscriptionId/cancellation'
            element={<SubscriptionCancellation />}
          />
          <Route
            path='/account/subscription/:subscriptionId'
            element={<SubscriptionDetail />}
          />
          <Route path='/account/*' element={<Account />} />

          <Route
            path='/typeform/:tinyAccountId/:kitId/:typeformId/:surveyType'
            element={<Typeform />}
          />
          <Route
            path='/typeform/:tinyAccountId/:kitId/:typeformId'
            element={<TypeformSurvey />}
          />
        </Route>
        {/* Practitioner Routes */}
        <Route element={<PractitionerRoute />}>
          <Route element={<PractitionerLayout />}>
            <Route path='/impersonate' element={<Navigate to='/portal' />} />
            <Route path='/portal' element={<PractitionerPortal />} />
            <Route path='/settings' element={<PractitionerSettings />} />
          </Route>
        </Route>
      </Route>

      <Route element={<NoAuthRouteCleanLayout />}>
        <Route path='/account-password' element={<AccountPasswordPage />} />
      </Route>
      <Route path='/nps' element={<Nps />} />
      <Route path='/*' element={<CatchAllRoute />} />
    </Routes>
  );
};
