import { useLocation, useNavigate } from 'react-router-dom';
import c from 'classnames';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { EventPropertiesMap } from 'contexts/analytics/types';
import Typography from 'components/Typography/Typography';

import styles from './Card.module.scss';

export const Card = ({
  title = '',
  id,
  className = '',
  variant = 'normal',
  clickable,
  children,
}: {
  title?: React.ReactNode;
  id?: string;
  className?: string;
  variant?: 'normal' | 'wrapper';
  clickable?: EventPropertiesMap['Clicked Button'];
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (clickable) {
      trackEvent('Clicked Button', clickable);
      if (clickable.isExternal) {
        window.open(clickable.href, '_blank');
      } else {
        navigate(clickable.href, {
          state: { from: pathname },
        });
      }
    }
  };

  return (
    <div
      id={id}
      className={c(
        styles.cardWrapper,
        variant,
        className,
        !!clickable && styles.hover,
      )}
      onClick={handleClick}
    >
      {title && (
        <>
          <div
            className={c(
              styles.cardSection,
              styles.cardHeader,
              title ? styles.headerMargin : '',
            )}
          >
            <Typography variant='heading-m'>{title}</Typography>
          </div>
          <div className={c(styles.cardBody, styles.cardSection)}>
            <div className={c('flex flex-column h-100')}>{children}</div>
          </div>
        </>
      )}

      {!title && (
        <div className={c(styles.cardBody, 'h-100')}>
          <div className={c('flex flex-column h-100')}>{children}</div>
        </div>
      )}
    </div>
  );
};
