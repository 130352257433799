import c from 'classnames';
import styles from './Typography.module.scss';

export type TypographyComponentVariant =
  | 'label' // 0.8125rem or 1rem (desktop w/ responsive prop)
  | 'body-s' // 1rem or 0.8125rem (mobile w/ responsive prop)
  | 'body-m' // 1.25rem
  | 'heading-s' // 1rem
  | 'heading-m' // 1.125rem
  | 'heading-l' // 1.5rem or 1.25rem (mobile)
  | 'heading-xl' // 1.75rem or 1.5rem (mobile)
  | 'heading-2xl'; // 2.125rem or 1.75rem (mobile)

export enum TextColor {
  GREEN = 'green',
  PURPLE = 'purple',
  DARK = 'dark',
  LIGHT_GREY = 'light-grey',
  LIGHTER_GREY = 'lighter-grey',
  RED = 'red',
  ORANGE = 'orange',
  GREY = 'grey',
}
type TextColorType =
  | 'green'
  | 'purple'
  | 'dark'
  | 'light-grey'
  | 'lighter-grey'
  | 'red'
  | 'orange'
  | 'grey';

export const getTextColorFromEvaluationColor = (color: string) => {
  switch (color) {
    case 'green':
      return TextColor.GREEN;
    case 'orange':
      return TextColor.ORANGE;
    case 'red':
      return TextColor.RED;
    case 'grey':
      return TextColor.GREY;
  }
  return TextColor.DARK;
};
type TypographyProps = {
  variant: TypographyComponentVariant;
  medium?: boolean;
  light?: boolean;
  color?: TextColorType;
  responsive?: boolean;
  serif?: boolean;
  className?: string;
  children: React.ReactNode;
};
const Typography = ({
  variant,
  medium,
  light,
  color,
  responsive, // only implemented for label size, large heading sizes are responsive by default
  serif,
  className,
  children,
}: TypographyProps) => {
  return (
    <>
      <span
        className={c(
          className,
          styles.typography,
          styles[variant],
          serif && styles.serif,
          color && styles[color],
          medium && styles.medium,
          light && styles.light,
          responsive && styles.responsive,
        )}
      >
        {children}
      </span>
    </>
  );
};

export default Typography;
