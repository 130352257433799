import { useMemo } from 'react';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getItemLinkLabelForType } from 'helpers/getItemLinkLabelForType';
import {
  ActionPlanRecommendationItem,
  BookmarkedItem,
  RecommendationItemType,
} from 'types/ActionPlan';

import { Icon, ImageFallback, TextLink, Markdown } from 'components';
import { Bookmark } from '../Bookmark/Bookmark';
import probioticPlaceholder from 'assets/images/placeholders/probiotic.svg';
import productPlaceholder from 'assets/images/placeholders/product.svg';
import foodPlaceholder from 'assets/images/placeholders/food.svg';
import resourcePlaceholder from 'assets/images/placeholders/resource.svg';
import recipePlaceholder from 'assets/images/placeholders/recipe.svg';

import styles from './RecommendationItems.module.scss';

type ItemProps = {
  item: ActionPlanRecommendationItem;
  is_bookmarked: boolean;
  recommendation_item_id: number;
  kit_ids?: string[];
  action_plan_recommendation_id?: number;
};

const Item = ({
  item,
  is_bookmarked,
  recommendation_item_id,
  kit_ids,
  action_plan_recommendation_id,
}: ItemProps) => {
  return (
    <div className={styles.itemContainer}>
      {item.type === RecommendationItemType.PROBIOTIC && (
        <ItemProbiotic item={item} />
      )}
      {item.type === RecommendationItemType.FOOD && <ItemFood item={item} />}
      {item.type === RecommendationItemType.TIP && <ItemTip item={item} />}
      {item.type === RecommendationItemType.SUPPLEMENT && (
        <ItemSupplement item={item} />
      )}
      {item.type === RecommendationItemType.PRODUCT && (
        <ItemProduct item={item} />
      )}
      {item.type === RecommendationItemType.RESOURCE && (
        <ItemResource item={item} />
      )}
      {item.type === RecommendationItemType.RECIPE && (
        <ItemRecipe item={item} />
      )}
      {!!kit_ids && (
        <div
          className={c(
            styles.bookmarkTag,
            item.type === RecommendationItemType.TIP && styles.singleLine,
          )}
        >
          <Bookmark
            recommendation_item_id={recommendation_item_id}
            is_bookmarked={is_bookmarked}
            kit_ids={kit_ids}
            action_plan_recommendation_id={action_plan_recommendation_id}
          />
        </div>
      )}
    </div>
  );
};

const ItemTip = ({ item }: { item: ActionPlanRecommendationItem }) => {
  return (
    <div className={styles.mr32}>
      {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
      {!!item.description && (
        <div className={styles.itemDescription}>
          <Markdown>{item.description}</Markdown>
        </div>
      )}
    </div>
  );
};

const ItemFood = ({ item }: { item: ActionPlanRecommendationItem }) => {
  return (
    <div className={c(styles.flexRowCenter, styles.gap2)}>
      <div className={c(styles.imageSmall, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={foodPlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={styles.mr32}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={styles.itemDescription}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

const ItemResource = ({ item }: { item: ActionPlanRecommendationItem }) => {
  return (
    <div className={styles.flexRow}>
      <div className={c(styles.imageMedium, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={resourcePlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={c(styles.fullWidth, styles.fullHeight)}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={c(styles.itemDescription, styles.mobileMb12)}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
        <div className={styles.itemBody}>
          <div className={c(styles.itemExtraInfo, styles.hideOnMobile)}>
            {!!item.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{item.info}</span>
              </div>
            )}
            {!!item.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{item.discount_code}</span>
              </div>
            )}
          </div>
          {!!item.link && (
            <TextLink
              label={getItemLinkLabelForType(item.type)}
              iconNameRight='trendUp02'
              href={item.link}
              external
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ItemRecipe = ({ item }: { item: ActionPlanRecommendationItem }) => {
  return (
    <div className={styles.flexRow}>
      <div className={c(styles.imageMedium, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={recipePlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={c(styles.fullWidth, styles.fullHeight)}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={c(styles.itemDescription, styles.mb12)}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
        <div className={styles.itemBody}>
          <div className={styles.itemExtraInfo}>
            {!!item.time && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='clock' size='s' />
                <span>{item.time}</span>
              </div>
            )}
            {!!item.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{item.info}</span>
              </div>
            )}
            {!!item.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{item.discount_code}</span>
              </div>
            )}
          </div>
          {!!item.link && (
            <TextLink
              label={getItemLinkLabelForType(item.type)}
              iconNameRight='trendUp02'
              href={item.link}
              external
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ItemProduct = ({ item }: { item: ActionPlanRecommendationItem }) => {
  return (
    <div className={styles.flexRow}>
      <div className={c(styles.imageMedium, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={productPlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={c(styles.fullWidth, styles.fullHeight)}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={c(styles.itemDescription, styles.mobileMb12)}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
        <div className={styles.itemBody}>
          <div className={c(styles.itemExtraInfo, styles.hideOnMobile)}>
            {!!item.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{item.info}</span>
              </div>
            )}
            {!!item.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{item.discount_code}</span>
              </div>
            )}
          </div>
          {!!item.link && (
            <TextLink
              label={getItemLinkLabelForType(item.type)}
              iconNameRight='trendUp02'
              href={item.link}
              external
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ItemProbiotic = ({ item }: { item: ActionPlanRecommendationItem }) => {
  // const isAllergenFree = useMemo(() => getIsAllergenFree(item), [item]);
  return (
    <div className={styles.flexRow}>
      <div className={c(styles.imageMedium, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={probioticPlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={c(styles.fullWidth, styles.fullHeight)}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={c(styles.itemDescription, styles.mb12)}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
        <div className={styles.itemBody}>
          <div className={styles.itemExtraInfo}>
            {!!item.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{item.info}</span>
              </div>
            )}
            {!!item.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{item.discount_code}</span>
              </div>
            )}
            {/* HOLD ON THIS */}
            {/* {isAllergenFree && (
              <div
                className={c(
                  styles.itemExtraDescription,
                  styles.flexRowCenter,
                )}
              >
                <Icon name='check' size='s' />
                <span>Allergen free</span>
              </div>
            )} */}
          </div>
          {!!item.link && (
            <TextLink
              label={getItemLinkLabelForType(item.type)}
              iconNameRight='trendUp02'
              href={item.link}
              external
            />
          )}
        </div>
      </div>
    </div>
  );
};
const ItemSupplement = ({ item }: { item: ActionPlanRecommendationItem }) => {
  // const isAllergenFree = useMemo(() => getIsAllergenFree(item), [item]);
  return (
    <div className={styles.flexRow}>
      <div className={c(styles.imageMedium, styles.noShrink)}>
        {item.photo_url && (
          <ImageFallback
            src={item.photo_url}
            fallback={probioticPlaceholder}
            alt={item.title}
          />
        )}
      </div>
      <div className={c(styles.fullWidth, styles.fullHeight)}>
        {!!item.title && <div className={styles.itemTitle}>{item.title}</div>}
        {!!item.description && (
          <div className={c(styles.itemDescription, styles.mb12)}>
            <Markdown>{item.description}</Markdown>
          </div>
        )}
        <div className={styles.itemBody}>
          <div className={styles.itemExtraInfo}>
            {!!item.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{item.info}</span>
              </div>
            )}
            {!!item.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{item.discount_code}</span>
              </div>
            )}
            {/* HOLD ON THIS */}
            {/* {isAllergenFree && (
              <div
                className={c(
                  styles.itemExtraDescription,
                  styles.flexRowCenter,
                )}
              >
                <Icon name='check' size='s' />
                <span>Allergen free</span>
              </div>
            )} */}
          </div>
          {!!item.link && (
            <TextLink
              label={getItemLinkLabelForType(item.type)}
              iconNameRight='trendUp02'
              href={item.link}
              external
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const RecommendationItems = ({
  items,
  actionId,
  activeBookmarks,
}: {
  items: ActionPlanRecommendationItem[];
  actionId?: number;
  activeBookmarks?: BookmarkedItem[];
}) => {
  const currentKitId = useCurrentKitAndTinyAccount().currentKitId;

  const areGrouped = useMemo(() => {
    const GROUPABLE_ITEMS = [
      RecommendationItemType.FOOD,
      RecommendationItemType.TIP,
    ];
    const nonGroupableItems = items.filter(
      item => !GROUPABLE_ITEMS.includes(item.type),
    );
    return nonGroupableItems?.length === 0;
  }, [items]);

  return (
    <div className={c(styles.itemsContainer, areGrouped && styles.card)}>
      {items.map(item => (
        <div
          className={c(
            areGrouped ? styles.recommendation : styles.cardRecommendation,
          )}
          key={item.id}
        >
          {!!currentKitId && (
            <Item
              item={item}
              is_bookmarked={
                !!activeBookmarks?.find(
                  bookmark => bookmark.recommendation_item_id === item.id,
                )
              }
              recommendation_item_id={item.id}
              kit_ids={[currentKitId]}
              action_plan_recommendation_id={actionId}
            />
          )}
        </div>
      ))}
    </div>
  );
};
