import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from '@sentry/react';

import { AnalyticsProvider } from './contexts/analytics/AnalyticsProvider';
import { ErrorDisplay, Layout } from 'components';
import store from 'store/store';

import 'assets/scss/style.scss';
import { ENVIRONMENT, FAVICON, SEGMENT_WRITE_KEY } from 'assets/env_constants';
import HeaderIllustrationProvider from 'contexts/HeaderIllustrationContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600000,
    },
  },
});
if (window) {
  window.queryClient = queryClient;
}
const updateFavicon = () => {
  const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  if (favicon) {
    favicon.href = FAVICON;
  }
};

const App = () => {
  updateFavicon();
  return (
    <ErrorBoundary fallback={<ErrorDisplay />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AnalyticsProvider
            writeKey={
              ((ENVIRONMENT === 'production' || ENVIRONMENT === 'preview') &&
                SEGMENT_WRITE_KEY) ||
              ''
            }
          >
            <HeaderIllustrationProvider>
              <Layout />
            </HeaderIllustrationProvider>
          </AnalyticsProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
};
export default App;
