import { useMemo } from 'react';
import c from 'classnames';

import { LINKS } from 'assets';
import { kitResultsViewable } from 'helpers';
import usePublishedGuides from 'services/general/guidesList';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { EventPropertiesMap } from 'contexts/analytics/types';
import Guide from 'types/Guide';

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  Card,
  DesignButton,
  ImageFallback,
  TextLink,
  Typography,
} from 'components';
import { TextColor } from 'components/Typography/Typography';
import guidePlaceholder from 'assets/images/placeholders/book.svg';

import styles from './RecommendedForYou.module.scss';

type RecommendedCardProps = {
  title: string;
  backgroundClass: string;
  clickable: EventPropertiesMap['Clicked Button'];
  desktopOnly?: boolean;
  noOverlay?: boolean;
};
const RECOMMENDED_FOR_YOU_CARDS: {
  [key: string]: RecommendedCardProps;
} = {
  consult: {
    title: 'Schedule a coaching session',
    backgroundClass: styles.consult,
    clickable: {
      href: `/results/:id/consults`,
      text: 'Explore sessions',
      isExternal: false,
    },
  },
  actions: {
    title: 'Your Action Plan is Ready',
    backgroundClass: styles.actions,
    clickable: {
      href: '/action-plan/:id',
      text: 'Take action',
      isExternal: false,
    },
  },
  refer: {
    title: 'Give $40, Get $40',
    backgroundClass: styles.refer,
    clickable: {
      href: '/refer-a-friend',
      text: 'Refer a friend',
      isExternal: false,
    },
  },
  guides: {
    title: 'Explore Gut Guides',
    backgroundClass: styles.guides,
    clickable: {
      href: '/guides',
      text: 'View all guides',
      isExternal: false,
    },
  },
  facebook: {
    title: 'Join Facebook Community',
    backgroundClass: styles.facebookGroup,
    clickable: {
      href: LINKS.facebookGroup,
      text: 'Go to Facebook',
      isExternal: true,
    },
  },
  order: {
    title: 'Track your progress with your next kit',
    backgroundClass: styles.order,
    clickable: {
      href: LINKS.store,
      text: 'Order kit',
      isExternal: true,
    },
    desktopOnly: true,
    noOverlay: true,
  },
  membership: {
    title: 'Unlock exclusive benefits with Tiny+',
    backgroundClass: styles.membership,
    clickable: {
      href: LINKS.membership,
      text: 'Explore',
      isExternal: true,
    },
    desktopOnly: true,
    noOverlay: true,
  },
};
const getRecommendedForYouCards = (
  kitId: string | undefined,
  withResults: boolean,
  hasMembership: boolean,
) => {
  const cards = [];
  if (withResults) {
    if (!hasMembership) {
      cards.push(RECOMMENDED_FOR_YOU_CARDS.membership);
      cards.push(RECOMMENDED_FOR_YOU_CARDS.order);
    } else {
      cards.push(RECOMMENDED_FOR_YOU_CARDS.consult);
      cards.push(RECOMMENDED_FOR_YOU_CARDS.actions);
    }
  } else {
    cards.push(RECOMMENDED_FOR_YOU_CARDS.refer);
  }
  cards.push(RECOMMENDED_FOR_YOU_CARDS.guides);
  if (!withResults) {
    cards.push(RECOMMENDED_FOR_YOU_CARDS.facebook);
  }

  return cards.map(card => {
    if (card?.clickable?.href.includes(':id')) {
      card.clickable.href = card.clickable.href.replace(':id', kitId ?? '');
    }
    return card;
  });
};
const RecommendedCard = ({
  title,
  backgroundClass,
  clickable,
  desktopOnly,
  noOverlay,
}: RecommendedCardProps) => {
  return (
    <Card
      variant='wrapper'
      clickable={clickable}
      className={c(styles.card, desktopOnly && 'hide-on-mobile')}
    >
      <div className={c(styles.background, backgroundClass)}>
        <div className={c(!noOverlay && styles.overlay)}></div>
        <div className={styles.content}>
          <Typography variant='heading-l'>{title}</Typography>
          <DesignButton
            label={clickable.text}
            iconNameRight='chevronForward'
            variant={ButtonVariant.TEXT}
            size={ButtonSize.M}
            color={ButtonColor.WHITE}
          />
        </div>
      </div>
    </Card>
  );
};

export function RecommendedForYou({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) {
  const { latestKit, hasMembership } = useTinyAccountForHome(tinyAccountId);
  const withResults = kitResultsViewable(latestKit);

  const cards = getRecommendedForYouCards(
    latestKit?.id,
    withResults,
    hasMembership,
  );
  //If the surveys are completed and results are not ready we show guides instead of the other cards
  if (latestKit?.date_survey_completed && !kitResultsViewable(latestKit))
    return <GuidesForYou />;
  return (
    <div className={styles.container}>
      <Typography variant='heading-m'>Recommended for you</Typography>
      <div className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <RecommendedCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
}

const GuideCard = ({ guide }: { guide: Guide }) => {
  return (
    <Card
      variant='wrapper'
      clickable={
        !guide?.url
          ? undefined
          : {
              text: 'Guide: ' + guide.display_title,
              href: guide.url,
              variety: 'Card',
              isExternal: true,
            }
      }
    >
      <div className={styles.guideCard}>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <ImageFallback src={guide.image_url} fallback={guidePlaceholder} />
          </div>
          <Typography variant='heading-m'>{guide.display_title}</Typography>
          <div className={styles.twoLinesOfText}>
            <Typography variant='body-s' responsive serif className='grey-text'>
              {guide.description}
            </Typography>
          </div>
        </div>
        <DesignButton
          label='Read more'
          variant={ButtonVariant.TEXT}
          size={ButtonSize.M}
          color={ButtonColor.PURPLE}
        />
      </div>
    </Card>
  );
};

const GuidesForYou = () => {
  const { data: guides } = usePublishedGuides();
  const guidesToShow = useMemo(() => guides?.slice(0, 3), [guides]);

  if (!guidesToShow?.length) return <></>;
  return (
    <div className={styles.container}>
      <div className='flex align-items-center justify-content-between gap-1'>
        <Typography variant='heading-m'>Recent guides</Typography>
        <TextLink
          label='View all guides'
          iconNameRight='chevronForward'
          href='/guides'
          size='label'
          color={TextColor.PURPLE}
        />
      </div>
      <div className={styles.cardsContainer}>
        {guidesToShow.map(guide => (
          <GuideCard key={guide.id} guide={guide} />
        ))}
      </div>
    </div>
  );
};
