import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { Consult } from 'types/Consult';
import {
  getConsultCreditsCount,
  getHasAlreadyScheduledCloseToDate,
  getParsedNextConsultDate,
} from 'store/account';

import {
  ButtonColor,
  ButtonVariant,
  Card,
  Icon,
  LinkButton,
  Typography,
} from 'components';

import styles from './BookInAdvance.module.scss';

export const BookInAdvance = ({
  upcomingConsults,
}: {
  upcomingConsults: Consult[];
}) => {
  const consultCreditsCount = useSelector(getConsultCreditsCount);
  const nextConsultDate = useSelector(getParsedNextConsultDate);
  const hasConsultSuggestionInTheFuture =
    !!nextConsultDate && nextConsultDate > new Date();
  const hasAlreadyScheduledCloseToDate = useSelector(
    getHasAlreadyScheduledCloseToDate(upcomingConsults),
  );
  const shouldRender =
    !!hasConsultSuggestionInTheFuture && !hasAlreadyScheduledCloseToDate;

  if (!shouldRender) return <></>;
  return (
    <Card variant='wrapper' className={styles.purpleCard}>
      <div className='flex gap-2'>
        <div className={styles.iconWrapper}>
          <Icon name='calendar' size='m' />
        </div>
        <div className='flex flex-column gap-1'>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-s'>
              Schedule your next recommended consult, today
            </Typography>
            <Typography variant='label'>{`Recommended date: ${format(
              nextConsultDate,
              'MMMM dd yyyy',
            )}`}</Typography>
          </div>
          <LinkButton
            label={
              consultCreditsCount > 0
                ? 'You have a free consult: Book now'
                : 'Book now'
            }
            href='/consults/book'
            iconNameRight='chevronForward'
            color={ButtonColor.PURPLE}
            variant={ButtonVariant.TEXT}
            className='mt-3'
          />
        </div>
      </div>
    </Card>
  );
};
