import { useEffect, useState } from 'react';
import { userValueFormat } from 'helpers';
import c from 'classnames';
import { Typography } from 'components';

import styles from './RangesGraph.module.scss';

const getLimitedRanges = (sortedRanges, scaleBarMin, scaleBarMax) => {
  if (!sortedRanges?.length) return [];

  // apply the min and max to the ranges
  if (!scaleBarMin && !scaleBarMax) return sortedRanges;
  let limitedRanges = [];
  sortedRanges.forEach(range => {
    let newMin = null;
    let newMax = null;
    if (scaleBarMin && range.range_lower < scaleBarMin) {
      if (range.range_upper > scaleBarMin) {
        newMin = scaleBarMin;
      } else {
        return; //the whole range is out of the min-max range
      }
    }
    if (scaleBarMax && range.range_upper > scaleBarMax) {
      if (range.range_lower < scaleBarMax) {
        newMax = scaleBarMax;
      } else {
        return; //the whole range is out of the min-max range
      }
    }
    limitedRanges.push({
      ...range,
      range_lower: newMin ? newMin : range.range_lower,
      range_upper: newMax ? newMax : range.range_upper,
    });
  });
  return limitedRanges;
};

const getBreakpointsData = (rangesData, currentIndex, dataType) => {
  /*
    ||         Breakpoint ||          Breakpoint ||                 ||
  */
  return currentIndex === rangesData.length - 1
    ? {
        displayBreakpoint: false,
        alignment: 'flexEnd',
      }
    : {
        displayBreakpoint: userValueFormat(
          rangesData[currentIndex].range_upper,
          dataType,
        ),
        alignment: 'flexEnd',
      };
};

const getSamplePositioning = (range, value) => {
  if (value < range.range_lower) return 1; //at the lower limit
  if (value > range.range_upper) return 99; //at the upper limit
  const rangeWidth = range.range_upper - range.range_lower;
  return rangeWidth ? ((value - range.range_lower) * 100) / rangeWidth : null;
};

export const RangesGraph = ({
  relativeAbundance,
  dataType,
  ranges,
  scaleBarMin,
  scaleBarMax,
  withDetails = true,
  withSlimBars = false,
}) => {
  const [sampleRangeIndex, setSampleRangeIndex] = useState(0);
  const [rangesData, setRangesData] = useState([]);

  useEffect(() => {
    if (ranges?.length) {
      //get only a group for each "color" (group with the boundary conditions)
      let tempRangesData = getLimitedRanges(
        JSON.parse(JSON.stringify(ranges)), //new array of objects, important to avoid the objects to being pass by reference
        scaleBarMin,
        scaleBarMax,
      );
      if (tempRangesData?.length) {
        const min = tempRangesData[0].range_lower;
        const max = tempRangesData[tempRangesData.length - 1].range_upper;
        const dataWidth = max - min;
        //prepare data for graph
        tempRangesData.forEach((range, idx) => {
          //percentage 0 to 100
          tempRangesData[idx].percentage = dataWidth
            ? ((range.range_upper - range.range_lower) * 100) / dataWidth
            : 0;
          //find the range in which this kit sample is located
          if (
            range.range_lower <= relativeAbundance &&
            relativeAbundance < range.range_upper
          ) {
            setSampleRangeIndex(idx);
          }
        });
        tempRangesData.forEach((range, idx) => {
          //decide where to display the breakpoints
          tempRangesData[idx] = {
            ...tempRangesData[idx],
            ...getBreakpointsData(tempRangesData, idx, dataType),
          };
        });
        setRangesData(tempRangesData);
        //validate sample range index in case of outside min max boundaries
        if (relativeAbundance < tempRangesData[0].range_lower) {
          setSampleRangeIndex(0);
        }
        if (
          relativeAbundance >=
          tempRangesData[tempRangesData.length - 1].range_upper
        ) {
          setSampleRangeIndex(tempRangesData.length - 1);
        }
      }
    }
  }, [ranges, relativeAbundance, dataType, scaleBarMax, scaleBarMin]);

  return !rangesData?.length ? null : (
    <div className={c(styles.barContainer, withDetails && styles.withDetails)}>
      {rangesData.map((range, displayIdx) => (
        <div
          className={c(styles.barBlock, withSlimBars && styles.slimBars)}
          style={{ flexBasis: range.percentage + '%' }}
          key={range.range_lower + '_' + range.range_upper}
        >
          <div className={c(styles.bar, range.evaluation_color)}></div>
          {withDetails && (
            <div className={c(styles.details, styles[range.alignment])}>
              {!!range.displayBreakpoint && (
                <span className={c(styles.breakpoint)}>
                  <Typography variant='label'>
                    {range.displayBreakpoint}
                  </Typography>
                </span>
              )}
            </div>
          )}
          {sampleRangeIndex === displayIdx && (
            <>
              <div
                className={c(
                  styles.relativeAbundance,
                  withSlimBars && styles.slimBars,
                  range.evaluation_color,
                )}
                style={{
                  left: `calc(${getSamplePositioning(
                    range,
                    relativeAbundance,
                  )}% - 4px)`,
                }}
              ></div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
