import { Button } from 'components/THButton/THButton';
import { useSelector } from 'react-redux';
import useImpersonate from 'services/auth/useImpersonate';
import { getAccountEmail, getFullName } from 'store/account';
import styles from './ImpersonateBanner.module.scss';
const ImpersonateBanner = () => {
  const fullName = useSelector(getFullName);
  const email = useSelector(getAccountEmail);
  const { stopImpersonating } = useImpersonate();
  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <h6 className={styles.viewingText}>Viewing</h6>
        <div className={styles.infoRow}>
          <h4>{fullName}</h4>
          <h6 className={styles.email}>{email}</h6>
        </div>
      </div>
      <Button
        className={styles.switchUserButton}
        onClick={() => stopImpersonating()}
        width='content'
      >
        Switch User
      </Button>
    </div>
  );
};
export default ImpersonateBanner;
