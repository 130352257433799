import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import c from 'classnames';

import { Icon, Markdown } from 'components';

import styles from './Accordion.module.scss';

type AccordionProps = {
  header: string | React.ReactElement;
  initialState?: boolean;
  children?: React.ReactNode;
  onToggle?: (updatedIsOpen: boolean) => void;
  className?: string;
  hideToggleIcon?: boolean;
  isOpen?: boolean;
};
export const accordionAnimationProps = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 0.4,
      ease: [0.04, 0.62, 0.23, 0.98],
    },
  },
  exit: { opacity: 0, height: 0 },
};

export const Accordion = ({
  header,
  initialState = false,
  className = '',
  onToggle,
  children,
  hideToggleIcon,
  isOpen: controlledIsOpen,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    if (controlledIsOpen !== undefined) {
      setIsOpen(controlledIsOpen);
    }
  }, [controlledIsOpen]);

  const toggle = () => {
    if (onToggle) {
      onToggle(!isOpen);
    }
    setIsOpen(!isOpen);
  };

  const newHeaderWithIsOpen =
    typeof header === 'string' ? (
      <></>
    ) : (
      React.cloneElement(header, {
        isOpen: isOpen,
      })
    );

  return (
    <motion.div
      className={c(
        className,
        isOpen ? 'accordion-is-open' : 'accordion-is-closed',
      )}
    >
      <AnimatePresence>
        <motion.div
          key='header'
          className={styles.accordion}
          role='button'
          onClick={toggle}
        >
          {typeof header === 'string' ? (
            <div className={styles.header}>
              <Markdown>{header}</Markdown>
            </div>
          ) : (
            newHeaderWithIsOpen
          )}
          <Icon
            name='chevronDown'
            className={c(
              styles.toggleIcon,
              styles.rotate,
              isOpen ? [styles.iconRotateUp] : [styles.iconRotateDown],
              hideToggleIcon && styles.hideToggleIcon,
            )}
          />
        </motion.div>

        {isOpen && (
          <motion.div
            key='children'
            initial='open'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto', scaleY: 1 },
              collapsed: {
                opacity: 0,
                height: 0,
                scaleY: 0.8,
              },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.78] }}
            className={styles.children}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
